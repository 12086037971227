.standard-color-default {
  background-color: #E5E5E5;
}

.standard-color-white {
  background-color: #FFFFFF !important;
  border: #E5E5E5 1px solid !important;
}

.standard-color-aliceblue,
.standard-color-alice-blue {
  background-color: #f0f8ff !important;
}

.standard-color-antiquewhite,
.standard-color-antique-white {
  background-color: #faebd7 !important;
}

.standard-color-aqua {
  background-color: #00ffff !important;
}

.standard-color-aquamarine,
.standard-color-aqua-marine {
  background-color: #7fffd4 !important;
}

.standard-color-azure {
  background-color: #f0ffff !important;
}

.standard-color-beige {
  background-color: #f5f5dc !important;
}

.standard-color-bisque {
  background-color: #ffe4c4 !important;
}

.standard-color-black {
  background-color: #000000 !important;
}

.standard-color-blanchedalmond,
.standard-color-blanched-almond {
  background-color: #ffebcd !important;
}

.standard-color-blue {
  background-color: #0000ff !important;
}

.standard-color-blueviolet,
.standard-color-blue-violet {
  background-color: #8a2be2 !important;
}

.standard-color-brown {
  background-color: #a52a2a !important;
}

.standard-color-burlywood,
.standard-color-burly-wood {
  background-color: #deb887 !important;
}

.standard-color-cadetblue,
.standard-color-cadet-blue {
  background-color: #5f9ea0 !important;
}

.standard-color-chartreuse {
  background-color: #7fff00 !important;
}

.standard-color-chocolate {
  background-color: #d2691e !important;
}

.standard-color-coral {
  background-color: #ff7f50 !important;
}

.standard-color-cornflowerblue,
.standard-color-cornflower-blue {
  background-color: #6495ed !important;
}

.standard-color-cornsilk {
  background-color: #fff8dc !important;
}

.standard-color-crimson {
  background-color: #dc143c !important;
}

.standard-color-cyan {
  background-color: #00ffff !important;
}

.standard-color-darkblue,
.standard-color-dark-blue {
  background-color: #00008b !important;
}

.standard-color-darkcyan,
.standard-color-dark-cyan {
  background-color: #008b8b !important;
}

.standard-color-darkgoldenrod,
.standard-color-dark-golden-rod {
  background-color: #b8860b !important;
}

.standard-color-darkgray,
.standard-color-dark-gray {
  background-color: #a9a9a9 !important;
}

.standard-color-darkgreen,
.standard-color-dark-green {
  background-color: #006400 !important;
}

.standard-color-darkkhaki,
.standard-color-dark-khaki {
  background-color: #bdb76b !important;
}

.standard-color-darkmagenta,
.standard-color-dark-magenta {
  background-color: #8b008b !important;
}

.standard-color-darkolivegreen,
.standard-color-dark-olive-green {
  background-color: #556b2f !important;
}

.standard-color-darkorange,
.standard-color-dark-orange {
  background-color: #ff8c00 !important;
}

.standard-color-darkorchid,
.standard-color-dark-orchid {
  background-color: #9932cc !important;
}

.standard-color-darkred,
.standard-color-dark-red {
  background-color: #8b0000 !important;
}

.standard-color-darksalmon,
.standard-color-dark-salmon {
  background-color: #e9967a !important;
}

.standard-color-darkseagreen,
.standard-color-dark-sea-green {
  background-color: #8fbc8f !important;
}

.standard-color-darkslateblue,
.standard-color-dark-slate-blue {
  background-color: #483d8b !important;
}

.standard-color-darkslategray,
.standard-color-dark-slate-gray {
  background-color: #2f4f4f !important;
}

.standard-color-darkturquoise,
.standard-color-dark-turquoise {
  background-color: #00ced1 !important;
}

.standard-color-darkviolet,
.standard-color-dark-violet {
  background-color: #9400d3 !important;
}

.standard-color-deeppink,
.standard-color-deep-pink {
  background-color: #ff1493 !important;
}

.standard-color-deepskyblue,
.standard-color-deep-sky-blue {
  background-color: #00bfff !important;
}

.standard-color-dimgray,
.standard-color-dim-gray {
  background-color: #696969 !important;
}

.standard-color-dimgrey,
.standard-color-dim-grey {
  background-color: #696969 !important;
}

.standard-color-dodgerblue,
.standard-color-dodger-blue {
  background-color: #1e90ff !important;
}

.standard-color-firebrick,
.standard-color-fire-brick {
  background-color: #b22222 !important;
}

.standard-color-floralwhite,
.standard-color-floral-white {
  background-color: #fffaf0 !important;
}

.standard-color-forestgreen,
.standard-color-forest-green {
  background-color: #228b22 !important;
}

.standard-color-fuchsia {
  background-color: #ff00ff !important;
}

.standard-color-gainsboro {
  background-color: #dcdcdc !important;
}

.standard-color-ghostwhite,
.standard-color-ghost-white {
  background-color: #f8f8ff !important;
}

.standard-color-gold {
  background-color: #ffd700 !important;
}

.standard-color-goldenrod,
.standard-color-golden-rod {
  background-color: #daa520 !important;
}

.standard-color-gray {
  background-color: #808080 !important;
}

.standard-color-grey {
  background-color: #808080 !important;
}

.standard-color-green {
  background-color: #008000 !important;
}

.standard-color-greenyellow,
.standard-color-green-yellow {
  background-color: #adff2f !important;
}

.standard-color-honeydew,
.standard-color-honey-dew {
  background-color: #f0fff0 !important;
}

.standard-color-hotpink,
.standard-color-hot-pink {
  background-color: #ff69b4 !important;
}

.standard-color-indianred,
.standard-color-indian-red {
  background-color: #cd5c5c !important;
}

.standard-color-indigo {
  background-color: #4b0082 !important;
}

.standard-color-ivory {
  background-color: #fffff0 !important;
}

.standard-color-khaki {
  background-color: #f0e68c !important;
}

.standard-color-lavender {
  background-color: #e6e6fa !important;
}

.standard-color-lavenderblush,
.standard-color-lavender-blush {
  background-color: #fff0f5 !important;
}

.standard-color-lawngreen,
.standard-color-lawn-green {
  background-color: #7cfc00 !important;
}

.standard-color-lemonchiffon,
.standard-color-lemon-chiffon {
  background-color: #fffacd !important;
}

.standard-color-lightblue,
.standard-color-light-blue {
  background-color: #add8e6 !important;
}

.standard-color-lightcoral,
.standard-color-light-coral {
  background-color: #f08080 !important;
}

.standard-color-lightcyan,
.standard-color-light-cyan {
  background-color: #e0ffff !important;
}

.standard-color-lightgoldenrodyellow,
.standard-color-light-golden-rod-yellow {
  background-color: #fafad2 !important;
}

.standard-color-lightgray,
.standard-color-light-gray {
  background-color: #d3d3d3 !important;
}

.standard-color-lightgrey,
.standard-color-light-grey {
  background-color: #d3d3d3 !important;
}

.standard-color-lightgreen,
.standard-color-light-green {
  background-color: #90ee90 !important;
}

.standard-color-lightpink,
.standard-color-light-pink {
  background-color: #ffb6c1 !important;
}

.standard-color-lightsalmon,
.standard-color-light-salmon {
  background-color: #ffa07a !important;
}

.standard-color-lightseagreen,
.standard-color-light-sea-green {
  background-color: #20b2aa !important;
}

.standard-color-lightskyblue,
.standard-color-light-sky-blue {
  background-color: #87cefa !important;
}

.standard-color-lightslategray,
.standard-color-light-slate-gray {
  background-color: #778899 !important;
}

.standard-color-lightsteelblue,
.standard-color-light-steel-blue {
  background-color: #b0c4de !important;
}

.standard-color-lightyellow,
.standard-color-light-yellow {
  background-color: #ffffe0 !important;
}

.standard-color-lime {
  background-color: #00ff00 !important;
}

.standard-color-limegreen,
.standard-color-lime-green {
  background-color: #32cd32 !important;
}

.standard-color-linen {
  background-color: #faf0e6 !important;
}

.standard-color-magenta {
  background-color: #ff00ff !important;
}

.standard-color-maroon {
  background-color: #800000 !important;
}

.standard-color-mediumaquamarine,
.standard-color-medium-aqua-marine {
  background-color: #66cdaa !important;
}

.standard-color-mediumblue,
.standard-color-medium-blue {
  background-color: #0000cd !important;
}

.standard-color-mediumorchid,
.standard-color-medium-orchid {
  background-color: #ba55d3 !important;
}

.standard-color-mediumpurple,
.standard-color-medium-purple {
  background-color: #9370d8 !important;
}

.standard-color-mediumseagreen,
.standard-color-medium-sea-green {
  background-color: #3cb371 !important;
}

.standard-color-mediumslateblue,
.standard-color-medium-slate-blue {
  background-color: #7b68ee !important;
}

.standard-color-mediumspringgreen,
.standard-color-medium-spring-green {
  background-color: #00fa9a !important;
}

.standard-color-mediumturquoise,
.standard-color-medium-turquoise {
  background-color: #48d1cc !important;
}

.standard-color-mediumvioletred,
.standard-color-medium-violet-red {
  background-color: #c71585 !important;
}

.standard-color-midnightblue,
.standard-color-midnight-blue {
  background-color: #191970 !important;
}

.standard-color-mintcream,
.standard-color-mint-cream {
  background-color: #f5fffa !important;
}

.standard-color-mistyrose,
.standard-color-misty-rose {
  background-color: #ffe4e1 !important;
}

.standard-color-moccasin {
  background-color: #ffe4b5 !important;
}

.standard-color-navajowhite,
.standard-color-navajo-white {
  background-color: #ffdead !important;
}

.standard-color-navy {
  background-color: #000080 !important;
}

.standard-color-oldlace,
.standard-color-old-lace {
  background-color: #fdf5e6 !important;
}

.standard-color-olive {
  background-color: #808000 !important;
}

.standard-color-olivedrab,
.standard-color-olive-drab {
  background-color: #6b8e23 !important;
}

.standard-color-orange {
  background-color: #ffa500 !important;
}

.standard-color-orangered,
.standard-color-orange-red {
  background-color: #ff4500 !important;
}

.standard-color-orchid {
  background-color: #da70d6 !important;
}

.standard-color-palegoldenrod,
.standard-color-pale-golden-rod {
  background-color: #eee8aa !important;
}

.standard-color-palegreen,
.standard-color-pale-green {
  background-color: #98fb98 !important;
}

.standard-color-paleturquoise,
.standard-color-pale-turquoise {
  background-color: #afeeee !important;
}

.standard-color-palevioletred,
.standard-color-pale-violet-red {
  background-color: #d87093 !important;
}

.standard-color-papayawhip,
.standard-color-papaya-whip {
  background-color: #ffefd5 !important;
}

.standard-color-peachpuff,
.standard-color-peach-puff {
  background-color: #ffdab9 !important;
}

.standard-color-peru {
  background-color: #cd853f !important;
}

.standard-color-pink {
  background-color: #ffc0cb !important;
}

.standard-color-plum {
  background-color: #dda0dd !important;
}

.standard-color-powderblue,
.standard-color-powder-blue {
  background-color: #b0e0e6 !important;
}

.standard-color-purple {
  background-color: #800080 !important;
}

.standard-color-red {
  background-color: #ff0000 !important;
}

.standard-color-rosybrown,
.standard-color-rosy-brown {
  background-color: #bc8f8f !important;
}

.standard-color-royalblue,
.standard-color-royal-blue {
  background-color: #4169e1 !important;
}

.standard-color-saddlebrown,
.standard-color-saddle-brown {
  background-color: #8b4513 !important;
}

.standard-color-salmon {
  background-color: #fa8072 !important;
}

.standard-color-sandybrown,
.standard-color-sandy-brown {
  background-color: #f4a460 !important;
}

.standard-color-seagreen,
.standard-color-sea-green {
  background-color: #2e8b57 !important;
}

.standard-color-seashell,
.standard-color-sea-shell {
  background-color: #fff5ee !important;
}

.standard-color-sienna {
  background-color: #a0522d !important;
}

.standard-color-silver {
  background-color: #c0c0c0 !important;
}

.standard-color-skyblue,
.standard-color-sky-blue {
  background-color: #87ceeb !important;
}

.standard-color-slateblue,
.standard-color-slate-blue {
  background-color: #6a5acd !important;
}

.standard-color-slategray,
.standard-color-slate-gray {
  background-color: #708090 !important;
}

.standard-color-slategrey,
.standard-color-slate-grey {
  background-color: #708090 !important;
}

.standard-color-snow {
  background-color: #fffafa !important;
}

.standard-color-springgreen,
.standard-color-spring-green {
  background-color: #00ff7f !important;
}

.standard-color-steelblue,
.standard-color-steel-blue {
  background-color: #4682b4 !important;
}

.standard-color-tan {
  background-color: #d2b48c !important;
}

.standard-color-teal {
  background-color: #008080 !important;
}

.standard-color-thistle {
  background-color: #d8bfd8 !important;
}

.standard-color-tomato {
  background-color: #ff6347 !important;
}

.standard-color-turquoise {
  background-color: #40e0d0 !important;
}

.standard-color-violet {
  background-color: #ee82ee !important;
}

.standard-color-wheat {
  background-color: #f5deb3 !important;
}

.standard-color-whitesmoke,
.standard-color-white-smoke {
  background-color: #f5f5f5 !important;
}

.standard-color-yellow {
  background-color: #ffff00 !important;
}

.standard-color-yellowgreen,
.standard-color-yellow-green {
  background-color: #9acd32 !important;
}

.standard-color-transparent {
  background-color: transparent !important;
}

.standard-color-arrow svg {
  fill: #FFFFFF !important;
}

.standard-color-white .standard-color-arrow svg,
.standard-color-antiquewhite .standard-color-arrow svg,
.standard-color-antique-white .standard-color-arrow svg,
.standard-color-navajowhite .standard-color-arrow svg,
.standard-color-navajo-white .standard-color-arrow svg,
.standard-color-floralwhite .standard-color-arrow svg,
.standard-color-floral-white .standard-color-arrow svg,
.standard-color-ghostwhite .standard-color-arrow svg,
.standard-color-ghost-white .standard-color-arrow svg,
.standard-color-whitesmoke .standard-color-arrow svg,
.standard-color-white-smoke .standard-color-arrow svg,
.standard-color-lightgray .standard-color-arrow svg,
.standard-color-light-gray .standard-color-arrow svg,
.standard-color-lightslategray .standard-color-arrow svg,
.standard-color-light-slate-gray .standard-color-arrow svg,
.standard-color-lightslategray .standard-color-arrow svg,
.standard-color-light-slate-gray .standard-color-arrow svg {
  fill: #000000 !important;
}

.slick-slide {
  outline: none;
}

.shopify-review--type-1 .shopify-review-icon {
  width: 14px;
  min-width: 14px;
  min-height: 14px;
  height: 8px;
}

.shopify-review--type-1 .shopify-review-icon .icon {
  width: 22px;
  min-width: 22px;
  margin-left: -4px;
}

.shopify-review--type-1 .shopify-review-starrating {
  height: 15px;
}

.shopify-review--type-2 .shopify-review-icon {
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  height: 10px;
}

.shopify-review--type-2 .shopify-review-icon .icon {
  width: 24px;
  min-width: 24px;
  margin-left: -5px;
}

.shopify-review--type-2 .shopify-review-starrating {
  height: 17px;
}

.shopify-review-starrating .shopify-review-icon:not(:last-child) {
  margin-right: 4px;
}

#shopify-product-reviews {
  margin: 0 !important;
}

.spr-icon.spr-icon-star-empty {
  opacity: 1 !important;
}

.spr-container {
  padding: 0 !important;
  border: none !important;
}

.spr-review-header-title {
  text-transform: uppercase;
}

.spr-review-header-byline,
.spr-review-content-body {
  margin-top: 10px !important;
}

.spr-header-title {
  margin-bottom: 6px !important;
}

.spr-review-header-byline {
  margin-bottom: 0 !important;
}

.spr-summary {
  margin-top: 6px !important;
  display: flex;
  align-items: flex-end;
}

.spr-summary .spr-starrating {
  margin-right: 10px;
}

.spr-summary-actions {
  margin-left: auto;
}

.spr-review:first-child {
  margin-top: 30px !important;
}

.spr-review {
  position: relative;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  border-top-style: dashed !important;
}

.spr-starratings {
  margin-bottom: 12px !important;
}

.spr-review-reportreview {
  position: absolute;
  top: 27px;
  right: 0;
  text-decoration: underline !important;
}

.spr-review-content {
  margin-bottom: 0 !important;
}

.spr-form {
  padding-top: 25px !important;
  margin-top: 30px !important;
  border-top-style: dashed !important;
}

.spr-form-title {
  margin-bottom: 20px;
}

.spr-form-label {
  text-transform: uppercase;
}

.spr-form-actions {
  margin-top: 15px !important;
}

.spr-button {
  float: left !important;
}

.weglot-container {
  display: none !important;
}

.weglot-container.show {
  display: block !important;
}

.irs .irs-line,
.irs .irs-line span,
.irs .irs-bar {
  height: 2px;
}

.irs .irs-from,
.irs .irs-to,
.irs .irs-single,
.irs .irs-min,
.irs .irs-max {
  padding: 0;
}

.irs .irs-from::after,
.irs .irs-to::after,
.irs .irs-single::after {
  display: none;
}

.irs .irs-slider {
  border-radius: 50%;
  border: solid 1px transparent;
  cursor: pointer;
}

.fullscreen {
  width: auto !important;
  height: auto !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: inherit !important;
  padding: inherit !important;
  overflow: auto !important;
}

.fotorama--fullscreen {
  position: fixed !important;
}

.fotorama__html+.fotorama__video-play {
  display: none !important;
}

@media (min-width: 768px) {
  .slider-revolution .tp-bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

@media (min-width: 768px) {
  .slider-revolution .tp-bullet.selected {
    width: 10px;
    height: 10px;
    margin-top: -2px;
    margin-left: -2px;
    margin-right: -2px;
    cursor: default;
  }
}

.slider-revolution .tp-resizeme .btn {
  border-width: 1px !important;
}

.slider-revolution .tp-video-play-button {
  margin: 0;
  top: auto;
  bottom: 40px;
  left: auto;
  right: 40px;
}

.slider-revolution.revslider-initialised+.slider-revolution__spacer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}

.is-edge .slider-revolution video {
  width: auto !important;
  height: auto !important;
}

.tippy-tooltip.shella-theme {
  font-style: italic;
  border-radius: 0;
  height: 15px;
  padding: 0 6px 0 4px;
}

.tippy-tooltip.shella-theme .tippy-arrow {
  border-width: 3px;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.shella-theme .tippy-arrow {
  bottom: -3px;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.shella-theme .tippy-arrow {
  top: -3px;
}

.tippy-popper[x-placement^='left'] .tippy-tooltip.shella-theme .tippy-arrow {
  right: -3px;
}

.tippy-popper[x-placement^='right'] .tippy-tooltip.shella-theme .tippy-arrow {
  left: -3px;
}

#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  pointer-events: none;
}

.ly-languages-switcher.ly-custom-dropdown-switcher .ly-custom-dropdown-list {
  width: auto !important;
}

.ly-languages-switcher.ly-custom-dropdown-switcher .ly-custom-dropdown-list li,
.ly-popup-modal-content li {
  padding: 0 !important;
}

.ly-languages-switcher.ly-custom-dropdown-switcher .ly-custom-dropdown-list li::before,
.ly-popup-modal-content li::before {
  display: none !important;
}

.rimage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.rimage--parallax-fixed {
  background-size: cover;
  background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
  .rimage--parallax-fixed {
    background-attachment: scroll;
  }
}

.rimage__img {
  position: absolute;
  max-width: initial;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rimage__img--cover {
  object-fit: cover;
}

.rimage__img--contain {
  object-fit: contain;
}

.rimage__img--stretch-by-width {
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
}

.rimage__img--stretch-by-height {
  width: auto !important;
  height: 100% !important;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rvideo {
  position: relative;
}

.rvideo__video {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  background-position: center;
  object-fit: cover;
  object-position: 50% 50%;
  transform: translate3d(-50%, -50%, 0);
}

input,
textarea,
select {
  border-radius: 0;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea,
select {
  padding: 0 15px;
  margin-bottom: 20px;
  background-color: white;
  border: solid 1px transparent;
  will-change: background-color, border-color, color;
  transition-property: background-color, border-color, color;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 7px;
  vertical-align: -1px;
}

textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
}

label {
  margin-bottom: 2px;
  margin-right: 15px;
}

fieldset {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
}

form {
  margin-bottom: 20px;
}

.label-hidden {
  display: none;
}

.label-required::after {
  content: '*';
}

.note {
  position: relative;
  display: inline-block;
  min-height: 25px;
  padding: 4px 15px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.note::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  left: 15px;
  border: solid 4px transparent;
  border-bottom-width: 5px;
}

.note p {
  margin-top: 15px;
}

.input-quantity input[type="number"] {
  -moz-appearance: textfield;
}

.input-quantity input[type="number"]::-webkit-inner-spin-button,
.input-quantity input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input-quantity--type-01 [data-control] {
  min-width: 36px;
  transition-property: border-color;
}

.input-quantity--type-02 input {
  width: 62px;
  min-width: 62px;
}

.input-quantity--type-02 [data-control] {
  min-width: 26px;
}

.input-quantity--type-03 input {
  width: 100px;
  min-width: 100px;
}

.input-quantity--type-04 input {
  width: 102px;
  min-width: 102px;
}

.input-quantity--type-04 [data-control] {
  min-width: 26px;
}

.input-quantity--type-05 input {
  width: 62px;
  min-width: 62px;
}

.input-quantity--type-05 [data-control] {
  width: 35px;
  min-width: 35px;
}

.input-checkbox input {
  display: none;
}

.input-checkbox input+span {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-top: -2px !important;
}

.input-checkbox span i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.input-checkbox input:checked+span i {
  display: flex !important;
}

.input-boolean input {
  display: none;
}

.input-boolean input+span {
  width: 30px;
  height: 18px;
  min-width: 30px;
  border-radius: 10px;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
}

.input-boolean input+span span {
  width: 16px;
  min-width: 16px;
  height: 100%;
  left: -1px;
  border-radius: 50%;
  transition-property: left;
  transition-timing-function: ease;
}

.input-boolean input:checked+span span {
  left: calc(100% - 15px);
}

.select {
  z-index: 1;
}

.select.hovered {
  z-index: 2;
}

.select select {
  height: 35px;
}

.is-ios .select select {
  padding-right: 0 !important;
}

.btn[disabled],
.btn[data-disabled],
.btn.disabled,
.btn:disabled,
.spr-summary-actions-newreview[disabled],
.spr-summary-actions-newreview[data-disabled],
.spr-summary-actions-newreview.disabled,
.spr-summary-actions-newreview:disabled,
.spr-button[disabled],
.spr-button[data-disabled],
.spr-button.disabled,
.spr-button:disabled {
  pointer-events: none;
  opacity: 0.4;
}

.spr-button {
  box-shadow: none !important;
}

.shopify-payment-button__button[disabled] {
  pointer-events: none;
  opacity: 0.7 !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button .shopify-cleanslate {
  width: 100% !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button .shopify-cleanslate div[role="button"] {
  min-height: initial !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button .checkoutLight {
  min-height: initial !important;
}

.shopify-payment-button__more-options {
  outline: none !important;
}

.btn,
.btn span,
.btn-link,
.btn-link span {
  white-space: normal;
}

.btn-link {
  text-decoration: underline;
  cursor: pointer;
}

.btn-link--undecorated {
  text-decoration: none;
}

.btn,
.btn-link,
.spr-summary-actions-newreview {
  will-change: background-color, border-color, color;
}

.btn .icon {
  transition-property: fill;
  will-change: fill;
}

.btn--animated {
  overflow: hidden;
}

.btn--status:not([data-button-status='added']) [data-button-content='added'],
.btn--status:not([data-button-status='sold-out']) [data-button-content='sold-out'],
.btn--status:not([data-button-status='pre-order']) [data-button-content='pre-order'],
.btn--status:not([data-button-status='select']) [data-button-content='select'] {
  display: none !important;
}

.btn--status[data-button-status='added']>*:not([data-button-content='added']) {
  display: none !important;
}

.btn--status[data-button-status='sold-out']>*:not([data-button-content='sold-out']) {
  display: none !important;
}

.btn--status[data-button-status='pre-order']>*:not([data-button-content='pre-order']) {
  display: none !important;
}

.btn--status[data-button-status='select']>*:not([data-button-content='select']) {
  display: none !important;
}

.btn--status[data-button-status='select'][data-button-select-disable] {
  pointer-events: none;
}

.btn--animation-shake:not([data-button-status="sold-out"]) {
  -webkit-animation: btn-shake 5s ease 2s infinite;
  animation: btn-shake 5s ease 2s infinite;
}

@-webkit-keyframes btn-shake {
  3% {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
  }

  6% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }

  8% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  10% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  13% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }

  16%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes btn-shake {
  3% {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
  }

  6% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }

  8% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  10% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  13% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }

  16%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.is-edge .btn--status[data-button-status='added']>[data-button-content='added'] i {
  margin-bottom: 0 !important;
}

@media (min-width: 768px) {
  html:not([dir='rtl']) .buttons-group-grid-px-5>*:not(:first-child) {
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  html:not([dir='rtl']) .buttons-group-grid-px-5>*:not(:last-child) {
    padding-right: 5px;
  }
}

@media (min-width: 768px) {
  [dir='rtl'] .buttons-group-grid-px-5>*:not(:first-child) {
    padding-right: 5px;
  }
}

@media (min-width: 768px) {
  [dir='rtl'] .buttons-group-grid-px-5>*:not(:last-child) {
    padding-left: 5px;
  }
}

.label {
  height: 23px;
  padding: 2px 6px;
}

.price {
  display: inline-block;
}

.price--sale>span:first-child {
  text-decoration: line-through;
}

.price--sale>span:not(:first-child)::before {
  display: inline-block;
  content: '\00a0';
}

.button-quick-view {
  width: 35px;
  height: 35px;
}

.responsive-block__bg {
  background-size: cover;
  background-position: center center;
}

.model-viewer-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.model-viewer-wrapper>* {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.header {
  z-index: 3;
}

.header__spacer {
  display: none;
}

.header__spacer--visible {
  display: block;
  position: relative;
  width: 100%;
}

.header__tape {
  min-height: 35px;
}

.header__content--sticky {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 3;
  will-change: opacity;
  transition-property: opacity;
}

.header__content--sticky:hover {
  opacity: 1 !important;
}

.header__line-info ul li:not(:first-child) {
  margin-left: 18px;
}

.header__logo--center svg,
.header__logo--center img {
  width: 100%;
}

.header__counter {
  position: absolute;
  top: -3px;
  left: 22px;
}

.header__counter[data-js-cart-count-mobile='0'],
.header__counter[data-js-wishlist-count='0'],
.header__counter[data-js-compare-count='0'] {
  display: none !important;
}

.header__counter[data-js-cart-count-mobile='0']+.header__counter-spacer {
  display: none !important;
}

.header__sticky-logo {
  z-index: 2;
}

@media (min-width: 1380px) {
  .header__content--sticky .header__sticky-logo * {
    height: 100%;
  }
}

@media (min-width: 1380px) {
  .header__content--sticky .header__sticky-logo img {
    width: auto;
  }
}

@media (min-width: 1380px) {
  .header__content--sticky .header__sticky-logo--displaced {
    display: flex !important;
  }
}

@media (min-width: 1540px) {
  .header__sticky-sidebar {
    display: flex !important;
  }
}

.header__btn-currency span {
  min-width: 28px;
}

.header__select-currency select {
  height: 26px !important;
  min-height: 26px;
}

[data-js-accordion]:not([data-js-accordion="only-mobile"]) [data-js-accordion-button] {
  cursor: pointer;
}

.slider__prev,
.slider__next {
  top: 50%;
  margin-top: -20px;
  z-index: 1;
}

.slider__prev {
  left: 30px;
}

.slider__next {
  right: 30px;
}

.slider .slick-slide {
  outline: none;
}

.slider .slick-dots,
.slider .slick-arrow {
  z-index: 9999;
}

.slider .slick-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

@media (max-width: 1259px) {
  .slider .slick-dots {
    margin-bottom: 25px;
  }
}

.slider .slick-dots li {
  width: 6px;
  height: 6px;
  min-width: 6px;
  padding: 0;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.slider .slick-dots li::before {
  display: none;
}

.slider .slick-dots li.slick-active {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.slider .slick-dots button {
  display: none;
}

.breadcrumbs ul {
  margin-left: -3px;
  margin-right: -3px;
}

.breadcrumbs a,
.breadcrumbs span {
  padding-left: 3px;
  padding-right: 3px;
}

.breadcrumbs li:not(:first-child)::before {
  content: '/';
  padding-left: 3px;
  padding-right: 3px;
}

.social-media {
  margin-bottom: -5px;
}

.social-media__facebook i {
  margin-right: -4px;
}

.social-media i {
  min-width: 17px;
}

.social-share {
  margin-bottom: -10px;
}

.social-share .row {
  margin-left: -5px;
  margin-right: -5px;
}

.social-share__item {
  transition-property: color, opacity;
}

.social-share__item .icon {
  transition-property: fill;
}

.social-share__item_content {
  min-height: 35px;
}

.social-share__item_bg {
  opacity: 0.1;
  transition-property: opacity;
}

.countdown--type-01>span {
  height: 35px;
  min-width: 35px;
}

.countdown--type-01>span::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
}

.countdown--type-01 .countdown__section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 1px;
  margin-right: 1px;
}

.countdown--type-01 .countdown__time,
.countdown--type-01 .countdown__postfix {
  position: relative;
}

.countdown--type-02 .countdown__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.countdown--type-02 .countdown__section:not(:last-child) {
  margin-right: 18px;
}

.countdown--type-02 .countdown__section:not(:last-child) .countdown__time::after {
  content: ':';
  display: block;
  width: 18px;
  position: absolute;
  right: -18px;
  top: 50%;
  margin-top: -14px;
  text-align: center;
}

.countdown--type-02 .countdown__time {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown--type-02 .countdown__time>* {
  width: 30px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
}

.countdown--type-02 .countdown__time>*:not(:last-child) {
  margin-right: 4px;
}

.countdown--type-02 .countdown__postfix {
  margin-top: 10px;
}

.stock-countdown__range,
.stock-countdown__progress {
  height: 6px;
  border-radius: 6px;
}

.delivery-countdown i {
  vertical-align: -4px;
}

.free-shipping i {
  vertical-align: -4px;
}

.dropdown span {
  cursor: pointer;
}

.dropdown li.active a,
.dropdown li.active span {
  cursor: default;
}

.table-product-sizes tr:first-child td {
  border-top: none;
}

.table-product-sizes td:first-child {
  width: 110px;
}

.material-info__head-icons {
  max-width: 190px;
}

.material-info__list li {
  display: flex;
  align-items: center;
}

.material-info__list i {
  display: inline-block;
  margin-right: 10px;
}

.tabs {
  overflow: hidden;
}

.tabs__nav {
  display: none;
}

.tabs[data-type="horizontal"] .tabs__body>div {
  display: block;
}

.tabs[data-type="horizontal"] .tabs__body>div>div {
  display: none;
}

.tabs[data-type="mobile"] .tabs__body {
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
}

.tabs[data-type="mobile"] .tabs__body>div {
  display: block;
}

.tabs[data-type="mobile"] .tabs__body>div:not(:first-child)>span {
  min-height: 33px;
  border-top: solid 1px transparent;
}

.tabs[data-type="mobile"] .tabs__body>div>span {
  position: relative;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
}

.tabs[data-type="mobile"] .tabs__body>div>span i {
  position: absolute;
  top: 6px;
  transition-property: transform;
  will-change: transform;
}

.tabs[data-type="mobile"] .tabs__body>div.active>span i {
  transform: rotate(45deg);
}

.tabs[data-type="mobile"] .tabs__body>div>div {
  display: none;
}

.tabs[data-type="mobile"] .tabs__head {
  display: none;
}

@media (min-width: 768px) {
  .sticky-sidebar {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .sticky-sidebar.sidebar-fixed-top [data-js-sticky-sidebar-body] {
    position: fixed;
    top: 15px;
  }
}

@media (min-width: 768px) {
  .sticky-sidebar.sidebar-fixed-bottom [data-js-sticky-sidebar-body] {
    position: fixed;
    bottom: 15px;
  }
}

@media (min-width: 768px) {
  .sticky-sidebar.sidebar-static-bottom {
    justify-content: flex-end;
  }
}

.product-options--centered {
  text-align: center;
}

.product-options--centered label {
  margin-right: 0;
}

.product-options--centered .product-options__section {
  justify-content: center;
}

.product-options__value {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  outline: none;
}

.product-options__value.disabled-hidden {
  position: relative;
}

.product-options__value.disabled-hidden span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.product-options__value.disabled-hidden span::before,
.product-options__value.disabled-hidden span::after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  top: 50%;
  left: 50%;
}

.product-options__value.disabled-hidden span::before {
  transform: translate3d(-50%, 0, 0) rotate(45deg);
}

.product-options__value.disabled-hidden span::after {
  transform: translate3d(-50%, 0, 0) rotate(-45deg);
}

.product-options__value--square {
  width: 46px;
  height: 46px;
}

.product-options__value--square.active {
  border: solid 1px transparent;
}

.product-options__value--square.disabled-hidden span::before,
.product-options__value--square.disabled-hidden span::after {
  width: 64px;
}

.product-options__value--text {
  min-height: 25px;
  padding: 0 4px;
}

.product-options__value--text.disabled-hidden span::before,
.product-options__value--text.disabled-hidden span::after {
  width: 34px;
}

.product-options__value--circle {
  display: inline-flex;
  position: relative;
}

.product-options__value--circle.active {
  justify-content: center;
  align-items: center;
}

.product-options__value--circle.active::before {
  content: '';
  position: absolute;
  border: solid 1px transparent;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.product-options--type-collection .product-options__value--circle {
  width: 18px;
  height: 18px;
}

.product-options--type-collection .product-options__value--circle.active::before {
  width: 22px;
  height: 22px;
}

.product-options--type-collection .product-options__value--circle.disabled-hidden span::before,
.product-options--type-collection .product-options__value--circle.disabled-hidden span::after {
  width: 18px;
}

.product-options--type-collection .product-options__value--large-text {
  min-width: 25px;
  min-height: 25px;
  padding: 0 4px;
}

.product-options--type-collection .product-options__value--large-text.disabled-hidden span::before,
.product-options--type-collection .product-options__value--large-text.disabled-hidden span::after {
  width: 34px;
}

.product-options--type-page [data-section-container]:not(:last-child) .product-options__section,
.product-options--type-footbar [data-section-container]:not(:last-child) .product-options__section {
  margin-bottom: 8px;
}

@media (min-width: 768px) {

  .product-options--type-page label,
  .product-options--type-footbar label {
    margin-bottom: 5px;
  }
}

.product-options--type-page .product-options__value--circle,
.product-options--type-footbar .product-options__value--circle {
  width: 24px;
  height: 24px;
}

.product-options--type-page .product-options__value--circle.active::before,
.product-options--type-footbar .product-options__value--circle.active::before {
  width: 28px;
  height: 28px;
}

.product-options--type-page .product-options__value--circle.disabled-hidden span::before,
.product-options--type-page .product-options__value--circle.disabled-hidden span::after,
.product-options--type-footbar .product-options__value--circle.disabled-hidden span::before,
.product-options--type-footbar .product-options__value--circle.disabled-hidden span::after {
  width: 24px;
}

.product-options--type-page .product-options__value--large-text,
.product-options--type-footbar .product-options__value--large-text {
  min-width: 35px;
  min-height: 35px;
  padding: 0 9px;
}

.product-options--type-page .product-options__value--large-text.disabled-hidden span::before,
.product-options--type-page .product-options__value--large-text.disabled-hidden span::after {
  width: 47px;
}

.product-options--type-footbar .product-options__value--large-text.disabled-hidden span::before,
.product-options--type-footbar .product-options__value--large-text.disabled-hidden span::after {
  width: 33px;
}

.product-options__section[data-style="color"] .standard-color-black.disabled-hidden span::after,
.product-options__section[data-style="color"] .standard-color-black.disabled-hidden span::before {
  background-color: #ffffff;
}

.product-options__section[data-disable-auto-select] .product-options__value--circle.active::before {
  display: none !important;
}

.product-images-navigation span {
  width: 35px;
  height: 35px;
  will-change: background-color;
  transition-property: background-color;
}

.product-images-navigation span[data-disabled] {
  opacity: 0.4;
  pointer-events: none;
}

.product-images-navigation span i {
  will-change: color;
  transition-property: color;
}

.product-image img {
  width: 100%;
}

.product-image:not(.hovered-end) [data-js-tooltip] {
  pointer-events: none;
}

[data-promobox-grid-visible] {
  display: none;
}

.product-collection--centered .product-collection__content {
  align-items: center !important;
  text-align: center;
}

.product-collection--centered .product-collection__buttons,
.product-collection--centered .product-collection__buttons-section {
  justify-content: center;
}

.product-collection--centered .product-options__section {
  justify-content: center;
}

.product-wishlist--centered .product-wishlist__content {
  align-items: center !important;
  text-align: center;
}

.product-wishlist--centered .product-wishlist__buttons,
.product-wishlist--centered .product-wishlist__buttons-section {
  justify-content: center;
}

.product-compare--centered .product-compare__content {
  align-items: center !important;
  text-align: center;
}

.product-compare--centered .product-compare__buttons,
.product-compare--centered .product-compare__buttons-section {
  justify-content: center;
}

.product-search__image img {
  width: 100%;
}

.product-search__image-overlay-top {
  padding-left: 10px;
  padding-right: 10px;
}

.product-search__image-overlay-top {
  padding-top: 10px;
}

.product-search-2__image {
  width: 60px;
  min-width: 60px;
}

.product-search-2__image img {
  width: 100%;
}

.product-cart__image {
  width: 60px;
  min-width: 60px;
}

.product-cart__image img {
  width: 100%;
}

.product-cart__price .price {
  display: inline-block;
}

.product-cart__remove.active {
  opacity: 0.4;
}

.product-store-lists__image {
  width: 60px;
  min-width: 60px;
}

.product-store-lists__image img {
  width: 100%;
}

.product-featured__image {
  width: 100px;
  min-width: 100px;
}

.product-featured__image img {
  width: 100%;
}

.product-notification__image {
  width: 60px;
  min-width: 60px;
}

.product-notification__image .rimage {
  height: 100%;
}

.product-notification__button-quick-view {
  margin-right: -2px;
  margin-bottom: -4px;
}

.product-notification__button-quick-view .button-quick-view {
  width: 20px;
  height: 20px;
}

.dynamic-checkout__button.disabled .shopify-payment-button__button {
  opacity: 0.7;
  pointer-events: none;
}

.dynamic-checkout__button [aria-hidden="true"] {
  display: none;
}

@media (min-width: 768px) {
  .product-gallery--single {
    max-width: 510px;
  }
}

.product-gallery video {
  outline: none;
}

.product-gallery__main_slider.is-zooming img,
.product-gallery__main_single.is-zooming img,
.product-gallery__main_item.is-zooming img {
  cursor: crosshair;
}

.product-gallery__main_arrow {
  width: 35px;
  height: 35px;
  top: 50%;
  margin-top: -17px;
  z-index: 2;
}

.product-gallery__main_arrow::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
}

.product-gallery__main_arrow.slick-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.product-gallery__main_arrow.slick-hidden {
  display: none !important;
}

@media (min-width: 768px) {
  .product-gallery__main_slider .slick-list[style*="overflow"] img {
    pointer-events: none;
  }
}

@media (min-width: 768px) {
  .product-gallery__main_item.slick-active {
    z-index: 1;
  }
}

.product-gallery__main_arrows--type-1 .product-gallery__main_arrow--type-prev {
  left: 10px;
}

.product-gallery__main_arrows--type-1 .product-gallery__main_arrow--type-next {
  right: 10px;
}

@media (max-width: 1259px) {
  .product-gallery__main_arrows--type-2 .product-gallery__main_arrow--type-prev {
    left: 10px;
  }
}

@media (max-width: 1259px) {
  .product-gallery__main_arrows--type-2 .product-gallery__main_arrow--type-next {
    right: 10px;
  }
}

.product-gallery__zoom {
  z-index: 2;
}

.product-gallery__zoom--fullscreen {
  height: 100vh;
  z-index: 3;
}

.product-gallery__zoom_container_zoomed {
  top: 0;
  left: 0;
  /*bottom: -200%;
        right: -200%;*/
}

.product-gallery__zoom_image {
  will-change: transform;
}

.product-gallery__thumbnail_item.current .product-gallery__thumbnail_item_inner::before {
  content: '';
  display: block;
  border: solid 1px transparent;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .product-gallery__thumbnail_item:not(.current) .product-gallery__thumbnail_item_inner:hover>* {
    opacity: 0.8;
  }
}

@media (min-width: 768px) {
  .product-gallery__thumbnail--collage .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.product-gallery__thumbnail_item_bg {
  opacity: 0.6;
}

.product-gallery__thumbnail_arrows {
  margin-bottom: -8px;
}

.product-gallery__thumbnail_arrow.slick-hidden {
  display: none !important;
}

@media (min-width: 768px) {
  .product-gallery__sheet {
    margin-bottom: -10px;
  }
}

@media (min-width: 768px) {
  .product-gallery__sheet:not([data-js-product-gallery-control-fullscreen]) .product-gallery__sheet_item.is-zooming img {
    cursor: crosshair;
  }
}

@media (min-width: 768px) {
  .product-gallery__sheet .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 768px) {
  .product-gallery__control .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.product-gallery__fullscreen {
  z-index: 3;
  transform: scale(0.9);
  opacity: 0;
}

.product-gallery__fullscreen.animate {
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.product-gallery__fullscreen.visible {
  opacity: 1;
  transform: none;
}

.product-gallery__fullscreen.show {
  display: block !important;
}

.product-gallery__fullscreen_close {
  width: 36px;
  height: 36px;
}

.product-gallery__fullscreen_arrows {
  padding-top: 100vh;
}

.product-gallery__fullscreen_arrow,
.product-gallery__fullscreen_close {
  z-index: 2;
}

.product-gallery__fullscreen_arrow_btn::before,
.product-gallery__fullscreen_close::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
}

.product-gallery__fullscreen_arrow {
  height: 100%;
  width: 10%;
  max-width: 190px;
  min-width: 55px;
}

.product-gallery__fullscreen_arrow_btn {
  width: 35px;
  height: 35px;
}

.product-gallery__fullscreen_arrow.slick-disabled .product-gallery__fullscreen_arrow_btn {
  opacity: 0.4;
  pointer-events: none;
}

.product-gallery__fullscreen_arrow.slick-hidden .product-gallery__fullscreen_arrow_btn {
  display: none !important;
}

.product-gallery__fullscreen .slick-slider,
.product-gallery__fullscreen .slick-list,
.product-gallery__fullscreen .slick-track {
  height: 100% !important;
}

.product-gallery__fullscreen .rvideo,
.product-gallery__fullscreen .model-viewer-wrapper,
.product-gallery__fullscreen .embed-responsive {
  height: 100% !important;
  padding-top: initial !important;
}

.product-gallery__fullscreen model-viewer,
.product-gallery__fullscreen .embed-responsive {
  object-fit: contain;
}

.product-gallery__fullscreen .rvideo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-gallery__fullscreen .rvideo__video {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  min-width: auto !important;
  min-height: auto !important;
  object-fit: initial;
  object-position: initial !important;
  transform: initial !important;
}

.product-gallery__fullscreen .product-gallery__blockratio {
  width: 100% !important;
  padding-top: 100vh !important;
}

.product-gallery__fullscreen_slider.is-zooming img {
  opacity: 0;
}

.product-gallery__fullscreen_slider.is-zooming+.product-gallery__fullscreen_arrows {
  pointer-events: none;
}

body.product-gallery-fullscreen #MainContent {
  z-index: 3;
}

body.product-gallery-fullscreen .footbar {
  z-index: 2;
}

body.product-gallery-fullscreen .popup-quick-view {
  overflow: visible !important;
}

@media (min-width: 768px) {
  .product-page-info--max-width {
    width: 100%;
    max-width: 765px;
  }
}

@media (min-width: 768px) {
  .product-page-info__section--max-width {
    width: 100%;
    max-width: 370px;
  }
}

.product-page-info__labels>* {
  margin-bottom: 15px !important;
}

.product-page-info__notes textarea {
  min-height: 80px;
}

.product-page-info__payments svg {
  max-width: 62px;
  width: 100%;
}

.product-page-info klarna-placement>* {
  margin-top: 20px !important;
}

.product-page-info form klarna-placement>* {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.product-fixed-sizebar {
  width: 270px;
  transition-property: transform, box-shadow;
  will-change: transform, box-shadow;
}

.product-fixed-sizebar:hover {
  transform: none !important;
}

.product-fixed-sizebar__btn {
  z-index: 999;
  width: 20px;
  height: 20px;
}

.product-fixed-sizebar__btn::after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 100%;
  right: -10px;
}

.product-fixed-sizebar:hover .product-fixed-sizebar__btn i {
  transform: rotate3d(0, 1, 0, 180deg);
}

.product-fixed-sizebar__line:not(:first-child) {
  border-top: solid 1px transparent;
  transition-property: border-color;
}

.product-fixed-sizebar__line i {
  vertical-align: middle;
  margin-right: 10px;
}

.one-product__bg_image {
  background-size: cover;
  background-repeat: no-repeat;
}

.one-product__image {
  max-width: 470px;
}

.one-product__image-navigation {
  z-index: 1;
}

@media (min-width: 768px) {
  .one-product__bg_spacer+.one-product__bg_image[style*="background-image"]~.one-product__content {
    position: absolute !important;
  }
}

.one-product-info__quantity label {
  text-transform: uppercase;
}

.one-product-info__buttons-section {
  margin-left: -9px;
  margin-right: -9px;
}

.popup {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999999;
  backface-visibility: hidden;
  outline: none;
  -webkit-overflow-scrolling: touch;
}

.popup.show {
  display: block !important;
}

.popup__bg {
  top: -100px;
  bottom: -100px;
  opacity: 0;
  z-index: -1;
}

.popup__bg.animate {
  transition-property: opacity;
  will-change: opacity;
}

.popup__bg.visible {
  opacity: 0.5;
}

.popup__body {
  min-height: 100%;
}

.popup__body.show {
  display: flex !important;
}

.popup__body [data-popup-content] {
  opacity: 0;
}

.popup__body.animate [data-popup-content] {
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.popup__body.visible [data-popup-content] {
  transform: none !important;
  opacity: 1 !important;
}

.popup [data-popup-left] [data-popup-content] {
  transform: translateX(-100%);
}

.popup [data-popup-right] [data-popup-content] {
  transform: translateX(100%);
}

.popup [data-popup-center] [data-popup-content] {
  transform: scale(0.9);
}

body.popup-bg-blur.popup-opened main {
  filter: blur(3px);
}

.popup-navigation__button[data-js-popup-navigation-button='close']>*:not([data-button-content='close']) {
  display: none !important;
}

.popup-sidebar {
  max-width: 100%;
}

.popup-sidebar--width-md {
  width: 270px;
}

.popup-sidebar--width-lg {
  width: 332px;
}

.popup-cart {
  width: 270px;
  max-width: 100%;
}

.popup-cart__items>*:not(:first-child) {
  padding-top: 20px;
  border-top: dashed 1px;
}

.popup-wishlist {
  width: 270px;
  max-width: 100%;
}

.popup-wishlist-full {
  max-width: 100%;
}

@media (min-width: 541px) {
  .popup-wishlist-full {
    width: 90%;
  }
}

.popup-wishlist-full__content {
  margin: 0 -20px;
}

.popup-compare-full {
  max-width: 100%;
}

@media (min-width: 541px) {
  .popup-compare-full {
    width: 90%;
  }
}

.popup-compare-full__content {
  margin: 0 -20px;
}

.popup-account {
  width: 270px;
  max-width: 100%;
}

.popup-account__authorized h5 {
  text-transform: uppercase;
}

.popup-services {
  width: 270px;
  max-width: 100%;
}

.popup-services__content [class*='icon-'] {
  margin-top: -2px;
}

.popup-subscription {
  max-width: 100%;
}

.popup-subscription__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-subscription__width-col {
  width: 300px;
}

.popup-subscription__close-text:hover {
  text-decoration: none !important;
}

.popup-subscription__btn {
  width: 160px;
}

.popup-subscription--layout-01 {
  width: 510px;
}

.popup-subscription--layout-01 .popup-subscription__content {
  max-width: 390px;
}

.popup-subscription--layout-02 .popup-subscription__image,
.popup-subscription--layout-02 .popup-subscription__image>*,
.popup-subscription--layout-02 .popup-subscription__image .rimage,
.popup-subscription--layout-03 .popup-subscription__image,
.popup-subscription--layout-03 .popup-subscription__image>*,
.popup-subscription--layout-03 .popup-subscription__image .rimage {
  height: 100%;
}

.popup-subscription--layout-04 {
  width: 600px;
}

.popup-subscription--layout-04 form {
  width: 70%;
}

.popup-subscription--layout-05 {
  width: 100%;
}

.popup-subscription--layout-05 .popup-subscription__image {
  background-size: cover;
  background-position: bottom;
}

.popup-subscription--layout-05 form {
  width: 70%;
}

.popup-subscription-confirmation {
  width: 510px;
  max-width: 100%;
}

.popup-subscription-confirmation__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-subscription-confirmation__content {
  max-width: 390px;
}

.popup-product-contact {
  max-width: 100%;
}

.popup-product-contact__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-contact-confirmation {
  width: 510px;
  max-width: 100%;
}

.popup-contact-confirmation__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-contact-confirmation__content {
  max-width: 420px;
}

.popup-password {
  width: 270px;
  max-width: 100%;
}

.popup-quick-view {
  width: 1200px;
  max-width: 100%;
}

.popup-quick-view__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-delivery-return {
  width: 785px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .popup-delivery-return__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.popup-delivery-return__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-size-guide {
  width: 785px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .popup-size-guide__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.popup-size-guide__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-age-confirmation {
  width: 600px;
}

.popup-age-confirmation__close {
  top: 16px;
  right: 16px;
  z-index: 1;
}

.popup-age-confirmation__image {
  min-width: 100%;
  min-height: 100%;
}

.popup-age-confirmation__btns {
  max-width: 330px;
}

.popup-age-confirmation__content {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.popup-pickup-available {
  width: 320px;
  max-width: 100%;
}

.popup-pickup-available address p {
  margin-bottom: 0;
}

.fixed-sidebar {
  top: 45%;
  margin-top: -80px;
  z-index: 2;
}

.notification {
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.notification.animate {
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.notification.pressed {
  transform: scale(0.95);
  cursor: pointer;
}

.notification__inner {
  opacity: 0;
  transform: translate3d(0, 25%, 0);
}

.notification__inner.animate {
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.notification__inner.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 541px) {
  .notification-products {
    width: 270px;
    max-width: 100%;
  }
}

@media (min-width: 541px) {
  .notification-cookies {
    max-width: 435px;
  }
}

.notification-cookies__bg {
  opacity: 0.9;
}

.notification-cookies__button-info {
  text-decoration: none !important;
}

.footbar-product {
  display: none;
}

.footbar-product>* {
  min-height: 80px;
  opacity: 0;
  will-change: opacity;
  transition-property: opacity;
}

.footbar-product--visible>* {
  opacity: 1;
}

.footbar-product__image {
  width: 60px;
  min-width: 60px;
}

.search-ajax.open .search-ajax__content {
  display: flex !important;
}

.parallax {
  overflow: hidden;
}

.parallax--moved-image [data-parallax-image]:not(.lazyload),
.parallax--moved-image [data-parallax-image].lazyload.loaded {
  height: auto;
  object-fit: initial;
}

.parallax:not(.parallax--init) [data-parallax-image] {
  opacity: 0;
}

.promobox p {
  display: block;
  margin-bottom: 0;
}

.promobox .btn {
  min-width: 162px;
  pointer-events: all;
}

.promobox__btns {
  max-width: 100%;
}

.promobox__plate {
  min-height: 35px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.promobox__plate .promobox__content::before {
  content: '';
  display: block;
  opacity: 0.9;
  transition-property: background;
  will-change: background;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.promobox__content {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.promobox__content .custom-build>div>div {
  pointer-events: all;
}

.promobox__content-scale {
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.css-theme-loaded .promobox:not(.promobox--is-slider) .promobox__content,
.css-theme-loaded .slick-active .promobox__content {
  transform: none;
  opacity: 1;
  transition-property: opacity, transform;
}

.promobox__curtain {
  opacity: 0;
}

.promobox--curtain:hover .promobox__curtain {
  opacity: 1;
}

.promobox--curtain:hover .promobox__content {
  opacity: 0 !important;
}

.promobox--curtain:hover .promobox__content--hover-scale {
  transform: scale(0.3) !important;
}

.promobox__list {
  z-index: 1;
}

.promobox--type-1 .btn {
  min-width: 150px;
}

.promobox--type-1-3 .btn {
  min-width: 160px;
}

.promobox--type-1-2 .btn,
.promobox--type-1-5 .btn,
.promobox--type-1-6 .btn,
.promobox--type-1-9 .btn,
.promobox--type-1-14 .btn {
  width: 100%;
}

.promobox--type-1-6 .promobox__btns {
  width: 220px;
}

.promobox--type-1-2.text-center .promobox__btns,
.promobox--type-1-5.text-center .promobox__btns,
.promobox--type-1-6.text-center .promobox__btns,
.promobox--type-1-14.text-center .promobox__btns {
  margin-left: auto;
  margin-right: auto;
}

.promobox--type-1-background .promobox__text-wrap {
  width: 614px !important;
}

.promobox--type-1-background-2 .promobox__text-wrap {
  width: 490px !important;
}

.promobox--type-1-background-2 .btn {
  width: 142px !important;
}

.promobox--type-1-background-3 .promobox__text-wrap {
  width: 634px !important;
}

.promobox--type-1-background-4 .promobox__text-wrap {
  width: 390px !important;
}

.promobox--text>* {
  max-width: 550px;
}

.promobox__content--animation-scale-in {
  transform: scale(1.7);
}

.promobox__content--animation-scale-out {
  transform: scale(0.7);
}

.promobox__content--animation-translate-top {
  transform: translate3d(0, -100px, 0);
}

.promobox__content--animation-translate-bottom {
  transform: translate3d(0, 100px, 0);
}

.promobox__content--animation-translate-left {
  transform: translate3d(-100px, 0, 0);
}

.promobox__content--animation-translate-right {
  transform: translate3d(100px, 0, 0);
}

[class*='promobox__content--animation'] {
  opacity: 0.001;
  transition-property: transform, opacity;
  will-change: transform, opacity;
}

.brands__alphabet {
  overflow-y: auto;
}

.brands__letter a {
  width: 35px;
  min-width: 35px;
  height: 35px;
}

.brands__letter a.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.template-page .brands {
  min-height: 350px;
}

.search-form__form {
  max-width: 390px;
}

.menu__item>a {
  display: block;
}

.menu--vertical:not(.menu--items-visible) .menu__see-all>span:not(:first-child),
.menu--vertical.menu--items-visible .menu__see-all>span:first-child {
  display: none !important;
}

.header__content--sticky .menu-vertical-btn {
  border-radius: 0;
}

.collections__sidebar {
  z-index: 2;
}

.collection-control__button-sidebar {
  margin-left: -2px;
}

.collection-control__button-sidebar--status span:not(:first-of-type) {
  display: none;
}

.collection-control__button-sidebar--status.open span:first-of-type {
  display: none;
}

.collection-control__button-sidebar--status.open span:not(:first-of-type) {
  display: inline;
}

.collection-control__grid-buttons {
  outline: none;
}

.collection-control__sort-by label {
  white-space: nowrap;
}

.collection-control__sort-by select {
  width: auto;
}

.collection-sidebar-type-1 .collection-sidebar-section--bordered {
  border-bottom: solid 1px transparent;
}

.collection-sidebar-type-1 .collection-sidebar-section__head {
  padding: 17px 0 16px;
}

.collection-sidebar-type-1 .collection-sidebar-section__head.open .collection-sidebar-section__arrow {
  transform: rotate(-180deg);
}

.collections-menu__button.open .collections-menu__arrow {
  transform: rotate(-180deg);
}

.collection-filters.row {
  margin-bottom: -10px;
}

.collection-filters label span {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.collection-filters__checkbox--disabled {
  opacity: 0.5;
}

.collection-sidebar-type-1 .collection-filters__header {
  padding-bottom: 17px;
  margin-top: -4px;
}

.collection-current-filters__item i {
  margin-right: -6px;
}

.collection-current-filters--type-2 .collection-current-filters__item {
  order: 1;
}

.carousel__slider.initialized+.carousel__spacer {
  display: none !important;
}

.carousel__title a {
  transition-property: color;
}

.carousel__prev,
.carousel__next {
  top: 50%;
  margin-top: -12px;
  z-index: 1;
}

.carousel__prev-placeholder,
.carousel__next-placeholder {
  top: 0;
  height: 100%;
  z-index: 1;
}

@media (min-width: 1260px) and (max-width: 1320px) {

  .carousel__prev-placeholder,
  .carousel__prev,
  .carousel__next-placeholder,
  .carousel__next {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1105px) {

  .carousel__prev-placeholder,
  .carousel__prev,
  .carousel__next-placeholder,
  .carousel__next {
    display: none !important;
  }
}

.carousel .slick-slide {
  outline: none;
}

.carousel .slick-dots {
  margin-bottom: -15px;
}

.carousel .slick-dots li {
  width: 6px;
  height: 6px;
  min-width: 6px;
  padding: 0;
  margin-bottom: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.carousel .slick-dots li::before {
  display: none;
}

.carousel .slick-dots li.slick-active {
  width: 10px;
  height: 10px;
}

.carousel .slick-dots button {
  display: none;
}

.carousel__slick:not(.slick-initialized) .carousel__item:not(:first-child),
.carousel__slick:not(.slick-initialized) .carousel__item:first-child img {
  display: none !important;
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="2"] .carousel__slick:not(.slick-initialized) .carousel__item>* {
    max-width: 50% !important;
  }
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="3"] .carousel__slick:not(.slick-initialized) .carousel__item>* {
    max-width: 33.33% !important;
  }
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="4"] .carousel__slick:not(.slick-initialized) .carousel__item>* {
    max-width: 25% !important;
  }
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="5"] .carousel__slick:not(.slick-initialized) .carousel__item>* {
    max-width: 20% !important;
  }
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="6"] .carousel__slick:not(.slick-initialized) .carousel__item>* {
    max-width: 16.67% !important;
  }
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="7"] .carousel__slick:not(.slick-initialized) .carousel__item>* {
    max-width: 14.28% !important;
  }
}

@media (min-width: 1025px) {
  .carousel--preload-spacer .carousel__slider[data-count="8"] .carousel__slick:not(.slick-initialized) .carousel__item {
    max-width: 12.5% !important;
  }
}

.carousel-brands .slick-track {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.carousel-brands .carousel__item {
  min-height: 78px;
}

.carousel-reviews__item_container {
  max-width: 970px;
}

.carousel-reviews__stars {
  margin-left: -3px;
}

.carousel-reviews__stars .icon {
  margin: 0 -2px;
}

.carousel-reviews__item_image {
  width: 58px;
  height: 58px;
}

.pagination {
  margin-left: -7px;
  margin-right: -7px;
}

.compare table {
  overflow-x: auto;
}

.compare__title {
  max-width: 170px;
  min-width: 170px;
}

@media (min-width: 768px) {
  .compare__title:last-child h4 {
    font-size: 0;
  }
}

.compare__item {
  max-width: 200px;
  min-width: 200px;
}

@media (min-width: 541px) {
  .cart__image {
    width: 100%;
    max-width: 150px;
  }
}

.cart klarna-placement>* {
  margin-top: 20px !important;
}

.register .container {
  max-width: 370px;
}

.reset-password .container {
  max-width: 370px;
}

.activate-account .container {
  max-width: 370px;
}

.gallery__content {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
}

.gallery__item-layer {
  opacity: 0;
}

.gallery__item-bg {
  opacity: 0.5;
}

.gallery__fotorama.fotorama--fullscreen::before {
  content: '';
  display: block;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gallery__fotorama .fotorama__html {
  padding-top: 60px;
  padding-bottom: 40px;
}

.gallery__fotorama .fotorama__html img {
  max-height: calc(100% - 90px);
}

.gallery__fotorama .fotorama__nav {
  padding-top: 20px;
  margin-top: -20px;
}

.gallery__fotorama .fotorama__nav__frame.fotorama__active {
  transform: translateY(-20px);
  transition-property: transform;
}

.gallery__fotorama .fotorama__thumb img {
  width: 100% !important;
  height: auto !important;
  top: 0 !important;
  left: 0 !important;
}

.gallery__fotorama .fotorama__thumb-border {
  display: none;
}

.gallery__fotorama .fotorama__arr,
.gallery__fotorama .fotorama__fullscreen-icon {
  display: flex;
  background-image: none !important;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0) !important;
}

.gallery__fotorama .fotorama__arr i,
.gallery__fotorama .fotorama__fullscreen-icon i {
  pointer-events: none;
}

.gallery__fotorama .fotorama__arr:not(.fotorama__arr--disabled) {
  opacity: 1 !important;
}

.gallery__fotorama .fotorama__arr--prev {
  margin-left: 20px;
}

.gallery__fotorama .fotorama__arr--next {
  margin-right: 20px;
}

.gallery__fotorama .fotorama__fullscreen-icon {
  margin-top: 20px;
  margin-right: 20px;
  opacity: 1 !important;
}

.blogs__sidebar {
  z-index: 1;
}

.post--limit-width {
  max-width: 772px;
  margin-left: auto;
  margin-right: auto;
}

.article ul,
.article ol {
  margin-left: 40px;
  margin-bottom: 30px;
}

.article blockquote {
  padding-left: 30px;
  margin-left: 40px;
}

.article-text {
  margin-bottom: 40px;
}

.article-slider img {
  width: auto !important;
}

.article-slider__slick img {
  width: 100%;
  margin: 0 !important;
}

.article-slider .slick-slide {
  outline: none;
}

.article-slider .slick-arrow {
  width: 35px;
  height: 35px;
  top: 50%;
  margin-top: -17px;
  z-index: 1;
}

.article-slider .slick-arrow::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
}

.article-slider .slick-arrow.slick-disabled {
  display: none !important;
}

.article-slider .slick-dots li {
  width: 6px;
  height: 6px;
  min-width: 6px;
  padding: 0;
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.article-slider .slick-dots li::before {
  display: none;
}

.article-slider .slick-dots li.slick-active {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.article-slider .slick-dots button {
  display: none;
}

.instafeed__curtain::before {
  content: '';
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.faqs__cross {
  transition-property: transform;
  will-change: transform;
}

.faqs__head:not(.open) .faqs__cross {
  transform: rotate(45deg);
}

.contact-map::before {
  content: '';
  display: block;
  padding-top: 65%;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.contact-map:hover iframe {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.table-account tr:first-child td {
  border-top: none !important;
}

.table-account td {
  width: 50%;
  padding-left: 0 !important;
  text-transform: uppercase;
}

.table-size-guide tr:first-child th:first-child {
  width: 200px;
}

.lookbook__picker-circle::before,
.lookbook__picker-circle::after {
  display: block;
  content: '';
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.lookbook__picker-circle::before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0.7;
}

.lookbook__picker-circle::after {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
}

#lookbook main {
  z-index: 3;
}

.password-page-content__subscription {
  width: 100%;
  max-width: 500px;
}

@media (min-width: 768px) {
  .shopify-challenge__container {
    margin: 200px auto !important;
  }
}

.g-recaptcha {
  margin: 20px 0 !important;
}

.page-404 .container {
  max-width: 480px;
}

.payments>.row {
  margin-left: -5px;
  margin-right: -5px;
}

.payments .icon {
  width: 62px;
  height: initial;
  min-width: 62px;
  min-height: initial;
}

.payments__image {
  width: 62px;
}

.payments--size-37 .icon {
  width: 37px;
  min-width: 37px;
}

.payments--size-37 .payments__image {
  width: 37px;
}

.footer__custom-html [class*='icon-'] {
  margin-top: -2px;
}

.footer__social-media-mobile {
  margin-bottom: -10px;
}

.footer__payments {
  margin-top: -5px;
  margin-bottom: -5px;
}

.giftcard__image {
  max-width: 370px;
}

.giftcard__code {
  min-height: 45px;
}

[dir='rtl'],
[dir='rtl'] body {
  text-align: right;
}

[dir='rtl'] blockquote {
  padding-left: 0;
  padding-right: 20px;
  margin-left: 0;
  margin-right: 20px;
  border-left: none;
  border-right: solid 2px transparent;
}

[dir='rtl'] ul,
[dir='rtl'] ol {
  padding-right: 0;
}

[dir='rtl'] ul:not(.list-unstyled) li {
  padding-left: 0;
  padding-right: 16px;
}

[dir='rtl'] ul:not(.list-unstyled) li::before {
  margin-right: -16px;
  margin-left: 10px;
}

[dir='rtl'] ul:not(.list-unstyled) ul {
  padding-left: 0;
  padding-right: 18px;
}

[dir='rtl'] ol:not(.list-unstyled) li {
  padding-left: 0;
  padding-right: 16px;
}

[dir='rtl'] ol:not(.list-unstyled) li::before {
  margin-left: 0;
  margin-right: -16px;
}

[dir='rtl'] ol:not(.list-unstyled) ol {
  padding-left: 0;
  padding-right: 36px;
}

[dir='rtl'] .col.pr-0 {
  padding-left: 0;
  padding-right: 15px !important;
}

[dir='rtl'] .col.pl-0 {
  padding-left: 15px !important;
  padding-right: 0;
}

[dir='rtl'] .list-unstyled {
  padding-right: 0;
}

[dir='rtl'] .list-unstyled>ul,
[dir='rtl'] .list-unstyled>ol {
  padding-right: 0;
}

[dir='rtl'] input[type="checkbox"],
[dir='rtl'] input[type="radio"] {
  margin-left: 7px;
  margin-right: 0;
}

[dir='rtl'] label {
  margin-left: 15px;
  margin-right: 0;
}

[dir='rtl'] .note::before {
  left: auto;
  right: 15px;
}

[dir='rtl'] .input-boolean input+span span {
  left: auto;
  right: -1px;
  transition-property: right;
}

[dir='rtl'] .input-boolean input:checked+span span {
  left: auto;
  right: calc(100% - 15px);
}

[dir='rtl'] .border-left {
  border-left-style: none !important;
  border-right-style: dashed !important;
}

[dir='rtl'] .border-right {
  border-right-style: none !important;
  border-left-style: dashed !important;
}

[dir='rtl'] .border--dashed.border-left {
  border-left-style: none !important;
  border-right-style: dashed !important;
}

[dir='rtl'] .border--dashed.border-right {
  border-right-style: none !important;
  border-left-style: dashed !important;
}

[dir='rtl'] .table,
[dir='rtl'] .responsive-table {
  text-align: right;
}

[dir='rtl'] .dropdown {
  text-align: right;
}

[dir='rtl'] .price--sale>span:not(:first-child) {
  float: left;
}

[dir='rtl'] .header__content--sticky {
  left: auto !important;
  right: 0 !important;
}

[dir='rtl'] .header__counter {
  left: 0;
  right: 22px;
}

[dir='rtl'] .menu__megamenu .menu__item--has-children>a>i,
[dir='rtl'] .menu__dropdown .menu__item--has-children>a>i {
  transform: rotate(180deg);
}

[dir='rtl'] .popup [data-popup-left] [data-popup-content] {
  transform: translateX(100%);
}

[dir='rtl'] .popup [data-popup-right] [data-popup-content] {
  transform: translateX(-100%);
}

[dir='rtl'] .popup-navigation__back[data-button-content='back'] {
  transform: rotate(180deg);
}

[dir='rtl'] .product-options__value--text.disabled::before,
[dir='rtl'] .product-options__value--text.disabled::after {
  margin-left: 0;
  margin-right: 1px;
}

[dir='rtl'] .product-options--type-footbar [data-section-container]:not(:last-child) .product-options__section {
  margin-left: 20px;
  margin-right: 0;
}

[dir='rtl'] .product-images-navigation span i {
  transform: rotate(180deg);
}

[dir='rtl'] .article ul,
[dir='rtl'] .article ol {
  margin-left: auto;
  margin-right: 40px;
}

[dir='rtl'] .article blockquote {
  padding-left: 0;
  padding-right: 30px;
  margin-left: 0;
  margin-right: 40px;
}

[dir='rtl'] .carousel__prev i,
[dir='rtl'] .carousel__next i {
  display: inline-block;
  transform: rotate(180deg);
}

[dir='rtl'] .collection-current-filters__item i {
  margin-left: -6px;
  margin-right: 0;
}

[dir='rtl'] .countdown__time {
  direction: ltr;
}

[dir='rtl'] .countdown--type-01 .countdown__section {
  margin-left: 1px;
  margin-right: 0;
}

[dir='rtl'] .countdown--type-02 .countdown__section:not(:first-child) {
  margin-left: 0;
  margin-right: 18px;
}

[dir='rtl'] .countdown--type-02 .countdown__section:not(:last-child) .countdown__time::after {
  left: -18px;
  right: auto;
}

[dir='rtl'] .gallery__fotorama .fotorama__thumb img {
  left: auto !important;
  right: 0 !important;
}

[dir='rtl'] .material-info__list i {
  margin-left: 10px;
  margin-right: 0;
}

[dir='rtl'] .popup-subscription__close,
[dir='rtl'] .popup-size-guide__close,
[dir='rtl'] .popup-quick-view__close,
[dir='rtl'] .popup-delivery-return__close {
  left: 16px;
  right: auto;
}

[dir='rtl'] .product-options--centered label {
  margin-left: 0;
}

[dir='rtl'] .product-fixed-sizebar__btn::after {
  left: -10px;
  right: initial;
}

[dir='rtl'] .product-fixed-sizebar__line i {
  margin-left: 10px;
  margin-right: 0;
}

[dir='rtl'] .spr-summary-actions {
  margin-left: 0;
  margin-right: auto;
}

[dir='rtl'] .spr-summary .spr-starrating {
  margin-right: 0;
  margin-left: 10px;
}

[dir='rtl'] .shopify-review-starrating .shopify-review-icon:not(:last-child) {
  margin-left: 4px;
  margin-right: 0;
}

[dir='rtl'] .spr-review-reportreview {
  left: 0;
  right: auto;
}

[dir='rtl'] .spr-button {
  float: right !important;
}

[dir='rtl'] .rev_slider_wrapper,
[dir='rtl'] .rev_slider_wrapper * {
  direction: ltr;
}

@media (min-width: 1260px) {
  [dir='rtl'] .collection-grid-list-xl .product-collection__content {
    text-align: right;
  }
}

[dir='rtl'] .product-gallery__main_arrow--type-prev i {
  margin-left: 0 !important;
}

[dir='rtl'] .product-gallery__main_arrow--type-next i {
  margin-right: 0 !important;
}

.tp-caption {
  font-size: var(--body-size) !important;
  line-height: var(--body-line-height) !important;
}

.spr-icon,
.shopify-review-icon {
  font-size: 13px !important;
}

.spr-header-title,
.spr-form-title {
  font-size: var(--h4-size) !important;
  line-height: var(--h4-line-height);
}

.spr-review-header-title {
  font-size: 15px;
  line-height: 21px;
}

.spr-review-reportreview {
  font-size: 13px !important;
  line-height: 18px !important;
}

.spr-review-header-byline strong {
  font-weight: normal;
}

.tippy-tooltip.shella-theme {
  font-size: 11px;
  line-height: 14px;
}

.select__dropdown span {
  line-height: 23px;
}

.label {
  font-size: var(--body-size);
  line-height: 21px;
}

.irs .irs-from,
.irs .irs-to,
.irs .irs-single,
.irs .irs-min,
.irs .irs-max {
  font-size: var(--body-size);
  line-height: 18px;
}

.breadcrumbs ul {
  font-size: 10px;
  line-height: 16px;
}

.menu-vertical-btn .icon {
  width: var(--vertical-menu-button-icons-size);
  min-width: var(--vertical-menu-button-icons-size);
  min-height: var(--vertical-menu-button-icons-size);
}

.menu__item_icon {
  width: var(--vertical-menu-icons-size);
  min-width: var(--vertical-menu-icons-size);
  min-height: var(--vertical-menu-icons-size);
}

.menu__label {
  font-size: 9px;
  line-height: 12px;
}

.slider__prev .icon,
.slider__next .icon {
  width: 40px;
  min-width: 40px;
}

.countdown--type-01 .countdown__time,
.countdown--type-01 .countdown__postfix {
  font-size: 10px;
  line-height: 13px;
}

.countdown--type-02 .countdown__time {
  font-size: 20px;
  line-height: 26px;
}

.countdown--type-02 .countdown__postfix {
  font-size: 10px;
  line-height: 13px;
}

.visitors {
  font-size: 15px;
  line-height: 26px;
}

.material-info .icon {
  width: 23px;
  min-width: 23px;
  min-height: 23px;
}

.material-info__head-icons .icon {
  width: 50px;
  min-width: 50px;
  min-height: 52px;
}

.tabs__btn {
  font-size: var(--body-size);
  line-height: 21px;
  letter-spacing: var(--h5-letter-spacing);
}

.collection-grid-buttons .icon {
  width: 21px;
  min-width: 21px;
}

.collection-grid-buttons .icon-theme-304,
.collection-grid-buttons .icon-theme-305 {
  width: 20.5px;
  min-width: 20.5px;
}

.collection-grid-buttons .icon-theme-323 {
  width: 8px;
  min-width: 8px;
}

.collection-grid-buttons .icon-theme-324 {
  width: 13px;
  min-width: 13px;
}

.collection-grid-buttons .icon-theme-325 {
  width: 18px;
  min-width: 18px;
}

.collection-grid-buttons .icon-theme-326 {
  width: 28px;
  min-width: 28px;
}

.collection-grid-buttons .icon-theme-327 {
  width: 16px;
  min-width: 16px;
}

.product-collection__more-info {
  text-transform: var(--product-collection-more-info-transform);
  font-size: var(--product-collection-more-info-size);
  line-height: var(--product-collection-more-info-line-height);
  letter-spacing: var(--product-collection-more-info-letter-spacing);
  font-weight: var(--product-collection-more-info-weight);
}

.product-collection__title>*,
.product-compare__title>*,
.product-wishlist__title>* {
  font-family: var(--product-collection-title-family);
  font-size: var(--product-collection-title-size);
  line-height: var(--product-collection-title-line-height);
  letter-spacing: var(--product-collection-title-letter-spacing);
  font-weight: var(--product-collection-title-weight);
}

.product-collection__button-add-to-cart .btn,
.product-compare__button-add-to-cart .btn,
.product-wishlist__button-add-to-cart .btn,
.carousel-articles__item .btn {
  font-size: var(--product-collection-button-size);
  line-height: var(--product-collection-button-line-height);
  letter-spacing: var(--product-collection-button-letter-spacing);
  font-weight: var(--product-collection-button-weight);
  text-transform: var(--product-collection-button-transform);
}

.carousel-reviews__stars .icon {
  width: 24px;
  min-width: 24px;
  min-height: 24px;
}

.product-notification__content span {
  font-size: 10px;
}

.product-notification__content h3 {
  letter-spacing: var(--body-letter-spacing);
}

.product-notification__content h3,
.product-notification__content span {
  line-height: 18px;
}

.product-notification__button-close .icon {
  width: 14px;
  min-width: 14px;
  min-height: 14px;
}

.product-list__collections {
  line-height: 18px;
}

.product-gallery__thumbnail_item--type-video .product-gallery__thumbnail_item_bg .icon {
  width: 25px;
  min-width: 25px;
  min-height: 25px;
}

.product-page-info__title>*,
.one-product-info__title>* {
  letter-spacing: var(--product-page-title-letter-spacing);
  font-weight: var(--product-page-title-weight);
  text-transform: var(--product-page-title-transform);
}

.product-page-info__details-buttons {
  font-size: var(--body-size);
  line-height: 14px;
  letter-spacing: var(--h5-letter-spacing);
}

.product-page-info__price .price,
.one-product-info__price .price {
  font-size: 24px;
}

.product-page-info__price .price--sale>span:first-child,
.one-product-info__price .price--sale>span:first-child {
  font-size: 17px;
}

.one-product-info__details-buttons {
  font-size: var(--body-size);
  line-height: 14px;
  letter-spacing: var(--h5-letter-spacing);
}

.product-page-info__title>*,
.one-product-info__title>* {
  font-family: var(--product-page-title-family);
}

.product-page-info__reviews .shopify-review-badge-caption,
.one-product-info__reviews .shopify-review--badge-caption {
  text-transform: var(--product-page-reviews-caption-transform);
}

.product-options--type-page label {
  text-transform: var(--product-page-options-label-transform);
}

.product-page-info__field label,
.one-product-info__field label {
  text-transform: var(--product-page-fields-label-transform);
}

.one-product__bg .one-product-info__title>* {
  font-size: var(--h1-size);
  line-height: var(--h1-line-height);
  letter-spacing: var(--h1-letter-spacing);
}

.one-product__bg .one-product-info__price .price {
  font-size: 34px !important;
}

.one-product__bg .one-product-info__price .price--sale>span:first-child {
  font-size: 17px !important;
}

.product-collection__list-v2-content .price {
  font-size: 24px;
}

.product-collection__list-v2-content .price--sale>span:first-child {
  font-size: 18px;
}

@media (max-width: 345px) {
  .col-6>.product-collection .product-collection__button-add-to-cart .btn {
    font-size: 10px;
  }
}

.price {
  font-family: var(--product-price-family);
  font-size: 17px;
  font-weight: var(--product-price-weight);
}

.price--sale>span:first-child {
  font-size: var(--body-size);
}

.product-options__value--text,
.product-options__value--large-text {
  font-size: var(--body-size);
  line-height: 18px;
}

.collection-page-heading h1 {
  font-family: var(--collection-page-heading-title-family);
  font-size: var(--collection-page-heading-title-size);
  line-height: var(--collection-page-heading-title-line-height);
  letter-spacing: var(--collection-page-heading-title-letter-spacing);
  font-weight: var(--collection-page-heading-title-weight);
}

.collection-current-filters__item a {
  line-height: 20px;
}

.home-section-title,
.home-section-title>* {
  font-size: var(--home-sections-title-size);
  line-height: var(--home-sections-title-line-height);
  letter-spacing: var(--home-sections-title-letter-spacing);
  font-weight: var(--home-sections-title-weight);
}

.carousel-articles__title {
  font-size: var(--carousel-article-title-size);
  line-height: var(--carousel-article-title-line-height);
  letter-spacing: var(--carousel-article-title-letter-spacing);
  font-weight: var(--carousel-article-title-weight);
}

.brands__section-letter a {
  line-height: var(--h5-line-height);
}

.popup-account__login ul {
  font-size: var(--body-size);
  line-height: var(--body-line-height);
}

.notification-cookies p {
  font-size: 14px;
  line-height: 18px;
}

.notification-cookies__button-close .icon {
  width: 27px;
  min-width: 27px;
  min-height: 18px;
}

.social-share .icon-social-facebook {
  width: 11px;
  min-width: 11px;
}

.social-share .icon-social-pinterest-2 {
  width: 16px;
  min-width: 16px;
}

.social-share .icon-social-buffer {
  width: 18px;
  min-width: 18px;
}

.footbar-product__title>* {
  font-size: 15px;
  line-height: 22px;
}

.footbar-product__price {
  line-height: 22px;
}

.product-fixed-sizebar__line .icon {
  min-height: 16px;
}

.collection-sidebar-section__head>*,
.collection-sidebar-section__arrow {
  line-height: 30px;
}

.information-line .icon {
  width: 30px;
  min-width: 30px;
}

.blog-sidebar__recents h5 {
  letter-spacing: var(--body-letter-spacing);
}

.article .rte ul,
.article .rte ol {
  font-size: 20px !important;
  line-height: 30px !important;
}

.article blockquote,
.article blockquote p {
  font-size: var(--h3-size);
  line-height: var(--h3-line-height);
}

.article blockquote site,
.article blockquote p site {
  font-size: 15px;
  line-height: 20px;
}

.article__nav h4 {
  letter-spacing: var(--body-letter-spacing);
}

.article-body h1 {
  letter-spacing: var(--body-letter-spacing);
}

.article-text {
  font-size: 20px;
  line-height: 30px;
}

.lookbook__price .price {
  font-size: var(--body-size);
}

.lookbook__price .price--sale>span:first-child {
  font-size: var(--body-size);
}

.footer__copyright p {
  font-size: var(--footer-copyright-size);
  line-height: var(--footer-copyright-line-height);
}

.footer__menus {
  text-transform: var(--footer-menu-transform);
}

.footer__menus a {
  font-size: var(--footer-menu-size);
  line-height: var(--footer-menu-line-height);
}

.page__title {
  font-size: var(--page-title-size);
  line-height: var(--page-title-line-height);
  letter-spacing: var(--page-title-letter-spacing);
}

.giftcard__value {
  font-size: 20px;
  letter-spacing: 0;
}

.giftcard__code {
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.05em;
}

.giftcard__btn-print {
  letter-spacing: 0.05em;
}

.rte {
  font-size: var(--body-lg-size);
  line-height: var(--body-lg-line-height);
}

.rte ul,
.rte ol {
  font-size: var(--list-lg-size) !important;
  line-height: var(--list-lg-line-height) !important;
}

.popup-subscription-confirmation__title-icon .icon {
  width: 30px;
  min-width: 30px;
}

.popup-subscription__title-icon .icon {
  width: 30px;
  min-width: 30px;
}

@media (min-width: 541px) {
  .popup-age-confirmation__text-line-01 {
    font-size: 36px;
    line-height: 42px;
  }
}

.popup-age-confirmation__checkbox-text a {
  text-decoration: underline;
}

.popup-pickup-available__line i {
  vertical-align: bottom;
}

.font-alternative-text [class*="promobox__text-"] {
  font-family: var(--alternative-family);
}

.font-alternative-text-1 .promobox__text-1 {
  font-family: var(--alternative-family);
}

.font-alternative-text-2 .promobox__text-2 {
  font-family: var(--alternative-family);
}

.font-alternative-text-3 .promobox__text-3 {
  font-family: var(--alternative-family);
}

.font-alternative-btn .btn {
  font-family: var(--alternative-family);
}

.font-400-text [class*="promobox__text-"] {
  font-weight: 400;
}

.font-400-text-1 .promobox__text-1 {
  font-weight: 400;
}

.font-400-text-2 .promobox__text-2 {
  font-weight: 400;
}

.font-400-text-3 .promobox__text-3 {
  font-weight: 400;
}

.font-400-btn .btn {
  font-weight: 400;
}

.font-700-text [class*="promobox__text-"] {
  font-weight: 700;
}

.font-700-text-1 .promobox__text-1 {
  font-weight: 700;
}

.font-700-text-2 .promobox__text-2 {
  font-weight: 700;
}

.font-700-text-3 .promobox__text-3 {
  font-weight: 700;
}

.font-700-btn .btn {
  font-weight: 700;
}

.font-italic-text [class*="promobox__text-"] {
  font-style: italic;
}

.font-italic-text-1 .promobox__text-1 {
  font-style: italic;
}

.font-italic-text-2 .promobox__text-2 {
  font-style: italic;
}

.font-italic-text-3 .promobox__text-3 {
  font-style: italic;
}

.font-italic-btn .btn {
  font-style: italic;
}

body {
  background-color: var(--theme-body);
  color: var(--theme-c);
}

#MainContent {
  background-color: var(--theme-body);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--theme-head-c);
}

a {
  color: var(--theme-link-c);
}

a:visited,
a:active,
a:link {
  color: var(--theme-link-c);
}

a.link-unstyled:visited,
a.link-unstyled:active,
a.link-unstyled:link {
  color: initial;
}

p,
.paragraph {
  color: var(--theme-paragraph-c);
}

i {
  color: var(--icons-c);
}

.icon {
  fill: var(--icons-c);
}

blockquote {
  border-color: var(--theme);
  color: var(--theme);
}

blockquote p {
  color: var(--theme);
}

hr:not(.hr--clear) {
  border-top-color: var(--theme5);
}

.link-revert,
.link-revert:visited,
.link-revert:active,
.link-revert:link {
  color: var(--theme-c);
}

.border {
  border-color: var(--theme5) !important;
}

.border-top {
  border-top-color: var(--theme5) !important;
}

.border-bottom {
  border-bottom-color: var(--theme5) !important;
}

.border-left {
  border-left-color: var(--theme5) !important;
}

.border-right {
  border-right-color: var(--theme5) !important;
}

ul:not(.list-unstyled) li::before {
  background-color: var(--theme);
}

ol:not(.list-unstyled) li::before {
  color: var(--theme);
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea,
select {
  background-color: var(--input-bg);
  border: var(--input-bd);
  color: var(--input-c);
}

input[type="text"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: var(--input-c);
}

input[type="text"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  color: var(--input-c);
}

input[type="text"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: var(--input-c);
}

input[type="text"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  color: var(--input-c);
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
  background-color: var(--input-f-bg);
  border: var(--input-f-bd);
  color: var(--input-f-c);
}

input[type="text"]:focus::-webkit-input-placeholder,
input[type="number"]:focus::-webkit-input-placeholder,
input[type="email"]:focus::-webkit-input-placeholder,
input[type="password"]:focus::-webkit-input-placeholder,
input[type="search"]:focus::-webkit-input-placeholder,
input[type="tel"]:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder,
select:focus::-webkit-input-placeholder {
  color: var(--input-f-c);
}

input[type="text"]:focus::-moz-placeholder,
input[type="number"]:focus::-moz-placeholder,
input[type="email"]:focus::-moz-placeholder,
input[type="password"]:focus::-moz-placeholder,
input[type="search"]:focus::-moz-placeholder,
input[type="tel"]:focus::-moz-placeholder,
textarea:focus::-moz-placeholder,
select:focus::-moz-placeholder {
  color: var(--input-f-c);
}

input[type="text"]:focus:-ms-input-placeholder,
input[type="number"]:focus:-ms-input-placeholder,
input[type="email"]:focus:-ms-input-placeholder,
input[type="password"]:focus:-ms-input-placeholder,
input[type="search"]:focus:-ms-input-placeholder,
input[type="tel"]:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder,
select:focus:-ms-input-placeholder {
  color: var(--input-f-c);
}

input[type="text"]:focus:-moz-placeholder,
input[type="number"]:focus:-moz-placeholder,
input[type="email"]:focus:-moz-placeholder,
input[type="password"]:focus:-moz-placeholder,
input[type="search"]:focus:-moz-placeholder,
input[type="tel"]:focus:-moz-placeholder,
textarea:focus:-moz-placeholder,
select:focus:-moz-placeholder {
  color: var(--input-f-c);
}

.btn,
.button,
.spr-summary-actions-newreview,
.btn:not([class*="btn--"]) {
  background-color: var(--button-bg);
  border-color: var(--button-bd);
}

.btn,
.btn:visited,
.btn:active,
.btn:link,
.button,
.button:visited,
.button:active,
.button:link,
.spr-summary-actions-newreview,
.spr-summary-actions-newreview:visited,
.spr-summary-actions-newreview:active,
.spr-summary-actions-newreview:link,
.btn:not([class*="btn--"]),
.btn:not([class*="btn--"]):visited,
.btn:not([class*="btn--"]):active,
.btn:not([class*="btn--"]):link {
  color: var(--button-c);
}

.btn:focus,
.button:focus,
.spr-summary-actions-newreview:focus,
.btn:not([class*="btn--"]):focus {
  color: var(--button-c);
}

.btn span,
.button span,
.spr-summary-actions-newreview span,
.btn:not([class*="btn--"]) span {
  color: var(--button-c);
}

.btn .icon,
.button .icon,
.spr-summary-actions-newreview .icon,
.btn:not([class*="btn--"]) .icon {
  fill: var(--button-c);
}

@media (min-width: 1025px) {

  .btn:hover,
  .button:hover,
  .spr-summary-actions-newreview:hover,
  .btn:not([class*="btn--"]):hover {
    background-color: var(--button-h-bg);
    border-color: var(--button-h-bd);
    color: var(--button-h-c);
  }
}

@media (min-width: 1025px) {

  .btn:hover span,
  .button:hover span,
  .spr-summary-actions-newreview:hover span,
  .btn:not([class*="btn--"]):hover span {
    color: var(--button-h-c);
  }
}

@media (min-width: 1025px) {

  .btn:hover .icon,
  .button:hover .icon,
  .spr-summary-actions-newreview:hover .icon,
  .btn:not([class*="btn--"]):hover .icon {
    fill: var(--button-h-c);
  }
}

.btn.active,
.button.active,
.spr-summary-actions-newreview.active,
.btn:not([class*="btn--"]).active {
  background-color: var(--button-act-bg);
  border-color: var(--button-act-bd);
}

.btn.active,
.btn.active:visited,
.btn.active:active,
.btn.active:link,
.button.active,
.button.active:visited,
.button.active:active,
.button.active:link,
.spr-summary-actions-newreview.active,
.spr-summary-actions-newreview.active:visited,
.spr-summary-actions-newreview.active:active,
.spr-summary-actions-newreview.active:link,
.btn:not([class*="btn--"]).active,
.btn:not([class*="btn--"]).active:visited,
.btn:not([class*="btn--"]).active:active,
.btn:not([class*="btn--"]).active:link {
  color: var(--button-act-c);
}

.btn.active:focus,
.button.active:focus,
.spr-summary-actions-newreview.active:focus,
.btn:not([class*="btn--"]).active:focus {
  color: var(--button-act-c);
}

.btn.active span,
.button.active span,
.spr-summary-actions-newreview.active span,
.btn:not([class*="btn--"]).active span {
  color: var(--button-act-c);
}

.btn.active .icon,
.button.active .icon,
.spr-summary-actions-newreview.active .icon,
.btn:not([class*="btn--"]).active .icon {
  fill: var(--button-act-c);
}

.btn.btn--invert {
  background-color: var(--button2-bg);
  border-color: var(--button2-bd);
}

.btn.btn--invert,
.btn.btn--invert:visited,
.btn.btn--invert:active,
.btn.btn--invert:link {
  color: var(--button2-c);
}

.btn.btn--invert:focus {
  color: var(--button2-c);
}

.btn.btn--invert span {
  color: var(--button2-c);
}

.btn.btn--invert .icon {
  fill: var(--button2-c);
}

@media (min-width: 1025px) {
  .btn.btn--invert:hover {
    background-color: var(--button2-h-bg);
    border-color: var(--button2-h-bd);
    color: var(--button2-h-c);
  }
}

@media (min-width: 1025px) {
  .btn.btn--invert:hover span {
    color: var(--button2-h-c);
  }
}

@media (min-width: 1025px) {
  .btn.btn--invert:hover .icon {
    fill: var(--button2-h-c);
  }
}

.btn.btn--invert.active {
  background-color: var(--button2-act-bg);
  border-color: var(--button2-act-bd);
}

.btn.btn--invert.active,
.btn.btn--invert.active:visited,
.btn.btn--invert.active:active,
.btn.btn--invert.active:link {
  color: var(--button2-act-c);
}

.btn.btn--invert.active:focus {
  color: var(--button2-act-c);
}

.btn.btn--invert.active span {
  color: var(--button2-act-c);
}

.btn.btn--invert.active .icon {
  fill: var(--button2-act-c);
}

.btn.btn--secondary {
  background-color: var(--button3-bg);
  border-color: var(--button3-bd);
}

.btn.btn--secondary,
.btn.btn--secondary:visited,
.btn.btn--secondary:active,
.btn.btn--secondary:link {
  color: var(--button3-c);
}

.btn.btn--secondary:focus {
  color: var(--button3-c);
}

.btn.btn--secondary span {
  color: var(--button3-c);
}

.btn.btn--secondary .icon {
  fill: var(--button3-c);
}

@media (min-width: 1025px) {
  .btn.btn--secondary:hover {
    background-color: var(--button3-h-bg);
    border-color: var(--button3-h-bd);
    color: var(--button3-h-c);
  }
}

@media (min-width: 1025px) {
  .btn.btn--secondary:hover span {
    color: var(--button3-h-c);
  }
}

@media (min-width: 1025px) {
  .btn.btn--secondary:hover .icon {
    fill: var(--button3-h-c);
  }
}

.btn.btn--secondary.active {
  background-color: var(--button3-act-bg);
  border-color: var(--button3-act-bd);
}

.btn.btn--secondary.active,
.btn.btn--secondary.active:visited,
.btn.btn--secondary.active:active,
.btn.btn--secondary.active:link {
  color: var(--button3-act-c);
}

.btn.btn--secondary.active:focus {
  color: var(--button3-act-c);
}

.btn.btn--secondary.active span {
  color: var(--button3-act-c);
}

.btn.btn--secondary.active .icon {
  fill: var(--button3-act-c);
}

.btn.btn--clean {
  background-color: var(--button5-bg);
  border-color: var(--button5-bd);
}

.btn.btn--clean,
.btn.btn--clean:visited,
.btn.btn--clean:active,
.btn.btn--clean:link {
  color: var(--button5-c);
}

.btn.btn--clean:focus {
  color: var(--button5-c);
}

.btn.btn--clean span {
  color: var(--button5-c);
}

.btn.btn--clean .icon {
  fill: var(--button5-c);
}

@media (min-width: 1025px) {
  .btn.btn--clean:hover {
    background-color: var(--button5-h-bg);
    border-color: var(--button5-h-bd);
    color: var(--button5-h-c);
  }
}

@media (min-width: 1025px) {
  .btn.btn--clean:hover span {
    color: var(--button5-h-c);
  }
}

@media (min-width: 1025px) {
  .btn.btn--clean:hover .icon {
    fill: var(--button5-h-c);
  }
}

.btn.btn--clean.active {
  background-color: var(--button5-act-bg);
  border-color: var(--button5-act-bd);
}

.btn.btn--clean.active,
.btn.btn--clean.active:visited,
.btn.btn--clean.active:active,
.btn.btn--clean.active:link {
  color: var(--button5-act-c);
}

.btn.btn--clean.active:focus {
  color: var(--button5-act-c);
}

.btn.btn--clean.active span {
  color: var(--button5-act-c);
}

.btn.btn--clean.active .icon {
  fill: var(--button5-act-c);
}

.dynamic-checkout__button--styled .shopify-payment-button__button {
  background-color: var(--button4-bg) !important;
  border-color: var(--button4-bd) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button,
.dynamic-checkout__button--styled .shopify-payment-button__button:visited,
.dynamic-checkout__button--styled .shopify-payment-button__button:active,
.dynamic-checkout__button--styled .shopify-payment-button__button:link {
  color: var(--button4-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button:focus {
  color: var(--button4-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button span {
  color: var(--button4-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button .icon {
  fill: var(--button4-c) !important;
}

@media (min-width: 1025px) {
  .dynamic-checkout__button--styled .shopify-payment-button__button:hover {
    background-color: var(--button4-h-bg) !important;
    border-color: var(--button4-h-bd) !important;
    color: var(--button4-h-c) !important;
  }
}

@media (min-width: 1025px) {
  .dynamic-checkout__button--styled .shopify-payment-button__button:hover span {
    color: var(--button4-h-c) !important;
  }
}

@media (min-width: 1025px) {
  .dynamic-checkout__button--styled .shopify-payment-button__button:hover .icon {
    fill: var(--button4-h-c) !important;
  }
}

.dynamic-checkout__button--styled .shopify-payment-button__button.active {
  background-color: var(--button4-act-bg) !important;
  border-color: var(--button4-act-bd) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button.active,
.dynamic-checkout__button--styled .shopify-payment-button__button.active:visited,
.dynamic-checkout__button--styled .shopify-payment-button__button.active:active,
.dynamic-checkout__button--styled .shopify-payment-button__button.active:link {
  color: var(--button4-act-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button.active:focus {
  color: var(--button4-act-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button.active span {
  color: var(--button4-act-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button.active .icon {
  fill: var(--button4-act-c) !important;
}

.dynamic-checkout__button--styled .shopify-payment-button__button .shopify-cleanslate div[role="button"],
.dynamic-checkout__button--styled .shopify-payment-button__button .shopify-cleanslate div[role="button"]:hover,
.dynamic-checkout__button--styled .shopify-payment-button__button .shopify-cleanslate div[role="button"]:focus {
  background-color: transparent !important;
}

.btn.btn--transparent:not(:hover) {
  background-color: transparent;
}

.btn.btn--text {
  background-color: transparent;
  border-color: transparent;
}

.btn.btn--text,
.btn.btn--text:visited,
.btn.btn--text:active,
.btn.btn--text:link {
  color: var(--button-text-c);
}

.btn.btn--text span {
  color: var(--button-text-c);
}

.btn.btn--text .icon {
  fill: var(--button-text-c);
}

.btn.btn--text:hover,
.btn.btn--text:hover span {
  color: var(--button-text-c);
}

.header__select-currency select,
.header__ly-languages-switcher select {
  background-color: transparent !important;
}

.table th,
.responsive-table th {
  color: var(--theme3);
}

.table td,
.responsive-table td {
  color: var(--theme);
}

.table th,
.table td,
.table thead th,
.table tbody+tbody,
.responsive-table th,
.responsive-table td,
.responsive-table thead th,
.responsive-table tbody+tbody {
  border-color: var(--theme5);
}

input.success {
  border-color: var(--theme-success);
}

input.error {
  border-color: var(--theme-error);
}

label {
  color: var(--theme-head-c);
}

label.success {
  color: var(--theme-success);
}

label.error {
  color: var(--theme-error);
}

.note--success,
.note--success *,
.note--error,
.note--error * {
  color: var(--theme2);
}

.note--success ul li::before,
.note--error ul li::before {
  background-color: var(--theme2);
}

.note--success ol li::before,
.note--error ol li::before {
  color: var(--theme2);
}

.note:not([class*='note--']) {
  background-color: var(--theme4);
}

.note:not([class*='note--'])::before {
  border-bottom-color: var(--theme4);
}

.note--success {
  background-color: var(--theme-success);
}

.note--success::before {
  border-bottom-color: var(--theme-success);
}

.note--error {
  background-color: var(--theme-error);
}

.note--error::before {
  border-bottom-color: var(--theme-error);
}

.input-checkbox {
  color: var(--theme3);
}

.input-checkbox input:checked~span {
  color: var(--theme);
}

.input-checkbox:not(.input-checkbox--unbordered) input:checked+span {
  border-color: var(--theme) !important;
}

.input-boolean {
  color: var(--theme3);
}

.input-boolean input+span {
  background-color: var(--theme4);
}

.input-boolean input+span span {
  background-color: var(--theme2);
  -webkit-box-shadow: -1px 1px 0px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 1px 0px 0px rgba(0, 0, 0, 0.05);
  box-shadow: -1px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.input-boolean input:checked+span {
  background-color: var(--theme-primary);
}

.input-boolean input:checked~span {
  color: var(--theme);
}

.input-quantity--type-01 [data-control],
.input-quantity--type-05 [data-control] {
  background-color: var(--theme2);
}

.input-icon-button .icon {
  fill: var(--theme6);
}

.input-icon-button input:checked+.icon {
  fill: var(--theme);
}

.btn-link,
.btn-link:visited,
.btn-link:active,
.btn-link:link {
  color: var(--theme-c);
}

h1.btn-link,
h1.btn-link:visited,
h1.btn-link:active,
h1.btn-link:link,
.h1.btn-link,
.h1.btn-link:visited,
.h1.btn-link:active,
.h1.btn-link:link,
h2.btn-link,
h2.btn-link:visited,
h2.btn-link:active,
h2.btn-link:link,
.h2.btn-link,
.h2.btn-link:visited,
.h2.btn-link:active,
.h2.btn-link:link,
h3.btn-link,
h3.btn-link:visited,
h3.btn-link:active,
h3.btn-link:link,
.h3.btn-link,
.h3.btn-link:visited,
.h3.btn-link:active,
.h3.btn-link:link,
h4.btn-link,
h4.btn-link:visited,
h4.btn-link:active,
h4.btn-link:link,
.h4.btn-link,
.h4.btn-link:visited,
.h4.btn-link:active,
.h4.btn-link:link,
h5.btn-link,
h5.btn-link:visited,
h5.btn-link:active,
h5.btn-link:link,
.h5.btn-link,
.h5.btn-link:visited,
.h5.btn-link:active,
.h5.btn-link:link,
h6.btn-link,
h6.btn-link:visited,
h6.btn-link:active,
h6.btn-link:link,
.h6.btn-link,
.h6.btn-link:visited,
.h6.btn-link:active,
.h6.btn-link:link {
  color: var(--theme-head-c);
}

.label {
  color: var(--label-c);
}

.label--sale {
  background-color: var(--label-sale-bg);
}

.label--new {
  background-color: var(--label-new-bg);
}

.label--hot {
  background-color: var(--label-hot-bg);
}

.label--out-stock {
  background-color: var(--label-out-stock-bg);
}

.label--in-stock {
  background-color: var(--label-in-stock-bg);
}

.label--pre-order {
  background-color: var(--label-pre-order-bg);
  color: var(--label-pre-order-c);
}

.in-stock {
  color: var(--theme7);
  stroke: var(--theme7);
}

.out-stock {
  color: var(--theme8);
  stroke: var(--theme8);
}

.shopify-review-starrating,
.shopify-review-icon,
.spr-icon {
  color: var(--review-icons-c);
}

.shopify-review-badge-caption {
  color: var(--review-caption-c);
}

.spr-review-reportreview,
.spr-review-reportreview:visited,
.spr-review-reportreview:active,
.spr-review-reportreview:link {
  color: var(--theme3);
}

.irs .irs-line {
  background-color: var(--theme5);
}

.irs .irs-bar {
  background-color: var(--theme);
}

.irs .irs-min,
.irs .irs-max,
.irs .irs-from,
.irs .irs-to,
.irs .irs-single {
  background-color: transparent;
  color: var(--theme3);
}

.irs .irs-slider {
  background-color: var(--theme);
  border-color: var(--theme);
}

.slider-revolution .tp-bullet {
  background-color: var(--slider-bullets-bg);
}

.slider-revolution .tp-bullet:hover {
  background-color: var(--slider-bullets-h-bg);
}

.slider-revolution .tp-loader.spinner2 {
  background-color: var(--slider-bullets-bg);
}

.slider-revolution .tparrows.uranus::before {
  color: var(--slider-arrows-c);
}

.slider-revolution .tparrows.uranus:hover::before {
  color: var(--slider-arrows-h-c);
}

.tippy-tooltip.shella-theme {
  background-color: var(--theme);
  color: var(--theme2);
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.shella-theme .tippy-arrow {
  border-top-color: var(--theme);
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.shella-theme .tippy-arrow {
  border-bottom-color: var(--theme);
}

.tippy-popper[x-placement^='left'] .tippy-tooltip.shella-theme .tippy-arrow {
  border-left-color: var(--theme);
}

.tippy-popper[x-placement^='right'] .tippy-tooltip.shella-theme .tippy-arrow {
  border-right-color: var(--theme);
}

.dropdown a,
.dropdown a:visited,
.dropdown a:active,
.dropdown a:link {
  color: var(--theme3);
}

.dropdown span[disabled],
.dropdown a[disabled] {
  opacity: 0.6;
}

.dropdown:not([data-dropdown-unselected]) .selected,
.dropdown:not([data-dropdown-unselected]) .active {
  color: var(--theme);
}

.dropdown:not([data-dropdown-unselected]) .selected span,
.dropdown:not([data-dropdown-unselected]) .active span {
  color: var(--theme);
}

.dropdown:not([data-dropdown-unselected]) .selected a,
.dropdown:not([data-dropdown-unselected]) .selected a:visited,
.dropdown:not([data-dropdown-unselected]) .selected a:active,
.dropdown:not([data-dropdown-unselected]) .selected a:link,
.dropdown:not([data-dropdown-unselected]) .active a,
.dropdown:not([data-dropdown-unselected]) .active a:visited,
.dropdown:not([data-dropdown-unselected]) .active a:active,
.dropdown:not([data-dropdown-unselected]) .active a:link {
  color: var(--theme);
}

.social-media a,
.social-media a:visited,
.social-media a:active,
.social-media a:link {
  color: var(--theme3);
}

.social-media a:hover {
  color: var(--theme);
}

.social-share__item--facebook {
  color: #4867AA;
}

.social-share__item--facebook .icon {
  fill: #4867AA;
}

.social-share__item--facebook .social-share__item_bg {
  background-color: #4867AA;
}

.social-share__item--twitter {
  color: #1DA1F2;
}

.social-share__item--twitter .icon {
  fill: #1DA1F2;
}

.social-share__item--twitter .social-share__item_bg {
  background-color: #1DA1F2;
}

.social-share__item--pinterest {
  color: #CB2027;
}

.social-share__item--pinterest .icon {
  fill: #CB2027;
}

.social-share__item--pinterest .social-share__item_bg {
  background-color: #CB2027;
}

.social-share__item--linkedin {
  color: #3997D0;
}

.social-share__item--linkedin .icon {
  fill: #3997D0;
}

.social-share__item--linkedin .social-share__item_bg {
  background-color: #3997D0;
}

.social-share__item--buffer {
  color: #222A30;
}

.social-share__item--buffer .icon {
  fill: #222A30;
}

.social-share__item--buffer .social-share__item_bg {
  background-color: #222A30;
}

.social-share__item--reddit {
  color: #FF5722;
}

.social-share__item--reddit .icon {
  fill: #FF5722;
}

.social-share__item--reddit .social-share__item_bg {
  background-color: #FF5722;
}

.breadcrumbs a,
.breadcrumbs a:visited,
.breadcrumbs a:active,
.breadcrumbs a:link {
  color: var(--theme3);
}

.header__tape {
  background-color: var(--header-tape-bg);
  border-bottom: var(--header-tape-bd);
}

.header__tape p {
  color: var(--header-tape-c);
}

.header__tape p a,
.header__tape p a:visited,
.header__tape p a:active,
.header__tape p a:link {
  color: var(--header-tape-c);
}

.header__tape .header__tape-close .icon {
  fill: var(--header-tape-btn-close-c);
}

.header__line-info {
  background-color: var(--header-info-line-bg);
  border-bottom: var(--header-info-line-bd);
}

.header__line-info,
.header__line-info p {
  color: var(--header-info-line-c);
}

.header__line-info a,
.header__line-info a:visited,
.header__line-info a:active,
.header__line-info a:link,
.header__line-info p a,
.header__line-info p a:visited,
.header__line-info p a:active,
.header__line-info p a:link {
  color: var(--header-info-line-c);
}

.header__line-info .icon {
  fill: var(--header-info-line-c);
}

.header__line-info .header__social-media .icon {
  fill: var(--header-social-media-c);
}

.header__line-info .header__social-media a:hover .icon {
  fill: var(--header-social-media-h-c);
}

.header__line-additional {
  background-color: var(--header-additional-line-bg);
  border-bottom: var(--header-additional-line-bd);
}

.header__line-additional .info-line-text-list {
  color: var(--header-additional-line-c);
}

.header__line-additional .info-line-text-list span {
  color: var(--header-additional-line-highlighted-c);
}

.header__line-additional .header__select-currency select,
.header__line-additional .header__ly-languages-switcher select {
  color: var(--header-additional-line-currency-languages-c);
}

.header__line-additional_button--register,
.header__line-additional_button--register:visited,
.header__line-additional_button--register:active,
.header__line-additional_button--register:link,
.header__line-additional_button--login,
.header__line-additional_button--login:visited,
.header__line-additional_button--login:active,
.header__line-additional_button--login:link {
  color: var(--header-additional-line-account-c);
}

.header__line-additional_button--services,
.header__line-additional_button--services:visited,
.header__line-additional_button--services:active,
.header__line-additional_button--services:link {
  color: var(--header-additional-line-services-c);
}

.header__line--colorize-1 .header__select-currency select,
.header__line--colorize-1 .header__ly-languages-switcher select {
  color: var(--header-btns-line-1-d-c);
}

.header__line--colorize-2 .header__select-currency select,
.header__line--colorize-2 .header__ly-languages-switcher select {
  color: var(--header-btns-line-2-d-c);
}

.header__search-form input[type='search'] {
  border: var(--header-search-input-border);
  background-color: var(--header-search-input-bg);
  border: var(--header-search-input-bd);
  color: var(--header-search-input-c);
}

.header__search-form input[type='search']::-webkit-input-placeholder {
  color: var(--header-search-input-c);
}

.header__search-form input[type='search']::-moz-placeholder {
  color: var(--header-search-input-c);
}

.header__search-form input[type='search']:-ms-input-placeholder {
  color: var(--header-search-input-c);
}

.header__search-form input[type='search']:-moz-placeholder {
  color: var(--header-search-input-c);
}

.header__search-form input[type='search']:focus {
  background-color: var(--header-search-input-f-bg);
  border: var(--header-search-input-f-bd);
}

.menu__label {
  color: var(--label-c);
}

.menu__label--sale {
  background-color: var(--label-sale-bg);
}

.menu__label--new {
  background-color: var(--label-new-bg);
}

.menu__label--hot {
  background-color: var(--label-hot-bg);
}

.menu__preview-image_image img.loaded+.menu__preview-image_shadow {
  background-color: var(--theme2);
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.promobox ul a,
.promobox ul a:visited,
.promobox ul a:active,
.promobox ul a:link {
  color: var(--theme-c);
}

.promobox__plate .promobox__content::before {
  background-color: var(--theme2);
}

.is-edge .promobox__content-scale::before {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.promobox__border:not(.promobox__border--only-hover) {
  border-color: var(--theme5);
}

.popup__bg {
  background-color: var(--theme-popups-bg);
}

.popup-navigation__search,
.popup-sidebar,
.popup-cart,
.popup-cart__footer,
.popup-wishlist,
.popup-wishlist-full,
.popup-compare-full,
.popup-account,
.popup-services,
.popup-quick-view,
.popup-size-guide,
.popup-delivery-return,
.popup-product-contact,
.popup-password,
.popup-pickup-available {
  background-color: var(--theme2);
}

.popup-navigation__search input[type="search"] {
  background-color: var(--theme2);
}

.popup-sidebar__close .icon {
  color: var(--theme);
}

.popup-cart__items>*:not(:first-child) {
  border-top-color: var(--theme5);
}

.popup-cart__discount span {
  color: var(--theme8);
}

.popup-cart__discount .icon {
  fill: var(--theme8);
}

.popup-cart__subtotal>* {
  color: var(--theme);
}

.popup-account__return-to-store {
  color: var(--theme);
}

.popup-subscription-confirmation,
.popup-contact-confirmation {
  background-color: var(--popup-subscription-confirmation-bg);
}

.popup-subscription-confirmation__close .icon,
.popup-subscription-confirmation__title-icon .icon,
.popup-contact-confirmation__close .icon,
.popup-contact-confirmation__title-icon .icon {
  fill: var(popup-subscription-confirmation-icon-close-c);
}

.popup-subscription-confirmation__message--success,
.popup-contact-confirmation__message--success {
  background-color: var(--popup-subscription-confirmation-success-bg);
}

.popup-subscription-confirmation__message--success p,
.popup-contact-confirmation__message--success p {
  color: var(--popup-subscription-confirmation-success-c);
}

.popup-subscription-confirmation__message--error,
.popup-contact-confirmation__message--error {
  background-color: var(--popup-subscription-confirmation-error-bg);
  color: var(--popup-subscription-confirmation-error-c);
}

.popup-subscription-confirmation__message--error ul,
.popup-contact-confirmation__message--error ul {
  color: var(--popup-subscription-confirmation-error-c);
}

.popup-subscription-confirmation__message--error ul:not(.list-unstyled) li::before,
.popup-contact-confirmation__message--error ul:not(.list-unstyled) li::before {
  background-color: var(--popup-subscription-confirmation-error-c);
}

.search input {
  color: var(--theme);
}

.search input::-webkit-input-placeholder {
  color: var(--theme);
}

.search input::-moz-placeholder {
  color: var(--theme);
}

.search input:-ms-input-placeholder {
  color: var(--theme);
}

.search input:-moz-placeholder {
  color: var(--theme);
}

.search-ajax__content {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.countdown--type-01 {
  color: var(--theme);
}

.countdown--type-01>span::before {
  background-color: var(--theme2);
}

.countdown--type-02 .countdown__time {
  color: var(--theme);
}

.countdown--type-02 .countdown__time>* {
  border-color: var(--theme5);
  background-color: var(--theme-body);
}

.countdown--type-02 .countdown__section:not(:last-child) .countdown__time::after {
  color: var(--theme3);
}

.stock-countdown__counter {
  color: var(--theme8);
}

.stock-countdown__range {
  background-color: var(--theme5);
}

.stock-countdown__progress {
  background-color: var(--theme);
}

.delivery-countdown {
  background-color: var(--theme5);
  color: var(--theme);
}

.delivery-countdown__counter {
  color: var(--theme8);
}

.visitors {
  color: var(--theme);
}

.visitors__counter {
  background-color: var(--theme);
  color: var(--theme2);
}

.free-shipping {
  background-color: var(--theme8);
  color: var(--theme2);
}

.free-shipping .icon {
  fill: var(--theme2);
}

.free-shipping__progress {
  background-color: var(--theme7);
}

.product-collection__title a,
.product-collection__title a:visited,
.product-collection__title a:active,
.product-collection__title a:link,
.product-wishlist__title a,
.product-wishlist__title a:visited,
.product-wishlist__title a:active,
.product-wishlist__title a:link,
.product-compare__title a,
.product-compare__title a:visited,
.product-compare__title a:active,
.product-compare__title a:link,
.product-featured__title a,
.product-featured__title a:visited,
.product-featured__title a:active,
.product-featured__title a:link {
  color: var(--product-collection-title-c);
}

.product-collection__more-info a,
.product-collection__more-info a:visited,
.product-collection__more-info a:active,
.product-collection__more-info a:link,
.product-wishlist__collections a,
.product-wishlist__collections a:visited,
.product-wishlist__collections a:active,
.product-wishlist__collections a:link,
.product-compare__collections a,
.product-compare__collections a:visited,
.product-compare__collections a:active,
.product-compare__collections a:link,
.product-featured__collections a,
.product-featured__collections a:visited,
.product-featured__collections a:active,
.product-featured__collections a:link {
  color: var(--product-collection-more-info-c);
}

.product-collection__sku span,
.product-collection__barcode span,
.product-collection__availability span,
.product-collection__type span,
.product-collection__vendor span {
  color: var(--theme);
}

.product-page-info__details p span {
  color: var(--theme);
}

.product-page-info__reviews {
  color: var(--theme-primary);
}

.product-page-info__price-sale-details {
  color: var(--theme8);
}

.product-page-info__share label {
  color: var(--theme);
}

.one-product-info__details p span {
  color: var(--theme);
}

.one-product-info__reviews {
  color: var(--theme-primary);
}

.one-product-info__price-sale-details {
  color: var(--theme8);
}

.one-product-info__share label {
  color: var(--theme3);
}

.product-page-info__title>*,
.product-page-info__title>*:visited,
.product-page-info__title>*:active,
.product-page-info__title>*:link,
.one-product-info__title>*,
.one-product-info__title>*:visited,
.one-product-info__title>*:active,
.one-product-info__title>*:link {
  color: var(--product-page-title-c);
}

.product-page-info__collections a,
.product-page-info__collections a:visited,
.product-page-info__collections a:active,
.product-page-info__collections a:link,
.one-product-info__collections a,
.one-product-info__collections a:visited,
.one-product-info__collections a:active,
.one-product-info__collections a:link {
  color: var(--product-page-collections-c);
}

.product-collection__availability [data-availability="false"] span,
.product-page-info__availability [data-availability="false"] span,
.one-product-info__availability [data-availability="false"] span {
  color: var(--theme8);
}

.product-page-info__details-buttons .btn-link,
.product-page-info__details-buttons .btn-link:visited,
.product-page-info__details-buttons .btn-link:active,
.product-page-info__details-buttons .btn-link:link,
.product-page-info__full-details .btn-link,
.product-page-info__full-details .btn-link:visited,
.product-page-info__full-details .btn-link:active,
.product-page-info__full-details .btn-link:link,
.one-product-info__details-buttons .btn-link,
.one-product-info__details-buttons .btn-link:visited,
.one-product-info__details-buttons .btn-link:active,
.one-product-info__details-buttons .btn-link:link {
  color: var(--product-page-details-buttons-c);
}

.one-product-info .countdown__time>* {
  background-color: var(--theme4);
  border-color: var(--theme4);
}

.one-product__bg .one-product-info .countdown__time>* {
  background-color: var(--theme-body);
  border-color: var(--theme-body);
}

.button-quick-view {
  background-color: var(--product-collection-quick-view-bg);
}

.button-quick-view .icon {
  fill: var(--product-collection-quick-view-c);
}

.product-images-navigation span {
  background-color: var(--product-collection-images-navigation-bg);
}

.product-images-navigation span .icon {
  fill: var(--product-collection-images-navigation-act-bg);
}

.table-product-sizes td:first-child,
.table-account td:first-child {
  color: var(--theme3);
}

.tabs[data-type="horizontal"] .tabs__body>div>span {
  color: var(--theme);
}

.tabs[data-type="mobile"] .tabs__body {
  border-color: var(--product-page-tabs-border-c);
}

.tabs[data-type="mobile"] .tabs__body>div>span {
  color: var(--theme);
}

.tabs[data-type="mobile"] .tabs__body>div:not(:first-child)>span {
  border-top-color: var(--product-page-tabs-border-c);
}

.product-gallery__main_arrow::before {
  background-color: var(--theme2);
}

.product-gallery__zoom--main .product-gallery__zoom_image {
  background-color: var(--theme-body);
}

.product-gallery__thumbnail_item.current .product-gallery__thumbnail_item_inner::before {
  border-color: var(--theme);
}

.product-gallery__thumbnail_item_bg {
  background-color: var(--theme2);
}

.product-gallery__thumbnail_arrow.slick-disabled .icon {
  fill: var(--theme5);
}

.product-gallery__fullscreen {
  background-color: var(--theme-popups-bg);
}

.product-gallery__fullscreen .model-viewer-wrapper {
  background-color: var(--theme-body);
}

.product-gallery__fullscreen_arrow_btn .icon,
.product-gallery__fullscreen_close .icon {
  fill: var(--theme2);
}

.product-gallery__fullscreen_arrow_btn::before,
.product-gallery__fullscreen_close::before {
  background-color: var(--theme-popups-bg);
}

.price {
  color: var(--price-c);
}

.price--sale>span:first-child {
  color: var(--price-sale-1-c);
}

.price--sale>span:not(first-child) {
  color: var(--price-sale-2-c);
}

.product-options label {
  color: var(--theme3);
}

.product-options__value--text,
.product-options__value--large-text,
.product-options__value--square {
  background-color: var(--product-options-bg);
  color: var(--product-options-c);
}

.product-options__value--circle::before,
.product-options__value--text,
.product-options__value--large-text,
.product-options__value--square {
  border-color: var(--product-options-bd) !important;
}

.product-options__value--text.active,
.product-options__value--large-text.active,
.product-options__value--square.active {
  background-color: var(--product-options-act-bg);
  color: var(--product-options-act-c);
}

.product-options__value--circle.active::before,
.product-options__value--text.active,
.product-options__value--large-text.active,
.product-options__value--square.active {
  border-color: var(--product-options-act-bd) !important;
}

.product-options__section[data-disable-auto-select] .product-options__value--text.active,
.product-options__section[data-disable-auto-select] .product-options__value--large-text.active,
.product-options__section[data-disable-auto-select] .product-options__value--square.active {
  border-color: var(--theme5) !important;
  color: var(--theme-c);
}

.product-options__value.disabled-hidden span::before,
.product-options__value.disabled-hidden span::after {
  background-color: var(--theme);
}

.collection-sidebar-type-2 .collection-filters__checkbox.input-checkbox:not(.input-checkbox--unbordered) .icon {
  fill: var(--theme2);
}

.collection-sidebar-type-2 .collection-filters__checkbox.input-checkbox:not(.input-checkbox--unbordered) input:checked+span {
  background-color: var(--theme);
}

.collection-filters__price-input-wrapper input:hover+span,
.collection-filters__price-input-wrapper input:focus+span {
  color: var(--theme);
}

.collection-sidebar-type-1 .collection-sidebar-section--bordered {
  border-bottom-color: var(--theme5);
}

.collection-control__button-sidebar {
  color: var(--theme);
}

.collection-control select {
  background-color: transparent;
}

.collection-current-filters__item a {
  background-color: var(--theme4);
  color: var(--theme3);
}

.collection-current-filters__item a .icon {
  fill: var(--theme3);
}

.slider__prev .icon,
.slider__next .icon {
  fill: var(--slider-arrows-c);
}

.slider__prev:hover .icon,
.slider__next:hover .icon {
  fill: var(--slider-arrows-h-c);
}

.slider .slick-dots li {
  background-color: var(--slider-bullets-bg);
}

.slider .slick-dots li:hover {
  background-color: var(--slider-bullets-h-bg);
}

.carousel .slick-dots li {
  background-color: var(--theme5);
}

.carousel .slick-dots li.slick-active {
  background-color: var(--theme);
}

.carousel-articles__title>*,
.carousel-articles__title>*:visited,
.carousel-articles__title>*:active,
.carousel-articles__title>*:link {
  color: var(--article-carousel-title-c);
}

.carousel-reviews--style-1.carousel-reviews-container {
  background-color: var(--review-carousel-bg);
}

.carousel-reviews--style-1 .carousel-reviews__item_content {
  background-color: var(--theme2);
}

.carousel-reviews--style-1 .carousel-reviews__stars .icon {
  fill: var(--review-carousel-stars-c);
}

.carousel-reviews--style-1 .carousel-reviews__paragraph {
  color: var(--theme3);
}

.carousel-reviews--style-1 .carousel-reviews__item_user p {
  color: var(--theme);
}

.carousel-reviews--style-1 .carousel-reviews__item_user p:last-child {
  color: var(--theme3);
}

.carousel-reviews--style-1 .slick-arrow .icon {
  fill: var(--theme);
}

.carousel-reviews--style-2.carousel-reviews-container {
  background-color: var(--theme);
}

.carousel-reviews--style-2 .carousel-reviews__item_content {
  background-color: var(--theme);
}

.carousel-reviews--style-2 .carousel-reviews__stars .icon {
  fill: var(--review-carousel-stars-c);
}

.carousel-reviews--style-2 .carousel-reviews__paragraph {
  color: var(--theme2);
}

.carousel-reviews--style-2 .carousel-reviews__item_user p {
  color: var(--theme2);
}

.carousel-reviews--style-2 .carousel-reviews__item_user p:last-child {
  color: var(--theme2);
}

.carousel-reviews--style-2 .slick-arrow .icon {
  fill: var(--theme2);
}

.carousel-reviews--style-3.carousel-reviews-container {
  background-color: var(--theme2);
}

.carousel-reviews--style-3 .carousel-reviews__item_content {
  background-color: var(--review-carousel-bg);
}

.carousel-reviews--style-3 .carousel-reviews__stars .icon {
  fill: var(--review-carousel-stars-c);
}

.carousel-reviews--style-3 .carousel-reviews__paragraph {
  color: var(--theme3);
}

.carousel-reviews--style-3 .carousel-reviews__item_user p {
  color: var(--theme);
}

.carousel-reviews--style-3 .carousel-reviews__item_user p:last-child {
  color: var(--theme3);
}

.carousel-reviews--style-3 .slick-arrow .icon {
  fill: var(--theme);
}

.home-section-title:not(.home-section-title--multiple) {
  color: var(--home-sections-title-c);
}

.home-section-title:not(.home-section-title--multiple) a,
.home-section-title:not(.home-section-title--multiple) a:visited,
.home-section-title:not(.home-section-title--multiple) a:active,
.home-section-title:not(.home-section-title--multiple) a:link {
  color: var(--home-sections-title-act-c);
}

.home-section-title:not(.home-section-title--multiple) a:hover {
  color: var(--home-sections-title-h-c) !important;
}

.home-section-title--multiple {
  color: var(--home-sections-title-multiple-act-c);
}

.home-section-title--multiple a,
.home-section-title--multiple a:visited,
.home-section-title--multiple a:active,
.home-section-title--multiple a:link {
  color: var(--home-sections-title-multiple-act-c);
}

.home-section-title--multiple a:hover {
  color: var(--home-sections-title-multiple-h-c) !important;
}

.home-section-title--multiple a:not(.active),
.home-section-title--multiple a:not(.active):visited,
.home-section-title--multiple a:not(.active):active,
.home-section-title--multiple a:not(.active):link {
  color: var(--home-sections-title-multiple-c);
}

.brands__letter a,
.brands__letter a:visited,
.brands__letter a:active,
.brands__letter a:link {
  color: var(--theme);
}

.brands__letter a:hover {
  background-color: var(--theme5);
}

.brands__letter a.active {
  background-color: var(--theme);
}

.brands__letter a.active,
.brands__letter a.active:visited,
.brands__letter a.active:active,
.brands__letter a.active:link {
  color: var(--theme2);
}

.search-form {
  background-color: var(--theme4);
}

.compare__title h4 {
  color: var(--theme3);
}

.compare__collections a,
.compare__collections a:visited,
.compare__collections a:active,
.compare__collections a:link {
  color: var(--theme3);
}

.cart__head label {
  color: var(--theme3);
}

.cart__discount span {
  color: var(--theme8);
}

.cart__discount .icon {
  fill: var(--theme8);
}

.gallery__item-bg {
  background-color: var(--theme);
}

.gallery__item-text>* {
  color: var(--theme2);
}

.gallery__fotorama.fotorama--fullscreen,
.gallery__fotorama.fotorama--fullscreen .fotorama__nav,
.gallery__fotorama.fotorama--fullscreen .fotorama__stage {
  background-color: transparent;
}

.gallery__fotorama.fotorama--fullscreen::before {
  background-color: var(--theme);
}

.gallery__fotorama .fotorama__arr .icon,
.gallery__fotorama .fotorama__fullscreen-icon .icon {
  fill: var(--theme2);
}

.fullscreen {
  background-color: var(--theme2) !important;
}

.blog-body__button-sidebar {
  color: var(--theme);
}

.post__hidden-info>* {
  background-color: var(--theme2);
}

.article-body__button-sidebar {
  color: var(--theme);
}

.article-slider .slick-arrow::before {
  background-color: var(--theme2);
}

.article-slider .slick-dots li {
  background-color: var(--theme5);
}

.article-slider .slick-dots li.slick-active {
  background-color: var(--theme);
}

.lookbook__picker-circle::before {
  background-color: var(--theme2);
}

.lookbook__picker-circle::after {
  background-color: var(--theme);
}

.lookbook__product {
  background-color: var(--theme2);
}

.table-size-guide th {
  background-color: var(--theme);
  color: var(--theme2);
}

.table-size-guide th,
.table-size-guide tr:nth-child(2) td {
  border-top-color: var(--theme);
}

.table-size-guide tr:last-child td {
  border-bottom: solid 1px var(--theme5);
}

.instafeed__curtain {
  color: var(--theme2);
}

.instafeed__curtain .icon {
  fill: var(--theme2);
}

.instafeed__curtain::before {
  background-color: var(--theme);
}

.wishlist__button-remove,
.compare__button-remove {
  color: var(--theme);
}

.notification-products {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.notification-cookies p {
  color: var(--theme2);
}

.notification-cookies p a,
.notification-cookies p a:visited,
.notification-cookies p a:active,
.notification-cookies p a:link {
  color: var(--theme2);
}

.notification-cookies__bg {
  background-color: var(--notification-cookie-bg);
}

.notification-cookies__button-info,
.notification-cookies__button-info:visited,
.notification-cookies__button-info:active,
.notification-cookies__button-info:link {
  color: var(--notification-cookie-button-info-c);
}

.notification-cookies__button-close,
.notification-cookies__button-close:visited,
.notification-cookies__button-close:active,
.notification-cookies__button-close:link {
  color: var(--notification-cookie-button-close-c);
}

.notification-cookies__button-close .icon {
  fill: var(--notification-cookie-button-close-c);
}

.footbar-product {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.product-fixed-sizebar,
.product-fixed-sizebar__btn {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.product-fixed-sizebar__btn::after {
  background-color: var(--theme2);
}

.product-fixed-sizebar__line span {
  color: var(--theme);
}

.product-fixed-sizebar__line .icon {
  fill: var(--theme);
}

.product-fixed-sizebar:hover .product-fixed-sizebar__line:not(:first-child) {
  border-top-color: var(--theme5);
}

.footer {
  background-color: var(--footer-bg);
  color: var(--footer-text-c);
}

.footer a,
.footer a:visited,
.footer a:active,
.footer a:link {
  color: var(--footer-links-c);
}

.footer p,
.footer p>* {
  color: var(--footer-text-c);
}

.footer .icon {
  fill: var(--footer-icons-c);
}

.footer__social-media a:hover .icon {
  fill: var(--footer-icons-h-c);
}

.footer__section-head h1,
.footer__section-head h1>*,
.footer__section-head h2,
.footer__section-head h2>*,
.footer__section-head h3,
.footer__section-head h3>*,
.footer__section-head h4,
.footer__section-head h4>*,
.footer__section-head h5,
.footer__section-head h5>*,
.footer__section-head h6,
.footer__section-head h6>*,
.footer__subscription h1,
.footer__subscription h1>*,
.footer__subscription h2,
.footer__subscription h2>*,
.footer__subscription h3,
.footer__subscription h3>*,
.footer__subscription h4,
.footer__subscription h4>*,
.footer__subscription h5,
.footer__subscription h5>*,
.footer__subscription h6,
.footer__subscription h6>*,
.footer__social-media h1,
.footer__social-media h1>*,
.footer__social-media h2,
.footer__social-media h2>*,
.footer__social-media h3,
.footer__social-media h3>*,
.footer__social-media h4,
.footer__social-media h4>*,
.footer__social-media h5,
.footer__social-media h5>*,
.footer__social-media h6,
.footer__social-media h6>*,
.footer__section-content h1,
.footer__section-content h1>*,
.footer__section-content h2,
.footer__section-content h2>*,
.footer__section-content h3,
.footer__section-content h3>*,
.footer__section-content h4,
.footer__section-content h4>*,
.footer__section-content h5,
.footer__section-content h5>*,
.footer__section-content h6,
.footer__section-content h6>* {
  color: var(--footer-titles-c);
}

.footer__border {
  border-top: var(--footer-bd);
}

.footer__border-side {
  border-left: var(--footer-bd);
}

.footer__border-top {
  border-top: var(--footer-line-1-bd);
}

.footer__border-bottom {
  border-top: var(--footer-line-2-bd);
}


/* FIX: Remove custom style from footer newsletter
.footer__subscription input[type='email'] {
  border: var(--footer-subscription-input-bd);
  background-color: var(--footer-subscription-input-bg);
  color: var(--footer-subscription-input-c);
}

.footer__subscription input[type='email']::-webkit-input-placeholder {
  color: var(--footer-subscription-input-c);
}

.footer__subscription input[type='email']::-moz-placeholder {
  color: var(--footer-subscription-input-c);
}

.footer__subscription input[type='email']:-ms-input-placeholder {
  color: var(--footer-subscription-input-c);
}

.footer__subscription input[type='email']:-moz-placeholder {
  color: var(--footer-subscription-input-c);
}

.footer__subscription input[type='email']:focus {
  background-color: var(--footer-subscription-input-f-bg);
  border: var(--footer-subscription-input-f-bd);
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:focus::-webkit-input-placeholder {
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:focus::-moz-placeholder {
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:focus:-ms-input-placeholder {
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:focus:-moz-placeholder {
  color: var(--footer-subscription-input-f-c);
}
*/

.giftcard__value,
.giftcard__code {
  color: var(--theme-c);
}

.giftcard__code {
  background-color: var(--theme2);
}

.presentation {
  background-color: var(--theme2);
}

.presentation__btn {
  background-color: var(--theme);
}

.presentation__btn i .icon {
  fill: var(--theme2);
}

.presentation.open .presentation__btn {
  background-color: var(--theme);
}

.presentation__content label span {
  color: var(--theme-c);
}

.presentation__content label:hover span,
.presentation__content label input:checked~span {
  color: var(--theme);
}

.presentation__content a,
.presentation__content a:visited,
.presentation__content a:active,
.presentation__content a:link {
  color: var(--theme-c);
}

[dir='rtl'] .border-left {
  border-left-color: none;
  border-right-color: var(--theme5);
}

[dir='rtl'] .border-right {
  border-left-color: var(--theme5);
  border-left-color: none;
}

html.css-full-loaded body.theme-css-animate .menu-vertical-btn .menu-vertical-btn__icon-open {
  transition-duration: var(--animation-css-low-duration);
}

html.css-full-loaded body.theme-css-animate .popup__bg.animate,
html.css-full-loaded body.theme-css-animate .popup__body.animate [data-popup-content] {
  transition-duration: var(--animation-popup-duration);
}

html.css-full-loaded body.theme-css-animate.popup-bg-blur main {
  transition-duration: var(--animation-popup-duration);
}

html.css-full-loaded body.theme-css-animate .notification.animate {
  transition-duration: var(--animation-notification-duration);
}

html.css-full-loaded body.theme-css-animate .notification.animate.pressed {
  transition-duration: calc(var(--animation-notification-duration) / 2);
}

html.css-full-loaded body.theme-css-animate .notification__inner.animate {
  transition-duration: var(--animation-notification-duration);
}

html.css-full-loaded body.theme-css-animate .footbar-product>* {
  transition-duration: var(--animation-footbar-product-duration);
}

html.css-full-loaded body.theme-css-animate .product-gallery__fullscreen.animate {
  transition-duration: calc(var(--animation-popup-duration) / 2);
}

html.css-full-loaded body.theme-css-animate .faqs__cross {
  transition-duration: var(--animation-accordion-duration);
}

html.css-full-loaded body.theme-css-animate .tabs[data-type="mobile"] .tabs__body>div>span i {
  transition-duration: var(--animation-tabs-duration);
}

html.css-full-loaded body.theme-css-animate .carousel__title a,
html.css-full-loaded body.theme-css-animate .product-fixed-sizebar,
html.css-full-loaded body.theme-css-animate .product-fixed-sizebar__line,
html.css-full-loaded body.theme-css-animate .slick-slide .promobox__content {
  transition-duration: var(--animation-css-duration);
}

html.css-full-loaded body.theme-css-animate .promobox:not(.promobox--is-slider) .promobox__content {
  transition-duration: 0.5s !important;
}

html.css-full-loaded body.theme-css-animate input[type="text"],
html.css-full-loaded body.theme-css-animate input[type="number"],
html.css-full-loaded body.theme-css-animate input[type="email"],
html.css-full-loaded body.theme-css-animate input[type="password"],
html.css-full-loaded body.theme-css-animate input[type="search"],
html.css-full-loaded body.theme-css-animate input[type="tel"],
html.css-full-loaded body.theme-css-animate textarea,
html.css-full-loaded body.theme-css-animate select,
html.css-full-loaded body.theme-css-animate .input-boolean input~span,
html.css-full-loaded body.theme-css-animate .input-boolean input+span span,
html.css-full-loaded body.theme-css-animate .spr-summary-actions-newreview,
html.css-full-loaded body.theme-css-animate .input-quantity [data-control] {
  transition-duration: var(--animation-css-low-duration);
}

html.css-full-loaded body.theme-css-animate .btn {
  transition-property: background-color, border-color, color;
  transition-duration: var(--animation-css-low-duration);
}

html.css-full-loaded body.theme-css-animate .input-boolean input:checked+span {
  transition-duration: calc(var(--animation-css-low-duration) * 2.5);
}

.slider-revolution .tp-bullet:hover,
.slider .slick-dots li:hover {
  opacity: var(--slider-bullets-h-opacity);
}

.slider-revolution .tparrows.uranus:hover::before,
.slider__prev:hover .icon,
.slider__next:hover .icon {
  opacity: var(--slider-arrows-h-opacity);
}

.hide-page-content main>*,
.hide-page-content .header__line-info,
.hide-page-content .header__btn-menu,
.hide-page-content .header__sidebar,
.hide-page-content .header__nav,
.hide-page-content .header__line-bottom,
.hide-page-content .header__line-additional_sidebar,
.hide-page-content .header__search-form,
.hide-page-content .header__border,
.hide-page-content .footer,
.hide-page-content .footbar {
  opacity: 0 !important;
}

.grecaptcha-badge {
  z-index: 2;
}

@media (min-width: 1025px) {
  .grecaptcha-badge {
    bottom: 60px !important;
  }
}

@media (max-width: 1024px) {
  .grecaptcha-badge {
    bottom: 40px !important;
  }
}

/*================ Rich Text Editor ================*/
.rte img {
  height: auto;
}

.rte ul+*,
.rte ol+* {
  margin-top: 20px;
}

.text-center.rte ul,
.text-center.rte ol,
.text-center .rte ul,
.text-center .rte ol {
  margin-left: 0;
  list-style-position: inside;
}

.rte-table {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.rte__video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;
}

.rte__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rte__table-wrapper {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.is-ios .rte__table-wrapper>table {
  display: block;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.irs .irs-from,
.irs .irs-to,
.irs .irs-single,
.irs .irs-min,
.irs .irs-max {
  top: 4px;
}

.irs .irs-slider {
  width: 10px;
  height: 10px;
  top: 21px;
}

.input-checkbox span {
  transition-property: color;
}

.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select i {
  top: 50%;
  margin-top: -10px;
  pointer-events: none;
}

.select__dropdown {
  min-width: 100%;
  max-height: 220px;
  overflow: auto;
}

.btn--size-large {
  min-height: 60px;
}

.dynamic-checkout__button--styled .shopify-payment-button__button.shopify-payment-button__button--unbranded,
.dynamic-checkout__button--styled .shopify-payment-button__button.shopify-payment-button__button--branded .shopify-cleanslate div[role="button"] {
  padding: 0 16px !important;
}

.btn--animated .btn__text {
  transition-property: transform, color;
  will-change: transform, color;
}

.btn--animated .btn__icon {
  transition-property: transform;
  will-change: transform;
}

.btn--animated .btn__icon {
  transform: translate3d(-40px, 45px, 0);
}

.btn--animated .btn__text {
  transform: translate3d(-12px, 0, 0);
}

.btn--animated:hover .btn__icon,
.btn--animated:hover .btn__text {
  transform: translate3d(0, 0, 0);
}

.image-animation {
  overflow: hidden;
}

.theme-css-animate .image-animation>* {
  transition-property: transform;
  backface-visibility: hidden;
}

.theme-css-animate .image-animation--from-opacity>*,
.theme-css-animate .image-animation--to-opacity>* {
  transition-property: opacity, transform;
}

.theme-css-animate .image-animation--from-opacity:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--from-opacity>* {
  opacity: 1;
}

.theme-css-animate .image-animation--from-opacity>*,
.theme-css-animate .image-animation--to-opacity:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-opacity>* {
  opacity: 0.4;
}

.theme-css-animate .image-animation--from-none>*,
.theme-css-animate .image-animation--to-none:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-none>* {
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

.theme-css-animate .image-animation--from-center>*,
.theme-css-animate .image-animation--to-center:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-center>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0);
}

.theme-css-animate .image-animation--from-top-left>*,
.theme-css-animate .image-animation--to-top-left:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-top-left>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(-4%, -4%, 0);
}

.theme-css-animate .image-animation--from-top>*,
.theme-css-animate .image-animation--to-top:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-top>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(0, -4%, 0);
}

.theme-css-animate .image-animation--from-top-right>*,
.theme-css-animate .image-animation--to-top-right:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-top-right>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(4%, -4%, 0);
}

.theme-css-animate .image-animation--from-right>*,
.theme-css-animate .image-animation--to-right:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-right>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(4%, 0, 0);
}

.theme-css-animate .image-animation--from-bottom-right>*,
.theme-css-animate .image-animation--to-bottom-right:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-bottom-right>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(4%, 4%, 0);
}

.theme-css-animate .image-animation--from-bottom>*,
.theme-css-animate .image-animation--to-bottom:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-bottom>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 4%, 0);
}

.theme-css-animate .image-animation--from-bottom-left>*,
.theme-css-animate .image-animation--to-bottom-left:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-bottom-left>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(-4%, 4%, 0);
}

.theme-css-animate .image-animation--from-left>*,
.theme-css-animate .image-animation--to-left:hover>*,
.theme-css-animate .image-animation-trigger:hover .image-animation--to-left>* {
  transform: scale3d(1.1, 1.1, 1) translate3d(-4%, 0, 0);
}

.header .container {
  z-index: 2;
}

.header__tape i[data-action="close"]::before {
  width: 30px;
}

.header__line-additional {
  z-index: 3;
}

.header__line-additional_separator {
  height: 20px;
}

.header__line-top {
  min-height: 38px;
  z-index: 2;
}

.header__line-bottom {
  z-index: 1;
}

.header__nav,
.header__vertical-nav {
  height: calc(100% + 12px);
  margin-top: -6px;
  z-index: 1;
}

.header__content--sticky .header__logo--sticky-hidden {
  display: none !important;
}

.header__content--sticky .header__logo--sticky-hidden+.header__sticky-logo-replace {
  display: flex !important;
}

.header__content--sticky .header__sticky-logo--containerized {
  display: flex !important;
}

.header__btn-services::before,
.header__btn-account::before,
.header__btn-wishlist::before,
.header__btn-compare::before,
.header__btn-cart::before,
.header__btn-currency::before,
.header__select-currency::before,
.header__btn-language::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: -7px;
  left: -8px;
  right: -8px;
}

.header__btn-currency i,
.header__btn-language i {
  width: 15px;
}

.header__btn-language+.header__dropdown {
  margin-top: 8px;
}

.header__btn-currency span {
  min-width: 34px;
}

.header__select-currency form::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  top: 100%;
  left: 0;
}

.header__select-currency i {
  top: 16px;
  margin-right: -3px;
}

.header__dropdown {
  margin-top: 7px;
}

.header__select-currency+.header__dropdown {
  margin-top: 6px;
}

.header__dropdown {
  min-width: 169px;
}

.header__password {
  min-width: 260px;
}

.header--type-5 .header__search-form,
.header--type-6 .header__search-form {
  width: 48%;
  left: 50%;
  margin-left: -24%;
}

.header--type-7 .header__search-form {
  width: 370px;
  max-width: 48%;
  min-width: 370px;
}

@media (min-width: 1260px) {
  .slider .slick-dots {
    margin-bottom: 50px;
  }
}

.social-media {
  margin-right: -10px;
}

.social-media a {
  transition-property: color;
}

.social-share__item:hover .social-share__item_bg {
  opacity: 1;
}

.dropdown a,
.dropdown span {
  display: block;
  padding: 0 7px;
  white-space: nowrap;
}

.dropdown.show {
  display: block !important;
}

.dropdown>* {
  opacity: 0;
}

.dropdown.animate>* {
  will-change: opacity;
  transition-property: opacity;
}

.dropdown.visible>* {
  opacity: 1;
}

.tabs[data-type="horizontal"] .tabs__head.tabs__head--slider .tabs__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  cursor: pointer;
  z-index: 2;
}

.tabs[data-type="horizontal"] .tabs__head.tabs__head--slider .tabs__nav.disabled {
  display: none !important;
}

.tabs[data-type="horizontal"] .tabs__head.tabs__head--slider .tabs__nav--prev {
  left: -2px;
}

.tabs[data-type="horizontal"] .tabs__head.tabs__head--slider .tabs__nav--next {
  right: -2px;
}

.tabs[data-type="horizontal"] .tabs__body {
  overflow-x: auto;
}

.tabs[data-type="horizontal"] .tabs__body>div>span {
  display: none;
}

.tabs[data-type="horizontal"] .tabs__content {
  padding: 35px 0;
}

.tabs[data-type="horizontal"] .tabs__head {
  position: relative;
  border-bottom: solid 1px transparent;
}

.tabs[data-type="horizontal"] .tabs__slider {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: -1px;
}

.tabs[data-type="horizontal"] .tabs__btn {
  padding: 6px 20px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  border: solid 1px transparent;
}

.tabs[data-type="mobile"] .tabs__body>div>span {
  padding: 8px 0;
}

.tabs[data-type="mobile"] .tabs__body>div>span i {
  right: -6px;
}

.tabs[data-type="mobile"] .tabs__content {
  padding: 10px 0;
}

.sticky-sidebar-lg {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}

.sticky-sidebar-lg.sidebar-fixed-top [data-js-sticky-sidebar-body] {
  position: fixed;
  top: 15px;
}

.sticky-sidebar-lg.sidebar-fixed-bottom [data-js-sticky-sidebar-body] {
  position: fixed;
  bottom: 15px;
}

.sticky-sidebar-lg.sidebar-static-bottom {
  justify-content: flex-end;
}

@media (min-width: 1260px) {
  .sticky-sidebar-xl {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (min-width: 1260px) {
  .sticky-sidebar-xl.sidebar-fixed-top [data-js-sticky-sidebar-body] {
    position: fixed;
    top: 15px;
  }
}

@media (min-width: 1260px) {
  .sticky-sidebar-xl.sidebar-fixed-bottom [data-js-sticky-sidebar-body] {
    position: fixed;
    bottom: 15px;
  }
}

@media (min-width: 1260px) {
  .sticky-sidebar-xl.sidebar-static-bottom {
    justify-content: flex-end;
  }
}

.product-options__value {
  margin-bottom: 10px;
}

.product-options__value:not(:last-child) {
  margin-right: 10px;
}

.product-options--type-footbar {
  display: flex;
  margin-bottom: 2px;
}

.product-options--type-footbar label {
  margin-bottom: 5px;
  text-transform: uppercase;
}

.product-options--type-footbar [data-section-container]:not(:last-child) .product-options__section {
  margin-bottom: 8px;
  margin-right: 20px;
}

.product-options--type-footbar .product-options__value--circle {
  width: 24px;
  height: 24px;
}

.product-options--type-footbar .product-options__value--circle.active::before {
  width: 28px;
  height: 28px;
}

.product-options--type-footbar .product-options__value--circle.disabled::before,
.product-options--type-footbar .product-options__value--circle.disabled::after {
  width: 24px;
}

.product-options--type-footbar .product-options__value--large-text {
  min-width: 24px;
  min-height: 24px;
  padding: 0 2px;
}

.product-options--type-footbar .product-options__value--large-text.disabled::before,
.product-options--type-footbar .product-options__value--large-text.disabled::after {
  width: 32px;
}

.product-image--hover-emersion-x,
.product-image--hover-emersion-y,
.product-image--hover-emersion-z {
  overflow: hidden;
}

.product-image--hover-fade .product-image__overlay-top-right,
.product-image--hover-fade .product-image__overlay-bottom-right {
  transition-property: opacity;
  will-change: opacity;
}

.product-image--hover-emersion-x .product-image__overlay-top-right,
.product-image--hover-emersion-x .product-image__overlay-bottom-right,
.product-image--hover-emersion-y .product-image__overlay-top-right,
.product-image--hover-emersion-y .product-image__overlay-bottom-right,
.product-image--hover-emersion-z .product-image__overlay-top-right,
.product-image--hover-emersion-z .product-image__overlay-bottom-right,
.product-image--hover-scale .product-image__overlay-top-right,
.product-image--hover-scale .product-image__overlay-bottom-right {
  transition-property: transform, opacity;
  will-change: transform, opacity;
}

.product-image--hover-toggle .product-image__overlay-top-right,
.product-image--hover-toggle .product-image__overlay-bottom-right,
.product-image--hover-fade .product-image__overlay-top-right,
.product-image--hover-fade .product-image__overlay-bottom-right,
.product-image--hover-emersion-x .product-image__overlay-top-right,
.product-image--hover-emersion-x .product-image__overlay-bottom-right,
.product-image--hover-emersion-y .product-image__overlay-top-right,
.product-image--hover-emersion-y .product-image__overlay-bottom-right,
.product-image--hover-emersion-z .product-image__overlay-top-right,
.product-image--hover-emersion-z .product-image__overlay-bottom-right,
.product-image--hover-scale .product-image__overlay-top-right,
.product-image--hover-scale .product-image__overlay-bottom-right {
  opacity: 0;
}

.product-image--hover-emersion-x .product-image__overlay-top-right,
.product-image--hover-emersion-x .product-image__overlay-bottom-right {
  transform: translate3d(100%, 0, 0);
}

.product-image--hover-emersion-y .product-image__overlay-top-right {
  transform: translate3d(0, -100%, 0);
}

.product-image--hover-emersion-y .product-image__overlay-bottom-right {
  transform: translate3d(0, 100%, 0);
}

.product-image--hover-emersion-z .product-image__overlay-top-right {
  transform: translate3d(100%, -100%, 0);
}

.product-image--hover-emersion-z .product-image__overlay-bottom-right {
  transform: translate3d(100%, 100%, 0);
}

.product-image--hover-scale .product-image__overlay-top-right,
.product-image--hover-scale .product-image__overlay-bottom-right {
  transform: scale3d(0.6, 0.6, 1);
}

.product-image--hover-toggle:hover .product-image__overlay-top-right,
.product-image--hover-toggle:hover .product-image__overlay-bottom-right,
.product-image--hover-fade:hover .product-image__overlay-top-right,
.product-image--hover-fade:hover .product-image__overlay-bottom-right,
.product-image--hover-emersion-x:hover .product-image__overlay-top-right,
.product-image--hover-emersion-x:hover .product-image__overlay-bottom-right,
.product-image--hover-emersion-y:hover .product-image__overlay-top-right,
.product-image--hover-emersion-y:hover .product-image__overlay-bottom-right,
.product-image--hover-emersion-z:hover .product-image__overlay-top-right,
.product-image--hover-emersion-z:hover .product-image__overlay-bottom-right,
.product-image--hover-scale:hover .product-image__overlay-top-right,
.product-image--hover-scale:hover .product-image__overlay-bottom-right {
  opacity: 1;
}

.product-image--hover-emersion-x:hover .product-image__overlay-top-right,
.product-image--hover-emersion-x:hover .product-image__overlay-bottom-right,
.product-image--hover-emersion-y:hover .product-image__overlay-top-right,
.product-image--hover-emersion-y:hover .product-image__overlay-bottom-right,
.product-image--hover-emersion-z:hover .product-image__overlay-top-right,
.product-image--hover-emersion-z:hover .product-image__overlay-bottom-right {
  transform: translate3d(0, 0, 0);
}

.product-image--hover-scale:hover .product-image__overlay-top-right,
.product-image--hover-scale:hover .product-image__overlay-bottom-right {
  transform: scale3d(1, 1, 1);
}

@media (min-width: 1025px) and (max-width: 1259px) {

  .js-collection-grid[data-value-lg="2"] [data-promobox-grid-visible*="2"],
  .js-collection-grid[data-value-lg="3"] [data-promobox-grid-visible*="3"],
  .js-collection-grid[data-value-lg="4"] [data-promobox-grid-visible*="4"],
  .js-collection-grid[data-value-lg="6"] [data-promobox-grid-visible*="6"],
  .js-collection-grid[data-value-lg="list"] [data-promobox-grid-visible*="list"] {
    display: block;
  }
}

@media (min-width: 1260px) {

  .js-collection-grid[data-value-xl="2"] [data-promobox-grid-visible*="2"],
  .js-collection-grid[data-value-xl="3"] [data-promobox-grid-visible*="3"],
  .js-collection-grid[data-value-xl="4"] [data-promobox-grid-visible*="4"],
  .js-collection-grid[data-value-xl="6"] [data-promobox-grid-visible*="6"],
  .js-collection-grid[data-value-xl="list"] [data-promobox-grid-visible*="list"] {
    display: block;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection form {
    flex-direction: row !important;
    align-items: start;
    margin-bottom: 40px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection__content {
    align-items: start !important;
    margin-top: 0 !important;
    text-align: left;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection__more-info {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection__collections {
    display: block !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection__title {
    margin-bottom: 12px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection__price {
    margin-bottom: 14px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {

  .collection-grid-list-lg .product-collection__details,
  .collection-grid-list-lg .product-collection__description {
    display: block !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {

  .collection-grid-list-lg .product-collection__buttons,
  .collection-grid-list-lg .product-collection__buttons-section {
    justify-content: start;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-options__section {
    justify-content: start;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg [data-collection-promobox] {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v1.collection-grid-list-lg .product-collection__image {
    width: 370px !important;
    min-width: 370px;
    max-width: 42.5287%;
    margin-right: 30px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection form {
    align-items: stretch;
    margin-bottom: 30px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__image {
    align-self: flex-start;
    width: 195px !important;
    min-width: 195px;
    max-width: 22.4137%;
    margin-right: 20px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__content {
    padding-right: 20px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {

  .collection-grid-list--v2.collection-grid-list-lg .product-collection__price,
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__buttons,
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__reviews {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content {
    display: flex !important;
    width: 185px;
    min-width: 185px;
    max-width: 185px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content .shopify-review-badge {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {

  .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content .shopify-review-starrating,
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content .shopify-review-icon:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content .shopify-review-badge-caption {
    margin-top: 5px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-border {
    display: block !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection form {
    flex-direction: row !important;
    align-items: start;
    margin-bottom: 40px !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection__content {
    align-items: start !important;
    margin-top: 0 !important;
    text-align: left;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection__more-info {
    display: none;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection__collections {
    display: block !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection__title {
    margin-bottom: 12px !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection__price {
    margin-bottom: 14px !important;
  }
}

@media (min-width: 1260px) {

  .collection-grid-list-xl .product-collection__details,
  .collection-grid-list-xl .product-collection__description {
    display: block !important;
  }
}

@media (min-width: 1260px) {

  .collection-grid-list-xl .product-collection__buttons,
  .collection-grid-list-xl .product-collection__buttons-section {
    justify-content: start;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-options__section {
    justify-content: start;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list-xl [data-collection-promobox] {
    display: none !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v1.collection-grid-list-xl .product-collection__image {
    width: 370px !important;
    min-width: 370px;
    max-width: 42.5287%;
    margin-right: 30px;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection form {
    align-items: stretch;
    margin-bottom: 30px !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__image {
    align-self: flex-start;
    width: 195px !important;
    min-width: 195px;
    max-width: 22.4137%;
    margin-right: 20px;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__content {
    padding-right: 20px;
  }
}

@media (min-width: 1260px) {

  .collection-grid-list--v2.collection-grid-list-xl .product-collection__price,
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__buttons,
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__reviews {
    display: none !important;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content {
    display: flex !important;
    width: 185px;
    min-width: 185px;
    max-width: 185px;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content .shopify-review-badge {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
  }
}

@media (min-width: 1260px) {

  .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content .shopify-review-starrating,
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content .shopify-review-icon:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content .shopify-review-badge-caption {
    margin-top: 5px;
  }
}

@media (min-width: 1260px) {
  .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-border {
    display: block !important;
  }
}

.collection-grid-buttons {
  margin-left: -3px;
  margin-right: -3px;
}

.collection-filter-by-price {
  margin-top: -8px;
}

@media (min-width: 1260px) {
  .product-page__container--with-sidebar {
    width: calc(100% - 370px);
  }
}

@media (min-width: 1260px) {
  .product-page__sidebar {
    width: 340px;
    min-width: 340px;
  }
}

@media (min-width: 1260px) {
  .product-page__sidebar--left {
    border-right: solid 1px;
  }
}

@media (min-width: 1260px) {
  .product-page__sidebar--right {
    border-left: solid 1px transparent;
  }
}

@media (min-width: 1260px) {
  .product-gallery__main_arrows--type-2 .product-gallery__main_arrow--type-prev {
    left: -40px;
  }
}

@media (min-width: 1260px) {
  .product-gallery__main_arrows--type-2 .product-gallery__main_arrow--type-next {
    right: -40px;
  }
}

@media (min-width: 1260px) {
  .product-gallery--container {
    max-width: 510px;
  }
}

.product-gallery--layout-1:not(.product-gallery--single) .product-gallery__main {
  width: calc(100% - 80px);
}

.product-gallery--layout-1 .product-gallery__thumbnail {
  min-width: 70px;
  width: 70px;
}

.product-gallery--layout-5:not(.product-gallery--single) .product-gallery__main_spacer {
  width: 33.33%;
}

.popup [data-popup-desktop-top] [data-popup-content] {
  transform: translateY(-100%);
}

.popup-subscription--layout-05 .popup-subscription__close {
  top: 18%;
  right: -30px;
}

.footbar {
  z-index: 3;
}

.notification-cookies__bg {
  transition-property: opacity;
}

.notification-cookies:hover .notification-cookies__bg {
  opacity: 1;
}

.notification-cookies__button-close:hover {
  text-decoration: none;
}

.footbar-product__controls {
  max-width: 70%;
}

.form-input-button--type-01 *:first-child {
  width: calc(65% - 10px);
}

.form-input-button--type-01 *:last-child {
  width: 35%;
}

.form-input-button--type-02 *:first-child {
  width: calc(55% - 10px);
}

.form-input-button--type-02 *:last-child {
  width: 45%;
}

body.fixed-elem .fixed-sidebar {
  display: none !important;
}

.promobox__plate--hovered,
.promobox__content-scale {
  backface-visibility: hidden;
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.promobox__content-scale::before {
  content: '';
  display: block;
  opacity: 0.9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.promobox__content-scale {
  opacity: 0;
  transform: scale3d(0, 0, 0);
}

.promobox:hover .promobox__plate--hovered {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
}

.promobox:hover .promobox__content-scale {
  opacity: 1;
  transform: none;
}

.promobox__border {
  border: solid 1px transparent;
  transition-property: border-color;
  will-change: border-color;
}

.promobox--type-1-2 .promobox__btns,
.promobox--type-1-5 .promobox__btns {
  width: 450px;
}

.promobox--type-1-9 .promobox__btns {
  width: 580px;
}

.promobox--type-1-14 .promobox__btns {
  width: 424px;
}

.menu--vertical {
  opacity: 0;
  pointer-events: none;
}

.menu--vertical.menu--fixed:not(.menu--loaded) {
  visibility: hidden;
}

.menu--vertical .menu__item_icon {
  margin-left: -2px;
}

.menu--open {
  opacity: 1;
  pointer-events: all;
}

.menu-vertical-btn {
  z-index: 1;
}

.menu-vertical-btn__icon-open {
  transition-property: transform;
}

.menu-vertical-btn:not(.menu-vertical-btn--fixed):hover .menu-vertical-btn__icon-open {
  transform: scaleY(1.3);
}

.menu-vertical-btn:not(.menu-vertical-btn--open) .menu-vertical-btn__icon-close,
.menu-vertical-btn--open .menu-vertical-btn__icon-open {
  display: none;
}

.menu-vertical-btn--fixed.menu-vertical-btn--open .menu-vertical-btn__icon-close {
  display: none;
}

.menu-vertical-btn--fixed.menu-vertical-btn--open .menu-vertical-btn__icon-open {
  display: flex;
}

.menu__panel {
  height: 100%;
  min-height: initial !important;
}

.menu.menu--items-visible .menu__panel {
  height: 100% !important;
}

.menu__panel>.menu__item>a {
  height: 100%;
}

.menu__panel:not(.menu__panel--on-a-par)>.menu__item>a>.menu__item_arrow {
  margin-right: -6px;
}

.menu--fixed:not(.menu--items-visible) [data-js-menu-vertical-item="hidden"],
.menu__see-all:not(.menu__see-all--visible),
.menu:not(.menu--fixed) .menu__see-all {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  visibility: hidden !important;
  z-index: -9999 !important;
  pointer-events: none !important;
}

.menu--vertical .menu__panel>.menu__item--has-children>a::after {
  content: '';
  display: block;
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -20px;
}

.menu__see-all i {
  margin-bottom: -1px;
  margin-left: -2px;
}

.menu__megamenu {
  z-index: 1;
}

.menu:not(.menu--vertical) .menu__megamenu,
.menu:not(.menu--vertical) .menu__curtain,
.menu:not(.menu--vertical) .menu__dropdown {
  top: 100%;
  left: 0;
}

.menu--vertical .menu__megamenu,
.menu--vertical .menu__curtain {
  top: 0;
  left: 100%;
}

.menu--vertical .menu__dropdown {
  top: 0;
  left: calc(100% + 20px);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item>a .menu__label {
  order: 1 !important;
}

.menu__megamenu.show,
.menu__curtain.show,
.menu__dropdown.show {
  display: block !important;
}

.menu__megamenu,
.menu__curtain {
  width: 100%;
}

.menu__megamenu {
  opacity: 0;
}

.menu__megamenu.animate {
  will-change: opacity;
  transition-property: opacity;
}

.menu__megamenu.visible {
  opacity: 1;
}

.menu__dropdown>* {
  opacity: 0;
}

.menu__dropdown.animate>* {
  will-change: opacity;
  transition-property: opacity;
}

.menu__dropdown.visible>* {
  opacity: 1;
}

.menu__dropdown,
.menu__list--styled .menu__list {
  width: 220px;
}

.menu:not(.menu--vertical) .menu__panel:not(.menu__panel--on-a-par) .menu__dropdown,
.menu__panel--on-a-par .menu__list--styled .menu__list {
  margin-left: -10px;
}

.menu__list--styled .menu__list {
  left: 100%;
  top: -15px;
  z-index: 1;
}

.menu__list--styled .menu__list::before {
  position: absolute;
  display: block;
  content: '';
  width: 10px;
  height: 100%;
  top: 0;
  left: 0;
}

.menu__panel--on-a-par .menu__list--styled .menu__list {
  left: calc(100% + 10px);
}

.menu__panel--on-a-par .menu__list--styled .menu__list::before {
  left: -10px;
}

.menu__list--styled {
  margin-left: -5px;
  margin-right: -5px;
}

.menu__list--styled i {
  margin-right: -5px;
}

.menu__list--styled .menu__item:not(:hover)>.menu__list {
  display: none !important;
}

.menu__promobox {
  margin-bottom: -30px;
}

.menu__products {
  margin-bottom: -20px;
}

.menu__group {
  margin-bottom: -30px;
}

.menu__group--offset-small {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
}

.menu__preview-image {
  z-index: 1;
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}

.menu__preview-image img {
  z-index: 2;
}

.menu__item:hover>a>.menu__preview-image {
  opacity: 1;
}

.menu__preview-image_image {
  width: 174px;
}

.collections {
  margin-bottom: 25px;
}

.collections__sidebar--width-md {
  width: 300px;
}

.collections__sidebar--width-md~.collections__body {
  max-width: calc(100% - 300px);
}

.collection-control__sort-by-wrapper,
.collection-control__view-wrapper {
  z-index: 3;
}

.collection-sidebar-type-1 .collection-sidebar {
  margin-top: -14px;
}

.collection-sidebar-type-2 .collection-sidebar__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.collection-sidebar-type-2 .collection-sidebar-section--content-current-filters,
.collection-sidebar-type-2 .collection-sidebar-section--content-info {
  display: none !important;
}

.collection-sidebar-type-2 .collection-sidebar-section {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.collection-sidebar-type-2 .collection-sidebar-section__head {
  border: solid 1px transparent;
  padding: 7px 9px 7px 15px;
  white-space: nowrap;
}

.collection-sidebar-type-2 .collection-sidebar-section__accordion {
  position: absolute;
  width: 270px;
  left: 0;
  top: calc(100% + 8px);
  z-index: 1;
}

.collection-sidebar-type-2 .collection-filters__form-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.collection-sidebar-type-2 .collection-filters__filters {
  max-height: 360px;
  overflow: auto;
}

.collection-sidebar-type-2 .collection-filters__header {
  position: sticky;
  top: 0;
  left: 0;
  padding: 13px 20px 12px;
  border-bottom: solid 1px transparent;
  z-index: 1;
}

.collection-sidebar-type-2 .collection-filters__content {
  padding: 20px 20px 0 20px;
}

.carousel__prev,
.carousel__next {
  opacity: 0;
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.carousel__prev {
  left: -45px;
  transform: translate3d(-20px, 0, 0);
}

.carousel__next {
  right: -45px;
  transform: translate3d(20px, 0, 0);
}

.carousel__prev-placeholder,
.carousel__next-placeholder {
  width: 45px;
}

.carousel__prev-placeholder {
  left: -45px;
}

.carousel__next-placeholder {
  right: -45px;
}

.carousel--fullwidth .carousel__prev {
  left: 20px;
}

.carousel--fullwidth .carousel__next {
  right: 20px;
}

.carousel:hover .carousel__prev,
.carousel:hover .carousel__next {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.carousel .slick-dots li {
  margin-left: 7px;
  margin-right: 7px;
}

.carousel .slick-dots li.slick-active {
  margin-left: 5px;
  margin-right: 5px;
}

@media (min-width: 1260px) {
  .carousel-articles__item--layout-01 .carousel-articles__image {
    width: 48% !important;
    min-width: 48%;
    max-width: 270px;
  }
}

.gallery__item-layer {
  transition-property: opacity;
}

.gallery__item:hover .gallery__item-layer {
  opacity: 1;
}

.blogs {
  margin-bottom: 25px;
}

.blogs__sidebar {
  margin-top: -8px;
}

@media (min-width: 1260px) {
  .blogs__sidebar--width-md {
    width: 400px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .blogs__sidebar--width-md {
    width: 332px;
  }
}

.blogs__sidebar.blogs__sidebar--offset-bottom {
  margin-bottom: -25px;
}

.post:hover .post__hidden-info {
  display: block !important;
  z-index: 1;
}

@media (min-width: 1260px) {
  .article__body--max-width {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1260px) {
  .article__body--with-sidebar {
    max-width: 60%;
  }
}

@media (min-width: 1260px) {
  .article__sidebar--width-md {
    width: 400px;
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .article__sidebar--width-md {
    width: 332px;
  }
}

.article__sidebar.article__sidebar--offset-bottom {
  margin-bottom: -25px;
}

.instafeed__curtain {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}

.instafeed__item:hover .instafeed__curtain {
  opacity: 1;
}

.lookbook__picker-circle::before {
  transition-property: transform, opacity;
  will-change: transform, opacity;
}

.lookbook__picker:hover .lookbook__picker-circle::before,
.lookbook__picker-circle.open::before {
  transform: scale(2);
  opacity: 0.9;
}

.lookbook__picker-circle.open+* .lookbook__product {
  display: block !important;
}

.lookbook__product {
  width: 160px;
  z-index: 1;
}

@media (min-width: 1260px) {
  .password-page-content {
    min-height: 400px;
  }
}

@media (min-width: 1260px) {
  .password-page-content__subscription--width-limit {
    max-width: 280px;
  }
}

.password-page-content__image {
  max-width: 480px;
}

.footer--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.footer__content--boxed-sm {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.footer__back-to-top {
  width: 30px;
  height: 30px;
  top: 100%;
  right: 0;
  z-index: 4;
}

.footer__back-to-top.show {
  top: auto;
  bottom: 16px;
}

.footer__back-to-top[data-bind] {
  transform: rotate(180deg);
}

[dir='rtl'] .btn--animated .btn__icon {
  transform: translate3d(40px, 45px, 0);
}

[dir='rtl'] .btn--animated .btn__text {
  transform: translate3d(12px, 0, 0);
}

[dir='rtl'] .btn--animated:hover .btn__icon,
[dir='rtl'] .btn--animated:hover .btn__text {
  transform: translate3d(0, 0, 0);
}

[dir='rtl'] .header__logo--center+.header__sidebar {
  padding-left: 15px;
  padding-right: 0;
  left: 0;
  right: auto;
}

[dir='rtl'] .header--type-5 .header__search-form,
[dir='rtl'] .header--type-6 .header__search-form {
  left: auto;
  right: 50%;
  margin-left: 0 !important;
  margin-right: -24%;
}

[dir='rtl'] .menu__panel:not(.menu__panel--on-a-par)>[dir='rtl'] .menu__item>a>i {
  margin-left: -6px;
  margin-right: 0;
}

[dir='rtl'] .menu__panel--bordered>.menu__item>.menu__megamenu::before,
[dir='rtl'] .menu__panel--bordered>.menu__item>.menu__dropdown::before {
  left: auto;
  right: 0;
}

[dir='rtl'] .menu--vertical .menu__panel:not(.menu__panel--on-a-par)>.menu__item>a>.menu__item_arrow {
  margin-left: -6px !important;
  transform: rotate(180deg);
}

[dir='rtl'] .menu:not(.menu--vertical) .menu__megamenu,
[dir='rtl'] .menu:not(.menu--vertical) .menu__dropdown {
  left: auto;
  right: 0;
}

[dir='rtl'] .menu--vertical .menu__megamenu,
[dir='rtl'] .menu--vertical .menu__curtain {
  left: auto !important;
  right: 100% !important;
}

[dir='rtl'] .menu--vertical .menu__dropdown {
  left: auto !important;
  right: calc(100% + 20px) !important;
}

[dir='rtl'] .menu--vertical .menu__panel>.menu__item--has-children>a::after {
  left: -20px;
  right: auto;
}

[dir='rtl'] .menu__panel--on-a-par [dir='rtl'] .menu__dropdown,
[dir='rtl'] .menu__panel--on-a-par [dir='rtl'] .menu__list--styled [dir='rtl'] .menu__list {
  margin-left: 0;
  margin-right: -10px;
}

[dir='rtl'] .menu__list--styled .menu__list {
  left: auto;
  right: 100%;
}

[dir='rtl'] .menu__list--styled .menu__list::before {
  left: auto;
  right: 0;
}

[dir='rtl'] .menu__panel--on-a-par [dir='rtl'] .menu__list--styled [dir='rtl'] .menu__list {
  right: calc(100% + 10px);
}

[dir='rtl'] .menu__panel--on-a-par [dir='rtl'] .menu__list--styled [dir='rtl'] .menu__list::before {
  right: -10px;
}

[dir='rtl'] .menu__list--styled i {
  margin-left: -5px;
  margin-right: 0;
}

[dir='rtl'] .collection-sidebar-type-2 .collection-sidebar-section {
  margin-left: 10px;
  margin-right: 0;
}

@media (min-width: 1260px) {
  [dir='rtl'] .collection-grid-list-xl .product-collection__image {
    margin-left: 30px;
    margin-right: 0;
  }
}

[dir='rtl'] .carousel__prev {
  left: auto;
  right: -45px;
  transform: translate3d(20px, 0, 0);
}

[dir='rtl'] .carousel__next {
  left: -45px;
  right: auto;
  transform: translate3d(-20px, 0, 0);
}

[dir='rtl'] .carousel__prev-placeholder {
  left: auto;
  right: -45px;
}

[dir='rtl'] .carousel__next-placeholder {
  left: -45px;
  right: auto;
}

[dir='rtl'] .product-options__value--circle:not(:last-child),
[dir='rtl'] .product-options__value--square:not(:last-child),
[dir='rtl'] .product-options__value--large-text:not(:last-child),
[dir='rtl'] .product-options__value--text:not(:last-child) {
  margin-left: 10px;
  margin-right: 0;
}

[dir='rtl'] .product-options--type-footbar>*:not(:last-child) {
  margin-left: 25px;
  margin-right: 0;
}

[dir='rtl'] .tabs[data-type="horizontal"] [dir='rtl'] .tabs__head[dir='rtl'] .tabs__head--slider [dir='rtl'] .tabs__nav--prev {
  left: auto;
  right: -2px;
}

[dir='rtl'] .tabs[data-type="horizontal"] [dir='rtl'] .tabs__head[dir='rtl'] .tabs__head--slider [dir='rtl'] .tabs__nav--next {
  left: -2px;
  right: auto;
}

[dir='rtl'] .tabs[data-type="mobile"] [dir='rtl'] .tabs__body>div>span i {
  left: -6px;
  right: auto;
}

[dir='rtl'] .product-fixed-sizebar {
  transform: translate3d(calc(-100% + 40px), 0, 0);
}

[dir='rtl'] .footer__back-to-top {
  left: 0;
  right: auto;
}

@media (min-width: 1260px) {
  [dir='rtl'] .collection-grid-list-xl .product-collection__content {
    text-align: right;
  }
}

[dir='rtl'] .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content .shopify-review-starrating,
[dir='rtl'] .collection-grid-list--v2.collection-grid-list-lg .product-collection__list-v2-content .shopify-review-icon:last-child,
[dir='rtl'] .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content .shopify-review-starrating,
[dir='rtl'] .collection-grid-list--v2.collection-grid-list-xl .product-collection__list-v2-content .shopify-review-icon:last-child {
  margin-left: 0;
}

.header__btn-cart span,
.header__btn-currency span {
  font-size: var(--body-size);
  line-height: 21px;
}

.header__btn-search span {
  line-height: 14px;
}

.header__btn-search .icon {
  min-height: 21px;
}

.menu {
  font-family: var(--menu-family);
  font-style: var(--menu-style);
  font-weight: var(--menu-weight);
}

.menu-vertical-btn {
  font-size: var(--vertical-menu-button-size);
  line-height: var(--vertical-menu-button-line-height);
  letter-spacing: var(--vertical-menu-button-letter-spacing);
  font-weight: var(--vertical-menu-button-weight);
}

.menu__list--styled .menu__item>a {
  font-size: var(--menu-list-size);
  line-height: var(--menu-list-line-height);
  letter-spacing: var(--menu-list-letter-spacing);
  font-weight: var(--menu-list-weight);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item>a {
  font-size: var(--menu-size);
  line-height: var(--menu-line-height);
  letter-spacing: var(--menu-letter-spacing);
  font-weight: var(--menu-weight);
  text-transform: var(--menu-transform);
}

.menu--vertical .menu__panel>.menu__item>a {
  font-size: var(--vertical-menu-size);
  line-height: var(--vertical-menu-line-height);
  letter-spacing: var(--vertical-menu-letter-spacing);
  font-weight: var(--vertical-menu-weight);
  text-transform: var(--vertical-menu-transform);
}

.menu__title {
  font-size: var(--menu-title-size);
  line-height: var(--menu-title-line-height);
  letter-spacing: var(--menu-title-letter-spacing);
  font-weight: var(--menu-title-weight);
  text-transform: var(--menu-title-transform);
}

.product-gallery__control {
  font-size: 10px;
}

.product-list__title>* {
  font-size: var(--h4-size);
}

.product-page-info__title>*,
.one-product-info__title>* {
  font-size: var(--product-page-title-size);
  line-height: var(--product-page-title-line-height);
}

@media (min-width: 1260px) {
  .collection-grid-list-xl .product-collection__title>* {
    font-size: var(--h4-size);
    line-height: var(--h4-line-height);
  }
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-list-lg .product-collection__title>* {
    font-size: var(--h4-size);
    line-height: var(--h4-line-height);
  }
}

.product-options--type-footbar .product-options__value--large-text {
  line-height: 24px;
}

.search input {
  font-size: var(--h3-size);
  line-height: var(--h3-line-height);
}

.search form label .icon {
  width: 30px;
  min-width: 30px;
  min-height: 45px;
}

a:hover {
  color: var(--theme-link-act-c);
}

a.link-unstyled:hover {
  color: initial;
}

.link-revert:hover {
  color: var(--theme);
}

.border-hover:hover {
  border-color: var(--theme) !important;
}

input[type="text"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
textarea:hover,
select:hover {
  background-color: var(--input-f-bg);
  border: var(--input-f-bd);
  color: var(--input-f-c);
}

input[type="text"]:hover::-webkit-input-placeholder,
input[type="number"]:hover::-webkit-input-placeholder,
input[type="email"]:hover::-webkit-input-placeholder,
input[type="password"]:hover::-webkit-input-placeholder,
input[type="search"]:hover::-webkit-input-placeholder,
input[type="tel"]:hover::-webkit-input-placeholder,
textarea:hover::-webkit-input-placeholder,
select:hover::-webkit-input-placeholder {
  color: var(--input-f-c);
}

input[type="text"]:hover::-moz-placeholder,
input[type="number"]:hover::-moz-placeholder,
input[type="email"]:hover::-moz-placeholder,
input[type="password"]:hover::-moz-placeholder,
input[type="search"]:hover::-moz-placeholder,
input[type="tel"]:hover::-moz-placeholder,
textarea:hover::-moz-placeholder,
select:hover::-moz-placeholder {
  color: var(--input-f-c);
}

input[type="text"]:hover:-ms-input-placeholder,
input[type="number"]:hover:-ms-input-placeholder,
input[type="email"]:hover:-ms-input-placeholder,
input[type="password"]:hover:-ms-input-placeholder,
input[type="search"]:hover:-ms-input-placeholder,
input[type="tel"]:hover:-ms-input-placeholder,
textarea:hover:-ms-input-placeholder,
select:hover:-ms-input-placeholder {
  color: var(--input-f-c);
}

input[type="text"]:hover:-moz-placeholder,
input[type="number"]:hover:-moz-placeholder,
input[type="email"]:hover:-moz-placeholder,
input[type="password"]:hover:-moz-placeholder,
input[type="search"]:hover:-moz-placeholder,
input[type="tel"]:hover:-moz-placeholder,
textarea:hover:-moz-placeholder,
select:hover:-moz-placeholder {
  color: var(--input-f-c);
}

.input-checkbox span:hover {
  color: var(--theme);
}

.input-boolean:hover input~span {
  color: var(--theme);
}

.input-quantity [data-control]:hover {
  border-color: var(--theme) !important;
}

.btn-link:hover {
  color: var(--theme-primary);
}

.spr-review-reportreview:hover {
  color: var(--theme-primary);
}

.dropdown {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.dropdown span {
  color: var(--theme3);
}

.dropdown span:not([disabled]):hover,
.dropdown a:not([disabled]):hover {
  color: var(--theme);
}

.dropdown:not([data-dropdown-unselected]) .selected,
.dropdown:not([data-dropdown-unselected]) .active {
  background-color: var(--theme4);
}

.social-share__item:hover {
  color: #ffffff;
}

.social-share__item:hover .icon {
  fill: #ffffff;
}

.breadcrumbs a:hover {
  color: var(--theme);
}

.menu:not(.menu--vertical) .menu__megamenu,
.menu:not(.menu--vertical) .menu__curtain {
  top: calc(100% + var(--header-offset-bottom-bd));
}

.menu:not(.menu--vertical),
.menu-vertical-btn {
  height: calc(100% + var(--header-offset-bottom-bd));
}

.menu--vertical {
  margin-top: var(--header-offset-bottom-bd);
}

.header__line--colorize-1 {
  background-color: var(--header-line-1-bg);
  border-bottom: var(--header-line-1-bd);
}

.header__line--colorize-2 {
  background-color: var(--header-line-2-bg);
  border-bottom: var(--header-line-2-bd);
}

.header__line-additional_button--register:hover,
.header__line-additional_button--login:hover {
  color: var(--header-additional-line-account-h-c);
}

.header__line-additional_button--services:hover {
  color: var(--header-additional-line-services-h-c);
}

.header__line-additional .header__btn-currency,
.header__line-additional .header__btn-currency:visited,
.header__line-additional .header__btn-currency:active,
.header__line-additional .header__btn-currency:link,
.header__line-additional .header__btn-language,
.header__line-additional .header__btn-language:visited,
.header__line-additional .header__btn-language:active,
.header__line-additional .header__btn-language:link {
  color: var(--header-additional-line-currency-languages-c);
}

.header__line-additional .header__btn-currency:hover,
.header__line-additional .header__btn-language:hover {
  color: var(--header-additional-line-currency-languages-h-c);
}

.header__line-additional .header__btn-currency>i .icon,
.header__line-additional .header__btn-language>i .icon {
  fill: var(--header-additional-line-currency-languages-c);
}

.header__line-additional .header__btn-currency:hover>i .icon,
.header__line-additional .header__btn-language:hover>i .icon {
  fill: var(--header-additional-line-currency-languages-h-c);
}

.header__search-form input[type='search']:hover {
  border: var(--header-search-input-f-bd);
}

.header__search-form label .icon {
  fill: var(--header-search-input-c);
}

.header__line--colorize-1 .header__counter {
  color: var(--header-counter-line-1-d-c);
}

.header__line--colorize-1 .header__btn-services,
.header__line--colorize-1 .header__btn-services:visited,
.header__line--colorize-1 .header__btn-services:active,
.header__line--colorize-1 .header__btn-services:link,
.header__line--colorize-1 .header__btn-account,
.header__line--colorize-1 .header__btn-account:visited,
.header__line--colorize-1 .header__btn-account:active,
.header__line--colorize-1 .header__btn-account:link,
.header__line--colorize-1 .header__btn-wishlist,
.header__line--colorize-1 .header__btn-wishlist:visited,
.header__line--colorize-1 .header__btn-wishlist:active,
.header__line--colorize-1 .header__btn-wishlist:link,
.header__line--colorize-1 .header__btn-compare,
.header__line--colorize-1 .header__btn-compare:visited,
.header__line--colorize-1 .header__btn-compare:active,
.header__line--colorize-1 .header__btn-compare:link,
.header__line--colorize-1 .header__btn-currency,
.header__line--colorize-1 .header__btn-currency:visited,
.header__line--colorize-1 .header__btn-currency:active,
.header__line--colorize-1 .header__btn-currency:link,
.header__line--colorize-1 .header__btn-language,
.header__line--colorize-1 .header__btn-language:visited,
.header__line--colorize-1 .header__btn-language:active,
.header__line--colorize-1 .header__btn-language:link {
  color: var(--header-btns-line-1-d-c);
}

.header__line--colorize-1 .header__btn-services:hover,
.header__line--colorize-1 .header__btn-account:hover,
.header__line--colorize-1 .header__btn-wishlist:hover,
.header__line--colorize-1 .header__btn-compare:hover,
.header__line--colorize-1 .header__btn-currency:hover,
.header__line--colorize-1 .header__btn-language:hover {
  color: var(--header-btns-line-1-d-h-c);
}

.header__line--colorize-1 .header__btn-services>i .icon,
.header__line--colorize-1 .header__btn-account>i .icon,
.header__line--colorize-1 .header__btn-wishlist>i .icon,
.header__line--colorize-1 .header__btn-compare>i .icon,
.header__line--colorize-1 .header__btn-currency>i .icon,
.header__line--colorize-1 .header__btn-language>i .icon {
  fill: var(--header-btns-line-1-d-c);
}

.header__line--colorize-1 .header__btn-services:hover>i .icon,
.header__line--colorize-1 .header__btn-account:hover>i .icon,
.header__line--colorize-1 .header__btn-wishlist:hover>i .icon,
.header__line--colorize-1 .header__btn-compare:hover>i .icon,
.header__line--colorize-1 .header__btn-currency:hover>i .icon,
.header__line--colorize-1 .header__btn-language:hover>i .icon {
  fill: var(--header-btns-line-1-d-h-c);
}

.header__line--colorize-1 .header__btn-wishlist:hover .header__counter,
.header__line--colorize-1 .header__btn-compare:hover .header__counter {
  color: var(--header-counter-line-1-d-h-c);
}

.header__line--colorize-1 .header__select-currency>i .icon,
.header__line--colorize-1 .header__ly-languages-switcher>i .icon {
  fill: var(--header-btns-line-1-d-c);
}

.header__line--colorize-1 .header__select-currency:hover select,
.header__line--colorize-1 .header__ly-languages-switcher:hover select {
  color: var(--header-btns-line-1-d-h-c);
}

.header__line--colorize-1 .header__select-currency:hover>i .icon,
.header__line--colorize-1 .header__ly-languages-switcher:hover>i .icon {
  fill: var(--header-btns-line-1-d-h-c);
}

.header__line--colorize-1 .header__btn-cart,
.header__line--colorize-1 .header__btn-cart:visited,
.header__line--colorize-1 .header__btn-cart:active,
.header__line--colorize-1 .header__btn-cart:link {
  color: var(--header-btn-cart-line-1-text-d-c);
}

.header__line--colorize-1 .header__btn-cart:hover {
  color: var(--header-btn-cart-line-1-d-h-c);
}

.header__line--colorize-1 .header__btn-cart>i .icon {
  fill: var(--header-btn-cart-line-1-d-c);
}

.header__line--colorize-1 .header__btn-cart:hover>i .icon {
  fill: var(--header-btns-line-1-d-h-c);
}

.header__line--colorize-1 .header__btn-cart:hover .header__counter {
  color: var(--header-btn-cart-line-1-text-d-c);
}

.header__line--colorize-1 .header__btn-search,
.header__line--colorize-1 .header__btn-search:visited,
.header__line--colorize-1 .header__btn-search:active,
.header__line--colorize-1 .header__btn-search:link {
  color: var(--header-btn-search-line-1-d-c);
}

.header__line--colorize-1 .header__btn-search:hover {
  color: var(--header-btn-search-line-1-d-h-c);
}

.header__line--colorize-1 .header__btn-search>i .icon {
  fill: var(--header-btn-search-line-1-d-c);
}

.header__line--colorize-1 .header__btn-search:hover>i .icon {
  fill: var(--header-btn-search-line-1-d-h-c);
}

.header__line--colorize-2 .header__counter {
  color: var(--header-counter-line-2-d-c);
}

.header__line--colorize-2 .header__btn-services,
.header__line--colorize-2 .header__btn-services:visited,
.header__line--colorize-2 .header__btn-services:active,
.header__line--colorize-2 .header__btn-services:link,
.header__line--colorize-2 .header__btn-account,
.header__line--colorize-2 .header__btn-account:visited,
.header__line--colorize-2 .header__btn-account:active,
.header__line--colorize-2 .header__btn-account:link,
.header__line--colorize-2 .header__btn-wishlist,
.header__line--colorize-2 .header__btn-wishlist:visited,
.header__line--colorize-2 .header__btn-wishlist:active,
.header__line--colorize-2 .header__btn-wishlist:link,
.header__line--colorize-2 .header__btn-compare,
.header__line--colorize-2 .header__btn-compare:visited,
.header__line--colorize-2 .header__btn-compare:active,
.header__line--colorize-2 .header__btn-compare:link,
.header__line--colorize-2 .header__btn-currency,
.header__line--colorize-2 .header__btn-currency:visited,
.header__line--colorize-2 .header__btn-currency:active,
.header__line--colorize-2 .header__btn-currency:link,
.header__line--colorize-2 .header__btn-language,
.header__line--colorize-2 .header__btn-language:visited,
.header__line--colorize-2 .header__btn-language:active,
.header__line--colorize-2 .header__btn-language:link {
  color: var(--header-btns-line-2-d-c);
}

.header__line--colorize-2 .header__btn-services:hover,
.header__line--colorize-2 .header__btn-account:hover,
.header__line--colorize-2 .header__btn-wishlist:hover,
.header__line--colorize-2 .header__btn-compare:hover,
.header__line--colorize-2 .header__btn-currency:hover,
.header__line--colorize-2 .header__btn-language:hover {
  color: var(--header-btns-line-2-d-h-c);
}

.header__line--colorize-2 .header__btn-services>i .icon,
.header__line--colorize-2 .header__btn-account>i .icon,
.header__line--colorize-2 .header__btn-wishlist>i .icon,
.header__line--colorize-2 .header__btn-compare>i .icon,
.header__line--colorize-2 .header__btn-currency>i .icon,
.header__line--colorize-2 .header__btn-language>i .icon {
  fill: var(--header-btns-line-2-d-c);
}

.header__line--colorize-2 .header__btn-services:hover>i .icon,
.header__line--colorize-2 .header__btn-account:hover>i .icon,
.header__line--colorize-2 .header__btn-wishlist:hover>i .icon,
.header__line--colorize-2 .header__btn-compare:hover>i .icon,
.header__line--colorize-2 .header__btn-currency:hover>i .icon,
.header__line--colorize-2 .header__btn-language:hover>i .icon {
  fill: var(--header-btns-line-2-d-h-c);
}

.header__line--colorize-2 .header__btn-wishlist:hover .header__counter,
.header__line--colorize-2 .header__btn-compare:hover .header__counter {
  color: var(--header-counter-line-2-d-h-c);
}

.header__line--colorize-2 .header__select-currency>i .icon,
.header__line--colorize-2 .header__ly-languages-switcher>i .icon {
  fill: var(--header-btns-line-2-d-c);
}

.header__line--colorize-2 .header__select-currency:hover select,
.header__line--colorize-2 .header__ly-languages-switcher:hover select {
  color: var(--header-btns-line-2-d-h-c);
}

.header__line--colorize-2 .header__select-currency:hover>i .icon,
.header__line--colorize-2 .header__ly-languages-switcher:hover>i .icon {
  fill: var(--header-btns-line-2-d-h-c);
}

.header__line--colorize-2 .header__btn-cart,
.header__line--colorize-2 .header__btn-cart:visited,
.header__line--colorize-2 .header__btn-cart:active,
.header__line--colorize-2 .header__btn-cart:link {
  color: var(--header-btn-cart-line-2-text-d-c);
}

.header__line--colorize-2 .header__btn-cart:hover {
  color: var(--header-btn-cart-line-2-d-h-c);
}

.header__line--colorize-2 .header__btn-cart>i .icon {
  fill: var(--header-btn-cart-line-2-d-c);
}

.header__line--colorize-2 .header__btn-cart:hover>i .icon {
  fill: var(--header-btns-line-2-d-h-c);
}

.header__line--colorize-2 .header__btn-cart:hover .header__counter {
  color: var(--header-btn-cart-line-2-text-d-c);
}

.header__line--colorize-2 .header__btn-search,
.header__line--colorize-2 .header__btn-search:visited,
.header__line--colorize-2 .header__btn-search:active,
.header__line--colorize-2 .header__btn-search:link {
  color: var(--header-btn-search-line-2-d-c);
}

.header__line--colorize-2 .header__btn-search:hover {
  color: var(--header-btn-search-line-2-d-h-c);
}

.header__line--colorize-2 .header__btn-search>i .icon {
  fill: var(--header-btn-search-line-2-d-c);
}

.header__line--colorize-2 .header__btn-search:hover>i .icon {
  fill: var(--header-btn-search-line-2-d-h-c);
}

.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item>a,
.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item>a:visited,
.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item>a:active,
.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item>a:link {
  color: var(--header-menu-sticky-c);
}

.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item>a .icon {
  fill: var(--header-menu-sticky-c);
}

.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a,
.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a:visited,
.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a:active,
.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a:link {
  color: var(--header-menu-sticky-h-c);
}

.header--sticky-type-slim .header__content--sticky .menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a .icon {
  fill: var(--header-menu-sticky-h-c);
}

.header--sticky-type-slim .header__content--sticky .header__btn-wishlist,
.header--sticky-type-slim .header__content--sticky .header__btn-wishlist:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-wishlist:active,
.header--sticky-type-slim .header__content--sticky .header__btn-wishlist:link,
.header--sticky-type-slim .header__content--sticky .header__btn-compare,
.header--sticky-type-slim .header__content--sticky .header__btn-compare:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-compare:active,
.header--sticky-type-slim .header__content--sticky .header__btn-compare:link,
.header--sticky-type-slim .header__content--sticky .header__btn-cart,
.header--sticky-type-slim .header__content--sticky .header__btn-cart:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-cart:active,
.header--sticky-type-slim .header__content--sticky .header__btn-cart:link,
.header--sticky-type-slim .header__content--sticky .header__btn-account,
.header--sticky-type-slim .header__content--sticky .header__btn-account:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-account:active,
.header--sticky-type-slim .header__content--sticky .header__btn-account:link,
.header--sticky-type-slim .header__content--sticky .header__btn-search,
.header--sticky-type-slim .header__content--sticky .header__btn-search:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-search:active,
.header--sticky-type-slim .header__content--sticky .header__btn-search:link,
.header--sticky-type-slim .header__content--sticky .header__currency,
.header--sticky-type-slim .header__content--sticky .header__currency:visited,
.header--sticky-type-slim .header__content--sticky .header__currency:active,
.header--sticky-type-slim .header__content--sticky .header__currency:link,
.header--sticky-type-slim .header__content--sticky .header__select-currency,
.header--sticky-type-slim .header__content--sticky .header__select-currency:visited,
.header--sticky-type-slim .header__content--sticky .header__select-currency:active,
.header--sticky-type-slim .header__content--sticky .header__select-currency:link,
.header--sticky-type-slim .header__content--sticky .header__btn-services,
.header--sticky-type-slim .header__content--sticky .header__btn-services:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-services:active,
.header--sticky-type-slim .header__content--sticky .header__btn-services:link,
.header--sticky-type-slim .header__content--sticky .header__btn-language,
.header--sticky-type-slim .header__content--sticky .header__btn-language:visited,
.header--sticky-type-slim .header__content--sticky .header__btn-language:active,
.header--sticky-type-slim .header__content--sticky .header__btn-language:link {
  color: var(--header-btns-sticky-c);
}

.header--sticky-type-slim .header__content--sticky .header__btn-wishlist:hover,
.header--sticky-type-slim .header__content--sticky .header__btn-compare:hover,
.header--sticky-type-slim .header__content--sticky .header__btn-cart:hover,
.header--sticky-type-slim .header__content--sticky .header__btn-account:hover,
.header--sticky-type-slim .header__content--sticky .header__btn-search:hover,
.header--sticky-type-slim .header__content--sticky .header__currency:hover,
.header--sticky-type-slim .header__content--sticky .header__select-currency:hover,
.header--sticky-type-slim .header__content--sticky .header__btn-services:hover,
.header--sticky-type-slim .header__content--sticky .header__btn-language:hover {
  color: var(--header-btns-sticky-h-c);
}

.header--sticky-type-slim .header__content--sticky .header__btn-wishlist>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-compare>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-cart>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-account>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-search>i .icon,
.header--sticky-type-slim .header__content--sticky .header__currency>i .icon,
.header--sticky-type-slim .header__content--sticky .header__select-currency>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-services>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-language>i .icon {
  fill: var(--header-btns-sticky-c);
}

.header--sticky-type-slim .header__content--sticky .header__btn-wishlist:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-compare:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-cart:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-account:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-search:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__currency:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__select-currency:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-services:hover>i .icon,
.header--sticky-type-slim .header__content--sticky .header__btn-language:hover>i .icon {
  fill: var(--header-btns-sticky-h-c);
}

.header--sticky-type-slim .header__content--sticky .header__btn-wishlist .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-compare .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-cart .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-account .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-search .header__counter,
.header--sticky-type-slim .header__content--sticky .header__currency .header__counter,
.header--sticky-type-slim .header__content--sticky .header__select-currency .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-services .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-language .header__counter {
  color: var(--header-btns-sticky-c);
}

.header--sticky-type-slim .header__content--sticky .header__btn-wishlist:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-compare:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-cart:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-account:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-search:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__currency:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__select-currency:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-services:hover .header__counter,
.header--sticky-type-slim .header__content--sticky .header__btn-language:hover .header__counter {
  color: var(--header-btns-sticky-h-c);
}

.menu-vertical-btn {
  background-color: var(--header-menu-vertical-button-bg);
  color: var(--header-menu-vertical-button-c);
}

.menu-vertical-btn .icon {
  fill: var(--header-menu-vertical-button-c);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item>a,
.menu:not(.menu--vertical) .menu__panel>.menu__item>a:visited,
.menu:not(.menu--vertical) .menu__panel>.menu__item>a:active,
.menu:not(.menu--vertical) .menu__panel>.menu__item>a:link {
  color: var(--header-menu-d-c);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item>a .icon {
  fill: var(--header-menu-d-c);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a {
  background-color: var(--header-menu-h-bg);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a,
.menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a:visited,
.menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a:active,
.menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a:link {
  color: var(--header-menu-h-c);
}

.menu:not(.menu--vertical) .menu__panel>.menu__item:hover>a .icon {
  fill: var(--header-menu-h-c);
}

.menu--vertical {
  background-color: var(--header-menu-vertical-d-bg);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.menu--vertical.menu--fixed:not(.menu--items-visible) {
  border-bottom: var(--header-menu-vertical-d-bd);
  border-left: var(--header-menu-vertical-d-bd);
  border-right: var(--header-menu-vertical-d-bd);
}

.menu--vertical .menu__panel>.menu__item>a,
.menu--vertical .menu__panel>.menu__item>a:visited,
.menu--vertical .menu__panel>.menu__item>a:active,
.menu--vertical .menu__panel>.menu__item>a:link {
  color: var(--header-menu-vertical-d-c);
}

.menu--vertical .menu__panel>.menu__item>a .icon {
  fill: var(--header-menu-vertical-icon-c);
}

.menu--vertical .menu__panel>.menu__item:hover>a,
.menu--vertical .menu__panel>.menu__item:hover>a:visited,
.menu--vertical .menu__panel>.menu__item:hover>a:active,
.menu--vertical .menu__panel>.menu__item:hover>a:link {
  color: var(--header-menu-vertical-h-c);
}

.menu--vertical .menu__panel>.menu__item:hover>a .icon {
  fill: var(--header-menu-vertical-icon-h-c);
}

.menu--vertical {
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.menu--vertical.menu--fixed:not(.menu--items-visible) {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.menu--vertical .menu__panel>.menu__item>a .menu__item_arrow .icon {
  fill: var(--theme6);
}

.menu__see-all {
  color: var(--theme-c);
}

.menu__see-all .icon {
  fill: var(--theme-c);
}

.menu__curtain,
.menu__dropdown,
.menu__list--styled .menu__list {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.menu__list--styled .menu__item>a,
.menu__list--styled .menu__item>a:visited,
.menu__list--styled .menu__item>a:active,
.menu__list--styled .menu__item>a:link {
  color: var(--header-menu-list-c);
}

.menu__list--styled .menu__item>a .icon {
  fill: var(--header-menu-list-c);
}

.menu__list--styled .menu__item:hover {
  background-color: var(--header-menu-list-h-bg);
}

.menu__list--styled .menu__item:hover>a,
.menu__list--styled .menu__item:hover>a:visited,
.menu__list--styled .menu__item:hover>a:active,
.menu__list--styled .menu__item:hover>a:link {
  color: var(--header-menu-list-h-c);
}

.menu__list--styled .menu__item:hover>a .icon {
  fill: var(--header-menu-list-h-c);
}

.menu__title,
.menu__title:visited,
.menu__title:active,
.menu__title:link {
  color: var(--header-menu-title-d-c);
}

.menu__title:hover {
  color: var(--header-menu-title-h-c);
}

a.brand:hover {
  border-color: var(--theme) !important;
}

.promobox ul a:hover {
  color: var(--theme);
}

.promobox__content-scale::before {
  background-color: var(--theme2);
  -webkit-box-shadow: 0 0 1px 0 var(--theme);
  -moz-box-shadow: 0 0 1px 0 var(--theme);
  box-shadow: 0 0 1px 0 var(--theme);
}

.promobox__border:hover {
  border-color: var(--theme);
}

.search__close .icon {
  fill: var(--theme6);
}

.product-collection__more-info a:hover,
.product-wishlist__collections a:hover,
.product-compare__collections a:hover,
.product-featured__collections a:hover {
  color: var(--theme);
}

.product-collection__list-v2-content .shopify-review-badge-caption {
  color: var(--theme-c);
}

.product-page-info__collections a:hover,
.one-product-info__collections a:hover {
  color: var(--theme);
}

.product-images-navigation span:hover {
  background-color: var(--product-collection-images-navigation-act-bg);
}

.product-images-navigation span:hover .icon {
  fill: var(--product-collection-images-navigation-bg);
}

.tabs[data-type="horizontal"] .tabs__head {
  background-color: var(--theme2);
  border-bottom-color: var(--theme5);
}

.tabs[data-type="horizontal"] .tabs__btn.active,
.tabs[data-type="horizontal"] .tabs__btn:hover {
  background-color: var(--theme2);
  color: var(--theme);
  border-top-color: var(--theme5);
  border-left-color: var(--theme5);
  border-right-color: var(--theme5);
}

.tabs[data-type="horizontal"] .tabs__nav .icon {
  fill: var(--theme3);
}

.tabs[data-type="horizontal"] .tabs__nav .icon:hover {
  fill: var(--theme);
}

@media (min-width: 1260px) {
  .product-page__sidebar {
    border-color: var(--theme5);
  }
}

.collection-grid-buttons .icon {
  fill: var(--theme5);
}

@media (min-width: 1025px) and (max-width: 1259px) {
  .collection-grid-buttons [data-active-lg] .icon {
    fill: var(--theme3);
  }
}

@media (min-width: 1260px) {
  .collection-grid-buttons [data-active-xl] .icon {
    fill: var(--theme3);
  }
}

.collection-sidebar-type-2 .collection-sidebar-section__head {
  border-color: var(--theme5);
}

.collection-sidebar-type-2 .collection-sidebar-section__head.open {
  border-color: var(--theme);
  background-color: var(--theme);
}

.collection-sidebar-type-2 .collection-sidebar-section__head.open>* {
  color: var(--theme2);
}

.collection-sidebar-type-2 .collection-sidebar-section__head.open .icon {
  fill: var(--theme2);
}

.collection-sidebar-type-2 .collection-sidebar-section__accordion {
  background-color: var(--theme2);
  box-shadow: 0px 0px 10px rgba(20, 20, 20, 0.2);
}

.collection-sidebar-type-2 .collection-filters__header {
  background-color: var(--theme2);
  border-bottom-color: var(--theme5);
}

.collection-current-filters__item a:hover {
  background-color: var(--theme);
}

.collection-current-filters__item a:hover {
  color: var(--theme2);
}

.collection-current-filters__item a:hover .icon {
  fill: var(--theme2);
}

.collection-current-filters__clear:hover span,
.collection-current-filters__clear:hover span:visited,
.collection-current-filters__clear:hover span:active,
.collection-current-filters__clear:hover span:link {
  color: var(--theme);
}

.compare__collections a:hover {
  color: var(--theme);
}

.notification-cookies__button-info:hover {
  color: var(--notification-cookie-button-info-h-c);
}

.notification-cookies__button-close:hover {
  color: var(--notification-cookie-button-close-c);
}

.footer a:hover {
  color: var(--footer-links-h-c);
}

.footer+.footer__back-to-top .icon {
  fill: var(--footer-button-back-to-top-d-c);
}

/* FIX: Remove custom style from footer newsletter
.footer__subscription input[type='email']:hover {
  background-color: var(--footer-subscription-input-f-bg);
  border: var(--footer-subscription-input-f-bd);
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:hover::-webkit-input-placeholder {
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:hover::-moz-placeholder {
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:hover:-ms-input-placeholder {
  color: var(--footer-subscription-input-f-c);
}

.footer__subscription input[type='email']:hover:-moz-placeholder {
  color: var(--footer-subscription-input-f-c);
}

*/

.presentation__content a:hover {
  color: var(--theme);
}

html.css-full-loaded body.theme-css-animate .menu__megamenu.animate,
html.css-full-loaded body.theme-css-animate .menu__dropdown.animate>* {
  transition-duration: var(--animation-menu-desktop-duration);
}

html.css-full-loaded body.theme-css-animate .menu__preview-image {
  transition-duration: 0.2s;
}

html.css-full-loaded body.theme-css-animate .dropdown.animate>* {
  transition-duration: var(--animation-dropdown-duration);
}

html.css-full-loaded body.theme-css-animate .product-image--hover-fade .product-image__overlay-top-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-fade .product-image__overlay-bottom-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-emersion-x .product-image__overlay-top-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-emersion-x .product-image__overlay-bottom-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-emersion-y .product-image__overlay-top-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-emersion-y .product-image__overlay-bottom-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-emersion-z .product-image__overlay-top-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-emersion-z .product-image__overlay-bottom-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-scale .product-image__overlay-top-right,
html.css-full-loaded body.theme-css-animate .product-image--hover-scale .product-image__overlay-bottom-right {
  transition-duration: var(--animation-product-hover-duration);
}

html.css-full-loaded body.theme-css-animate .image-animation>*,
html.css-full-loaded body.theme-css-animate .promobox__border,
html.css-full-loaded body.theme-css-animate .promobox__plate--hovered,
html.css-full-loaded body.theme-css-animate .promobox__plate .promobox__content::before,
html.css-full-loaded body.theme-css-animate .promobox__content-scale,
html.css-full-loaded body.theme-css-animate .promobox__text-1,
html.css-full-loaded body.theme-css-animate .promobox__text-2,
html.css-full-loaded body.theme-css-animate .promobox__text-3 {
  transition-duration: var(--animation-promobox-duration);
}

html.css-full-loaded body.theme-css-animate .promobox__text-1,
html.css-full-loaded body.theme-css-animate .promobox__text-2,
html.css-full-loaded body.theme-css-animate .promobox__text-3 {
  transition-property: color;
}

html.css-full-loaded body.theme-css-animate .promobox__curtain {
  transition-property: opacity;
  will-change: opacity;
}

html.css-full-loaded body.theme-css-animate .promobox__bg {
  transition-property: background;
}

html.css-full-loaded body.theme-css-animate .promobox--curtain .promobox__content {
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

html.css-full-loaded body.theme-css-animate .promobox--curtain .promobox__plate .promobox__content {
  transition-property: opacity;
  will-change: opacity;
}

html.css-full-loaded body.theme-css-animate .promobox__curtain,
html.css-full-loaded body.theme-css-animate .promobox__bg {
  transition-duration: calc(var(--animation-promobox-duration) * 1.5);
}

html.css-full-loaded body.theme-css-animate .promobox--curtain .promobox__content,
html.css-full-loaded body.theme-css-animate .promobox--curtain .promobox__plate .promobox__curtain {
  transition-duration: var(--animation-promobox-duration);
}

html.css-full-loaded body.theme-css-animate ul li>a,
html.css-full-loaded body.theme-css-animate ol li>a,
html.css-full-loaded body.theme-css-animate .btn-link,
html.css-full-loaded body.theme-css-animate .btn--animated .btn__icon,
html.css-full-loaded body.theme-css-animate .btn--animated .btn__text,
html.css-full-loaded body.theme-css-animate .input-checkbox span,
html.css-full-loaded body.theme-css-animate .spr-review-reportreview,
html.css-full-loaded body.theme-css-animate .instafeed__curtain,
html.css-full-loaded body.theme-css-animate .social-media a,
html.css-full-loaded body.theme-css-animate .social-share__item,
html.css-full-loaded body.theme-css-animate .social-share__item .icon,
html.css-full-loaded body.theme-css-animate .social-share__item_bg,
html.css-full-loaded body.theme-css-animate .carousel-reviews__review {
  transition-duration: var(--animation-css-duration);
}

html.css-full-loaded body.theme-css-animate .shopify-payment-button__button {
  transition: background-color var(--animation-css-low-duration), border-color var(--animation-css-low-duration), color var(--animation-css-low-duration) !important;
}

html.css-full-loaded body.theme-css-animate .btn .icon,
html.css-full-loaded body.theme-css-animate .btn span,
html.css-full-loaded body.theme-css-animate .header__content--sticky,
html.css-full-loaded body.theme-css-animate .product-wishlist__collections a,
html.css-full-loaded body.theme-css-animate .product-compare__collections a,
html.css-full-loaded body.theme-css-animate .product-page-info__collections a,
html.css-full-loaded body.theme-css-animate .product-featured__collections a,
html.css-full-loaded body.theme-css-animate .product-images-navigation span .icon,
html.css-full-loaded body.theme-css-animate .spr-summary-actions-newreview,
html.css-full-loaded body.theme-css-animate .product-images-navigation span,
html.css-full-loaded body.theme-css-animate .sorting-collections__head a,
html.css-full-loaded body.theme-css-animate .gallery__item-layer,
html.css-full-loaded body.theme-css-animate .gallery__fotorama .fotorama__nav__frame--thumb,
html.css-full-loaded body.theme-css-animate .carousel-brands a,
html.css-full-loaded body.theme-css-animate .carousel__prev,
html.css-full-loaded body.theme-css-animate .carousel__next,
html.css-full-loaded body.theme-css-animate .lookbook__picker-circle::before,
html.css-full-loaded body.theme-css-animate .slider-revolution__spacer,
html.css-full-loaded body.theme-css-animate .notification-cookies__bg,
html.css-full-loaded body.theme-css-animate .product-gallery__thumbnail_item>* {
  transition-duration: var(--animation-css-low-duration);
}

.product-image>a,
.product-image__overlay-top>a,
.product-image__overlay-bottom>a {
  cursor: var(--product-cursor) !important;
}

.product-gallery__sheet[data-js-product-gallery-control-fullscreen] {
  cursor: var(--product-gallery-cursor) !important;
}